import React from 'react';

import CalendarEventPopover from 'toolkit/time/CalendarEventPopover';
import {DisplayableEvent} from 'toolkit/time/types';

const EventsValueCell: React.FunctionComponent<Props> = (props: Props) => {
  return <CalendarEventPopover events={props.value}>{props.valueFormatted}</CalendarEventPopover>;
};
EventsValueCell.displayName = 'EventsValueCell';
interface Props {
  value: ReadonlyArray<DisplayableEvent>;
  valueFormatted: string;
}

export default EventsValueCell;

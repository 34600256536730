import './ViewNavLink.scss';

import React from 'react';
import {NavLink} from 'react-router-dom';

import {useRootViewId} from 'dashboard/dashboard-context';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

import {ViewLinkParams} from './view-link-types';
import {getViewLink, createViewUrlParams} from './view-urls';

const ViewNavLink: React.FunctionComponent<Props> = props => {
  const params = createViewUrlParams({
    asOfDate: props.evaluationDate,
    calendar: props.calendarProperties.name,
    evaluationPeriod: props.evaluationPeriod,
    filters: props.filters,
  });
  const rootViewId = useRootViewId();
  return (
    <NavLink
      className="ViewNavLink"
      to={getViewLink(
        props.vendorName,
        rootViewId,
        [...props.viewSlugs, assertTruthy(props.linkedView.slug)],
        props.viewUrlParams.push(params)
      )}
    >
      {props.children}
    </NavLink>
  );
};
ViewNavLink.displayName = 'ViewNavLink';

type Props = Omit<ViewLinkParams, 'widgetId'> & {
  children: React.ReactNode;
  filters: ReadonlyArray<Types.AttributeFilter>;
  linkedView: Types.ThinView;
};

export default ViewNavLink;

import {RowNode} from 'ag-grid-community';
import {Set} from 'immutable';

import {
  ACTUALS_PERIOD_MONTHLY,
  ACTUALS_PERIOD_WEEKLY,
  CHART_PERIOD_MONTHLY,
  CHART_PERIOD_WEEKLY,
  FORECAST_PERIOD_MONTHLY,
  FORECAST_PERIOD_WEEKLY,
} from 'planning/forecasts/constants';
import {shouldUseGrossSales} from 'planning/utils';
import {Settings} from 'settings/utils';
import {ComputeResultData} from 'toolkit/ag-grid/types';
import {AttributesByTypeAndName} from 'toolkit/attributes/types';
import {toThinAttributeValue} from 'toolkit/attributes/utils';
import {ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {getEmptyComputeResult} from 'toolkit/compute/utils';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {PEWTER_COMPUTED_FORECAST_TYPES} from 'toolkit/metrics/editor/types';
import {MetricsByName} from 'toolkit/metrics/types';
import {getMetricArgumentsFromSettings} from 'toolkit/metrics/utils';
import {PendingNpiStatus} from 'toolkit/plans/new-product-introduction/utils';
import {applyDiffToVersion, applyForecastOverride} from 'toolkit/plans/overrides';
import {getFiltersAsAttributeValues} from 'toolkit/plans/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {getCurrentForecastType} from 'widgets/tables/impl/planning/utils';

import {ForecastTypeByAttributeValueIds} from './types';

export function getActualsMetric(
  planDemandSource: Types.PlanDemandSource,
  planSettings: Types.PlanSettings,
  availableMetrics: MetricsByName
): Types.Metric | null {
  switch (planDemandSource) {
    case Types.PlanDemandSource.POS:
    case Types.PlanDemandSource.DIRECT_TO_CONSUMER:
      return (
        availableMetrics.get(
          shouldUseGrossSales(planSettings) ? 'sales_units_gross' : 'sales_units_net'
        ) ?? null
      );
    case Types.PlanDemandSource.SHIPMENT:
      return availableMetrics.get('inbound_shipped_units') ?? null;
  }
}

export function getForecastMetric(
  planDemandSource: Types.PlanDemandSource,
  availableMetrics: MetricsByName
): Types.Metric | null {
  switch (planDemandSource) {
    case Types.PlanDemandSource.POS:
    case Types.PlanDemandSource.DIRECT_TO_CONSUMER:
      return availableMetrics.get('forecast_sales_units_net') ?? null;
    case Types.PlanDemandSource.SHIPMENT:
      return availableMetrics.get('forecast_inbound_shipped_units') ?? null;
  }
}

export function getActualsDatePeriod(
  demandPlanSettings: Types.DemandPlanSettings,
  planDemandSource: Types.PlanDemandSource
): Types.SimpleDatePeriod {
  if (
    demandPlanSettings.adjustmentGranularity === Types.CalendarUnit.MONTHS ||
    planDemandSource === Types.PlanDemandSource.SHIPMENT
  ) {
    return ACTUALS_PERIOD_MONTHLY;
  }
  return ACTUALS_PERIOD_WEEKLY;
}

export function getForecastDatePeriod(
  demandPlanSettings: Types.DemandPlanSettings,
  planDemandSource: Types.PlanDemandSource
): Types.SimpleDatePeriod {
  if (
    demandPlanSettings.adjustmentGranularity === Types.CalendarUnit.MONTHS ||
    planDemandSource === Types.PlanDemandSource.SHIPMENT
  ) {
    return FORECAST_PERIOD_MONTHLY;
  }
  return FORECAST_PERIOD_WEEKLY;
}

export function getChartDatePeriod(planDemandSource: Types.PlanDemandSource): Types.DatePeriod {
  switch (planDemandSource) {
    case Types.PlanDemandSource.POS:
    case Types.PlanDemandSource.DIRECT_TO_CONSUMER:
      return CHART_PERIOD_WEEKLY;
    case Types.PlanDemandSource.SHIPMENT:
      return CHART_PERIOD_MONTHLY;
  }
}

export function getChartDateGroupingAttribute(
  demandPlanSettings: Types.DemandPlanSettings,
  planDemandSource: Types.PlanDemandSource,
  availableGroupings: AttributesByTypeAndName
): Types.Attribute | null {
  if (
    demandPlanSettings.adjustmentGranularity === Types.CalendarUnit.MONTHS ||
    planDemandSource === Types.PlanDemandSource.SHIPMENT
  ) {
    return availableGroupings.get(Types.AttributeType.DATE)!.get('Month') ?? null;
  }
  return availableGroupings.get(Types.AttributeType.DATE)!.get('Week') ?? null;
}

export function getForecastMetricInstance(
  metric: Types.Metric,
  forecastType: Types.ForecastType,
  metricArguments: Types.MetricArguments,
  planSettings: Types.PlanSettings
): Types.MetricInstance {
  return {
    metric,
    arguments: {
      ...metricArguments,
      forecastType,
      versionRecency: null,
      returnsCountingMethod:
        shouldUseGrossSales(planSettings) && PEWTER_COMPUTED_FORECAST_TYPES.includes(forecastType)
          ? Types.ReturnsCountingMethod.GROSS
          : Types.ReturnsCountingMethod.NET,
    },
  };
}

export function getForecastMetricArgumentsFromSettings(
  settings: Settings,
  period: Types.DatePeriod
): Types.MetricArguments {
  return {
    ...getMetricArgumentsFromSettings(settings),
    period,
    forecastComposition: Types.ForecastComposition.BASELINE,
    // TODO: remove when we support event lift with multiple forecasts (sc-40316)
  };
}

export function getChildTree(
  tree: Types.AttributeHierarchyTree,
  path: ReadonlyArray<Types.ThinAttributeValue>
): Types.AttributeHierarchyTree | null {
  if (path.length === 0) {
    return tree;
  } else {
    const matchingChild = tree.children.find(child => child.value?.id === path[0].id);

    return !!matchingChild ? getChildTree(matchingChild, path.slice(1)) : null;
  }
}

export const MAX_PLAN_ATTRIBUTES_FOR_FORECAST_OVERRIDES = 6;

export function filterTreeOnDemandSourceKey(
  tree: Types.AttributeHierarchyTree,
  demandSourceKeyAttributeIndex: number,
  demandSourceKeyAttributeValue: Types.AttributeValue,
  level = 0
): Types.AttributeHierarchyTree {
  return {
    ...tree,
    children:
      level >= MAX_PLAN_ATTRIBUTES_FOR_FORECAST_OVERRIDES
        ? []
        : tree.children
            .filter(
              child =>
                demandSourceKeyAttributeIndex !== level ||
                child.value?.id === demandSourceKeyAttributeValue.id
            )
            .map(child =>
              filterTreeOnDemandSourceKey(
                child,
                demandSourceKeyAttributeIndex,
                demandSourceKeyAttributeValue,
                level + 1
              )
            ),
  };
}

export function getTableData(
  treeNode: Types.AttributeHierarchyTree,
  resultChildren: ReadonlyArray<ThinComputeResultRowExtended>,
  level: number,
  metrics: ReadonlyArray<Types.MetricInstance>
): ComputeResultData {
  const matchingChildren = resultChildren.filter(child => treeNode?.value?.id === child?.value?.id);
  const firstMatchingChild = matchingChildren.length && matchingChildren[0];

  if (!treeNode.children.length) {
    return {
      data: matchingChildren.length
        ? matchingChildren[0]
        : getEmptyComputeResult(metrics, treeNode.value),
      group: false,
      level,
    };
  } else {
    const children = treeNode.children.map(child =>
      getTableData(child, firstMatchingChild ? firstMatchingChild.children : [], level + 1, metrics)
    );
    return {
      children,
      data: matchingChildren.length
        ? matchingChildren[0]
        : getEmptyComputeResult(metrics, treeNode.value),
      group: true,
      level,
    };
  }
}

export function getForecastOverridesForDescendants(
  attributeValueIds: ReadonlyArray<number>,
  planVersion: Types.PlanVersion
) {
  return planVersion.forecastOverrides
    .filter(override => override.attributeValues.length > attributeValueIds.length)
    .filter(override =>
      attributeValueIds.every((id, index) => id === override.attributeValues[index].id)
    );
}

export function hasChildrenWithDifferentForecastTypes(
  currentFilters: ReadonlyArray<Types.AttributeFilter>,
  planVersion: Types.PlanVersion
): boolean {
  const attributeValues = getFiltersAsAttributeValues(currentFilters).map(toThinAttributeValue);
  const attributeValueIds = attributeValues.map(value => assertTruthy(value.id));
  const descendantOverrides = getForecastOverridesForDescendants(attributeValueIds, planVersion);
  const allDescendantForecastTypes = getSetOfForecastTypes(descendantOverrides);

  const currentForecastType = getCurrentForecastType(currentFilters, planVersion);

  return allDescendantForecastTypes.add(currentForecastType).size > 1;
}

export function expandAllParentRows(row: RowNode) {
  const currentParent = row.parent;
  if (currentParent) {
    currentParent.setExpanded(true);
    expandAllParentRows(currentParent);
  }
}

export function isNpiActive(
  npiConfig: Types.NewProductIntroductionConfig | null | undefined,
  pendingNpiStatus: PendingNpiStatus | null | undefined
) {
  return (
    npiConfig &&
    ((pendingNpiStatus === PendingNpiStatus.UNCHANGED &&
      !npiConfig.newProductIntroduction.archiveDate) ||
      pendingNpiStatus === PendingNpiStatus.PENDING_UNARCHIVE)
  );
}

export function getNpiAttributeValueIds(npi: Types.NewProductIntroductionConfig) {
  return Set(npi.newProductIntroduction.attributeValues.map(value => assertTruthy(value.id)));
}

export function forecastLabelComparator(lengthFormat = TextLengthFormat.COMPACT) {
  return (forecastTypeA: Types.ForecastType, forecastTypeB: Types.ForecastType) =>
    Format.forecastTypeDisplayName(forecastTypeA, {lengthFormat}).localeCompare(
      Format.forecastTypeDisplayName(forecastTypeB, {lengthFormat})
    );
}

export function getForecastTypeFromAttributeValueIds(
  attributeValueIds: ReadonlyArray<number>,
  forecastTypeMap: ForecastTypeByAttributeValueIds,
  planVersion: Types.PlanVersion
): Types.ForecastType {
  if (forecastTypeMap.has(attributeValueIds)) {
    return forecastTypeMap.get(attributeValueIds)!;
  } else if (attributeValueIds.length === 0) {
    return planVersion.forecastType;
  } else {
    return getForecastTypeFromAttributeValueIds(
      attributeValueIds.slice(0, attributeValueIds.length - 1),
      forecastTypeMap,
      planVersion
    );
  }
}

function getSetOfForecastTypes(
  forecastOverrides: ReadonlyArray<Types.ThinUserForecastOverride>
): Set<Types.ForecastType> {
  return Set(forecastOverrides.map(override => override.forecastType));
}

function getTreeNode(
  root: Types.AttributeHierarchyTree,
  path: ReadonlyArray<Types.ThinAttributeValue>
): Types.AttributeHierarchyTree {
  if (path.length === 0) {
    return root;
  }

  const node = root.children.find(node => node.value!.id === path[0].id);
  if (!node) {
    throw new Error(
      `Could not find path in tree: [${path.map(pathItem => pathItem.id).join(', ')}]`
    );
  }
  return getTreeNode(node, path.slice(1));
}

export function normalizeForecastOverrides(
  pathFilters: ReadonlyArray<Types.AttributeFilter>,
  tree: Types.AttributeHierarchyTree,
  savedPlanVersion: Types.PlanVersion,
  pendingPlanVersion: Types.PlanVersion,
  forecastType: Types.ForecastType
): Types.PlanVersion {
  if (pathFilters.length === 0) {
    return pendingPlanVersion;
  }

  const attributeValues = getFiltersAsAttributeValues(pathFilters).map(toThinAttributeValue);
  const attributeValueIds = attributeValues.map(value => assertTruthy(value.id));
  const descendantForecastOverrides = getForecastOverridesForDescendants(
    attributeValueIds,
    pendingPlanVersion
  );
  const descendantForecastTypes = getSetOfForecastTypes(descendantForecastOverrides);
  const childrenOverrides = descendantForecastOverrides.filter(
    override => override.attributeValues.length === attributeValueIds.length + 1
  );
  const childrenForecastTypes = getSetOfForecastTypes(childrenOverrides);
  const numberOfChildren = getTreeNode(tree, attributeValues).children.length;

  const currentForecastType = getCurrentForecastType(pathFilters, pendingPlanVersion);

  // all children are overridden and have the same forecast type
  const allChildrenOverridesMatch =
    numberOfChildren === childrenOverrides.length &&
    childrenForecastTypes.add(forecastType).size <= 1;
  // single child that was overridden now matches the parent (& other children)
  const allChildrenMatch =
    childrenOverrides.length === 1 && descendantForecastTypes.add(currentForecastType).size === 1;
  if (allChildrenOverridesMatch || allChildrenMatch) {
    const overrideDiff = applyForecastOverride(pendingPlanVersion, pendingPlanVersion, {
      attributeValues,
      forecastType,
    });
    const newPlanVersion = assertTruthy(applyDiffToVersion(pendingPlanVersion, overrideDiff));
    return normalizeForecastOverrides(
      pathFilters.slice(0, pathFilters.length - 1),
      tree,
      savedPlanVersion,
      newPlanVersion,
      forecastType
    );
  }

  return pendingPlanVersion;
}

export function isTopLevelForecastSelectionView(
  selectionFilters: ReadonlyArray<Types.AttributeFilter>,
  demandSourceKeyAttributeIndex: number
) {
  return selectionFilters.length <= demandSourceKeyAttributeIndex + 1;
}

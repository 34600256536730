import {Comparator} from 'ag-grid-community/dist/lib/filter/provided/scalarFilter';
import {Map} from 'immutable';
import {useMemo} from 'react';

import * as Api from 'api';
import {useCurrentUser} from 'redux/context/user';
import {CurrentUser} from 'redux/reducers/user';
import {isAtLeast} from 'toolkit/users/utils';
import * as Types from 'types';
import {Tag} from 'types';
import {ascendingBy} from 'utils/arrays';
import {useResult, useApi} from 'utils/useApi';

import {SUPER_ADMIN_ONLY_TAGS} from './types';

export function getEntityIdsByTag(
  tagsByEntityId: Map<number, ReadonlyArray<string>>
): Map<string, ReadonlyArray<number>> {
  return tagsByEntityId
    .map((tags, entityId) => tags.map<[string, number]>(tag => [tag, entityId]))
    .valueSeq()
    .flatMap(tagAndEntityId => tagAndEntityId)
    .groupBy(([tag]) => tag)
    .map(tagAndEntityId =>
      tagAndEntityId
        .valueSeq()
        .map(val => val[1])
        .toSet()
        .toArray()
    )
    .toMap();
}

export function sortTagNames(
  tags: ReadonlyArray<Tag>,
  currentUser: CurrentUser
): ReadonlyArray<string> {
  return tags
    .map(tag => tag.name)
    .filter(tag => canViewTag(currentUser, tag))
    .sort(tagNameComparator());
}

export function sortTags(tags: ReadonlyArray<Tag>, currentUser: CurrentUser): ReadonlyArray<Tag> {
  return tags.filter(tag => canViewTag(currentUser, tag.name)).sort(tagComparator());
}

export function tagNameComparator(): Comparator<string> {
  return ascendingBy(tagName => tagName.toLocaleLowerCase());
}

function tagComparator(): Comparator<Tag> {
  return ascendingBy(tag => tag.name.toLocaleLowerCase());
}

export function useSortedViewTagNames(): ReadonlyArray<string> {
  const currentUser = useCurrentUser();
  const tags = useResult(useApi(Api.Tags.getViewTags.getResource()));
  return useMemo(() => sortTagNames(tags, currentUser), [currentUser, tags]);
}

export function useSortedSubscriptionTagNames(): ReadonlyArray<string> {
  const tags = useSubscriptionTags();
  const currentUser = useCurrentUser();
  return useMemo(() => sortTagNames(tags, currentUser), [currentUser, tags]);
}

export function useSortedSubscriptionTags(): ReadonlyArray<Tag> {
  const tags = useSubscriptionTags();
  const currentUser = useCurrentUser();
  return useMemo(() => sortTags(tags, currentUser), [currentUser, tags]);
}

function useSubscriptionTags() {
  return useResult(useApi(Api.Tags.getSubscriptionTags.getResource()));
}

function canViewTag(currentUser: CurrentUser, tag: string) {
  return (
    isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) || !SUPER_ADMIN_ONLY_TAGS.includes(tag)
  );
}

import {List, Map, Set} from 'immutable';
import moment, {Moment} from 'moment-timezone';

import {PartnersById} from 'toolkit/attributes/types';
import {Option} from 'toolkit/components/LabeledOptionsSelect';
import {getPartnerFilters} from 'toolkit/filters/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import * as Types from 'types';
import {CalendarProperties, LocalInterval, RetailCalendarEnum} from 'types';
import {ascendingBy, descendingBy} from 'utils/arrays';
import {isTruthy} from 'utils/functions';

export const max = (left: string, right: string) => (left >= right ? left : right);
export const min = (left: string, right: string) => (left <= right ? left : right);

export const dateGroupingGranularityOrder: {[key in Types.CalendarUnit]: number} = {
  DAYS: 0,
  WEEKS: 1,
  MONTHS: 2,
  QUARTERS: 3,
  SEASONS: 4,
  YEARS: 5,
};

export const periodUnitOptions: Option<Types.CalendarUnit>[] = [
  {value: Types.CalendarUnit.DAYS, label: 'Day'},
  {value: Types.CalendarUnit.WEEKS, label: 'Week'},
  {value: Types.CalendarUnit.MONTHS, label: 'Month'},
  {value: Types.CalendarUnit.QUARTERS, label: 'Quarter'},
  {value: Types.CalendarUnit.SEASONS, label: 'Season'},
  {value: Types.CalendarUnit.YEARS, label: 'Year'},
];

export function getCalendarLabel(
  calendarProperties: CalendarProperties,
  defaultCalendar: RetailCalendarEnum
) {
  return calendarProperties.name === defaultCalendar
    ? `${calendarProperties.displayName} (Default)`
    : calendarProperties.displayName;
}

export function getCalendarSelectOptions(
  availableCalendars: Map<Types.RetailCalendarEnum, Types.CalendarProperties>,
  defaultCalendar: Types.RetailCalendarEnum
): Option<Types.RetailCalendarEnum>[] {
  const defaultCalendarProps = availableCalendars.get(defaultCalendar) ?? null;

  const toOption = (calendarProperties: Types.CalendarProperties) => ({
    label: getCalendarLabel(calendarProperties, defaultCalendar),
    value: calendarProperties.name,
  });

  return [defaultCalendarProps ? toOption(defaultCalendarProps) : null].filter(isTruthy).concat(
    availableCalendars
      .remove(defaultCalendar)
      .valueSeq()
      .sortBy(calendar => calendar.displayName)
      .map(toOption)
      .toArray()
  );
}

export function hasDefaultCalendar(
  view: Types.View,
  partners: PartnersById,
  vendorCalendar: Types.RetailCalendarEnum
) {
  const partnerCalendar = getPartnerCalendar(partners, view.filters);
  return partnerCalendar !== null
    ? view.calendar === partnerCalendar
    : view.calendar === vendorCalendar;
}

export function intersectIntervals(
  left: Types.LocalInterval,
  right: Types.LocalInterval
): Types.LocalInterval {
  const start = max(left.start, right.start);
  const end = min(left.end, right.end);
  return start > end ? {start, end: start} : {start, end};
}

export function unionIntervals(intervals: List<Types.LocalInterval>): Types.LocalInterval | null {
  if (!intervals.size) {
    return null;
  }

  return {
    end: intervals.map(interval => interval.end).max()!,
    start: intervals.map(interval => interval.start).min()!,
  };
}

export function contains(interval: Types.LocalInterval, otherInterval: Types.LocalInterval) {
  return interval.start <= otherInterval.start && interval.end >= otherInterval.end;
}

export function containsDate(interval: Types.LocalInterval, date: moment.Moment | string) {
  return (
    moment(date).isSameOrAfter(moment(interval.start)) &&
    moment(date).isBefore(moment(interval.end))
  );
}

export function intersects(left: Types.LocalInterval, right: Types.LocalInterval) {
  return max(left.start, right.start) < min(left.end, right.end);
}

export function dateInterval(
  start: string | moment.Moment,
  end: string | moment.Moment
): Types.LocalInterval {
  const startDate = typeof start === 'string' ? start : start.format(DATE_FORMAT);
  const endDate = typeof end === 'string' ? end : end.format(DATE_FORMAT);
  return {start: startDate, end: endDate};
}

export function isForwardOnlyForecast(
  period: Types.DatePeriod
): period is Types.FutureDatePeriod | Types.NextNDatePeriod {
  return period.type === 'future' || period.type === 'nextn';
}

export function getPastPeriodPart(period: Types.DatePeriod) {
  if (period.type === 'complex') {
    return getComplexPeriodPart(period, 'startPeriod');
  } else if (!isForwardOnlyForecast(period)) {
    return period;
  }
  return null;
}

export function getFuturePeriodPart(period: Types.DatePeriod) {
  if (period.type === 'complex') {
    return getComplexPeriodPart(period, 'endPeriod');
  } else if (isForwardOnlyForecast(period)) {
    return period;
  }
  return null;
}

function getSimplePeriod(period: Types.DatePeriod, startingOrEnding: 'starting' | 'ending') {
  if (period.type === 'complex') {
    return startingOrEnding === 'starting' ? period.startPeriod : period.endPeriod;
  }
  return period;
}

export const daysPerUnit = {
  [Types.CalendarUnit.DAYS]: 1,
  [Types.CalendarUnit.WEEKS]: 7,
  [Types.CalendarUnit.MONTHS]: 30,
  [Types.CalendarUnit.QUARTERS]: 90,
  [Types.CalendarUnit.SEASONS]: 180,
  [Types.CalendarUnit.YEARS]: 360,
};

function assertNeverUnhandledPeriodType(x: never): never {
  throw new Error(`Unhandled period type ${(x as Types.DatePeriod).type}`);
}

export function getSimplePeriodDaysDuration(period: Types.SimplePeriod) {
  return daysPerUnit[period.unit] * period.amount;
}

export function getApproximateDaysFromStartToNow(period: Types.DatePeriod): number {
  switch (period.type) {
    case 'complex':
      return getApproximateDaysFromStartToNow(period.startPeriod);
    case 'fixed':
    case 'fixed_to_now':
      return today().diff(period.start, 'days');
    case 'lastn':
    case 'previous':
      return (period.amount + 0.5) * daysPerUnit[period.unit];
    case 'trailing':
      return period.amount * daysPerUnit[period.unit];
    case 'todate':
    case 'todate_weekend':
      return daysPerUnit[period.unit];
    case 'future':
      return -(period.amount - 0.5) * daysPerUnit[period.unit];
    case 'nextn':
      return -0.5 * daysPerUnit[period.unit];
    case 'now_to_fixed':
      return 0;
    case 'to_go':
      return 0;
    default:
      assertNeverUnhandledPeriodType(period);
  }
}

export function getApproximateDaysFromNowToEnd(period: Types.DatePeriod): number {
  switch (period.type) {
    case 'complex':
      return getApproximateDaysFromNowToEnd(period.endPeriod);
    case 'fixed_to_now':
    case 'trailing':
    case 'todate':
      return 0;
    case 'todate_weekend':
      return -3.5;
    case 'lastn':
      return -0.5 * daysPerUnit[period.unit];
    case 'future':
    case 'nextn':
      return (period.amount + 0.5) * daysPerUnit[period.unit];
    case 'previous':
      return -(period.amount - 0.5) * daysPerUnit[period.unit];
    case 'fixed':
    case 'now_to_fixed':
      return moment(period.end).diff(today(), 'days');
    case 'to_go':
      return daysPerUnit[period.unit];
    default:
      assertNeverUnhandledPeriodType(period);
  }
}

export function periodExtendsIntoTheFuture(period: Types.DatePeriod) {
  return getApproximateDaysFromNowToEnd(period) > 0;
}

// not the exact reverse of periodExtendsIntoTheFuture, as periods can include today (type: fixed)
export function periodExtendsIntoThePast(period: Types.DatePeriod) {
  return getApproximateDaysFromStartToNow(period) > 0;
}

export function createFilterForPeriodByMaxDays(
  maxDaysBefore: number | undefined,
  maxDaysAfter: number | undefined
) {
  return (period: Types.DatePeriod) => {
    if (maxDaysAfter !== undefined && periodExtendsIntoTheFuture(period)) {
      const days = getApproximateDaysFromNowToEnd(period);
      if (days > maxDaysAfter) {
        return false;
      }
    }

    if (maxDaysBefore !== undefined && periodExtendsIntoThePast(period)) {
      const days = getApproximateDaysFromStartToNow(period);
      if (days > maxDaysBefore) {
        return false;
      }
    }

    return true;
  };
}

export function getApproximateLengthInDays(period: Types.DatePeriod): number {
  const startToNow = getApproximateDaysFromStartToNow(period);
  const nowToEnd = getApproximateDaysFromNowToEnd(period);
  return startToNow + nowToEnd;
}

export function getUnionOfPeriods(
  periods: ReadonlyArray<Types.DatePeriod>
): Types.DatePeriod | null {
  if (!periods.length) {
    return null;
  }
  const periodWithEarliestStart = [...periods]
    .map(period => getSimplePeriod(period, 'starting'))
    .sort(descendingBy(getApproximateDaysFromStartToNow))[0];
  const periodWithLatestEnd = [...periods]
    .map(period => getSimplePeriod(period, 'ending'))
    .sort(descendingBy(getApproximateDaysFromNowToEnd))[0];

  if (
    getApproximateDaysFromNowToEnd(periodWithEarliestStart) ===
    getApproximateDaysFromNowToEnd(periodWithLatestEnd)
  ) {
    // this includes the case where the two periods are outright equal
    return periodWithEarliestStart;
  }
  if (
    getApproximateDaysFromStartToNow(periodWithEarliestStart) ===
    getApproximateDaysFromStartToNow(periodWithLatestEnd)
  ) {
    return periodWithLatestEnd;
  }
  if (
    (periodWithEarliestStart.type === 'fixed' || periodWithEarliestStart.type === 'fixed_to_now') &&
    periodWithLatestEnd.type === 'fixed'
  ) {
    return {
      type: 'fixed',
      start: periodWithEarliestStart.start,
      end: periodWithLatestEnd.end,
    };
  }
  return {
    type: 'complex',
    startPeriod: periodWithEarliestStart,
    endPeriod: periodWithLatestEnd,
  };
}

export function getPartnerCalendar(
  partners: PartnersById,
  filters: ReadonlyArray<Types.AttributeFilter>
): Types.RetailCalendarEnum | null {
  const partnerFilterIds = getPartnerFilters(filters)
    .filter(filter => filter.inclusive && !filter.attributeInstance.graphContext)
    .flatMap(filter => filter.values)
    .map(attributeValue => attributeValue.id!)
    .filter(partnerId => partners.has(partnerId));
  const retailCalendars = Set(
    partnerFilterIds.map(partnerFilterId => partners.get(partnerFilterId)?.defaultCalendar)
  ).filter(isTruthy);
  if (retailCalendars.size === 1 && retailCalendars.first() !== undefined) {
    return retailCalendars.first();
  }
  return null;
}

export const compareIntervalsByStart = ascendingBy(
  (interval: Types.LocalInterval) => interval.start
).thenAscendingBy(interval => interval.end);

export const compareIntervalsByEnd = ascendingBy(
  (interval: Types.LocalInterval) => interval.end
).thenAscendingBy(interval => interval.start);

export function intervalLength(
  {start, end}: Types.LocalInterval,
  granularity: moment.unitOfTime.DurationConstructor = 'days'
) {
  return moment(end).diff(moment(start), granularity);
}

export function spanIntervals(intervals: ReadonlyArray<LocalInterval>): LocalInterval | null {
  return intervals.reduce(
    (acc: LocalInterval | null, interval) =>
      !acc
        ? interval
        : {
            start: interval.start < acc.start ? interval.start : acc.start,
            end: interval.end > acc.end ? interval.end : acc.end,
          },
    null
  );
}

export function shiftFixedPeriod(
  period: Types.FixedDatePeriod,
  durationDays: number
): Types.FixedDatePeriod {
  return {
    type: 'fixed',
    start: moment(period.start).add(durationDays, 'days').format(DATE_FORMAT),
    end: moment(period.end).add(durationDays, 'days').format(DATE_FORMAT),
  };
}

export function spanFixedPeriods(
  leftPeriod: Types.FixedDatePeriod,
  rightPeriod: Types.FixedDatePeriod
): Types.FixedDatePeriod {
  return {
    type: 'fixed',
    start: leftPeriod.start < rightPeriod.start ? leftPeriod.start : rightPeriod.start,
    end: leftPeriod.end > rightPeriod.end ? leftPeriod.end : rightPeriod.end,
  };
}

export function today() {
  const now = moment();
  return moment([now.year(), now.month(), now.date()]);
}

export function getFixedPeriodDaysDuration(period: Types.FixedDatePeriod) {
  return moment(period.end).diff(period.start, 'days');
}

export function getVendorEvaluationDate(vendorEvaluationDate: string | null | undefined) {
  return moment(vendorEvaluationDate || undefined).startOf('day');
}

export function getComplexPeriodPart(
  period: Types.ComplexDatePeriod,
  key: 'startPeriod' | 'endPeriod'
): Types.SimpleDatePeriod {
  return period[key];
}

export function setComplexPeriodPart(
  period: Types.ComplexDatePeriod,
  key: 'startPeriod' | 'endPeriod',
  value: Types.SimpleDatePeriod
): Types.ComplexDatePeriod {
  return {
    ...period,
    [key]: value,
  };
}

export function isPeriodValid(period: Types.DatePeriod | null | undefined): boolean {
  if (!period) {
    return false;
  }

  if (period.type === 'fixed_to_now') {
    return moment(period.start).isBefore(moment());
  }
  // period must be of type fixed since that is the only place where we manually create an interval that could be invalid (start date after end date)
  return period.type !== 'fixed' || period.start <= period.end;
}

export function getCalendarUnitsOfPeriod(period: Types.DatePeriod): Set<Types.CalendarUnit> {
  if (period.type === 'complex') {
    return Set.union([
      getCalendarUnitsOfPeriod(period.startPeriod),
      getCalendarUnitsOfPeriod(period.endPeriod),
    ]);
  } else if ('unit' in period) {
    return Set.of(period.unit);
  } else if (period.type === 'fixed' || period.type === 'fixed_to_now') {
    // fixed periods have an implicit calendar unit of days
    return Set.of(Types.CalendarUnit.DAYS);
  } else {
    return Set.of();
  }
}

export function getDayListFromInterval(interval: Types.LocalInterval | null) {
  if (!interval) {
    return [];
  }

  const days = [];
  const endDate = moment.utc(interval.end);
  const now = moment.utc(interval.start);
  while (now.isBefore(endDate)) {
    days.push(now.format());
    now.add(1, 'days');
  }
  return days;
}

/**
 * finds the next week day starting from and including the given date
 */
export function nextOrSame(date: string, dayOfWeek: number) {
  if (dayOfWeek < 1 || dayOfWeek > 7) {
    throw Error('Day of Week must be between 1 and 7');
  }
  return (
    moment(date)
      // 1 is Monday, 7 is sunday, need to normalize to start of the week
      .add((7 + (dayOfWeek - moment(date).isoWeekday())) % 7, 'day')
      .format(DATE_FORMAT)
  );
}

/**
 * finds the next week day starting from and including the given date
 */
export function nextOrSameMoment(date: Moment, dayOfWeek: number) {
  if (dayOfWeek < 1 || dayOfWeek > 7) {
    throw Error('Day of Week must be between 1 and 7');
  }
  return (
    date
      // moments are mutable, so modify a clone
      .clone()
      // 1 is Monday, 7 is sunday, need to normalize to start of the week
      .add((7 + (dayOfWeek - date.isoWeekday())) % 7, 'day')
  );
}

/**
 * finds the next week day starting from the given date
 */
export function nextMoment(date: Moment, dayOfWeek: number) {
  const nextOrSame = nextOrSameMoment(date, dayOfWeek);
  return nextOrSame.isSame(date) ? date.clone().add(1, 'week') : nextOrSame;
}

/**
 * compares the date part between a date object and string. Used for comparing dates in ag-grd.
 * @param pickerDate the full date time from a date picker.
 * @param cellDate a string with date only in the cell.
 */
export function aggridDateComparator(pickerDate: Date, cellDate: string | null) {
  if (cellDate === null) {
    return 0;
  }
  const date = new Date(cellDate);
  const dateObj = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  if (dateObj < pickerDate) {
    return -1;
  } else if (dateObj > pickerDate) {
    return 1;
  }
  return 0;
}

/**
 * compares the date time part between two date strings or two moment objects
 * @param date1 the date string or moment object
 * @param date2 the date string or moment object
 */
export function dateComparator(date1: string | moment.Moment, date2: string | moment.Moment) {
  const parsedDate1 = moment(date1);
  const parsedDate2 = moment(date2);
  if (parsedDate1.isSame(parsedDate2)) {
    return 0;
  } else if (parsedDate1.isBefore(parsedDate2)) {
    return -1;
  } else {
    return 1;
  }
}

/**
 * compares the date time part between two datetime strings
 * @param dateString1 the full date time string
 * @param dateString2 the full date time string
 */
export function dateTimeComparator(dateString1: string, dateString2: string) {
  const date1 = moment(dateString1, 'YYYY-MM-DD h:mma z');
  const date2 = moment(dateString2, 'YYYY-MM-DD h:mma z');

  if (date1.isSame(date2)) {
    return 0;
  } else if (date1.isBefore(date2)) {
    return -1;
  }
  return 1;
}

export function getISODayOfWeekIndex(dayOfWeek: Types.DayOfWeek) {
  switch (dayOfWeek) {
    case Types.DayOfWeek.MONDAY:
      return 1;
    case Types.DayOfWeek.TUESDAY:
      return 2;
    case Types.DayOfWeek.WEDNESDAY:
      return 3;
    case Types.DayOfWeek.THURSDAY:
      return 4;
    case Types.DayOfWeek.FRIDAY:
      return 5;
    case Types.DayOfWeek.SATURDAY:
      return 6;
    case Types.DayOfWeek.SUNDAY:
      return 7;
  }
}

export function getISODayOfWeekLabel(dayOfWeekIndex: number) {
  switch (dayOfWeekIndex) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';
    default:
      throw new Error('No such day of week.');
  }
}

export function alignIntervalToCalendar(
  targetInterval: Types.LocalInterval,
  metric: Types.Metric,
  intervalType: Types.IntervalType,
  dataRangeDOW: number,
  calendar: Types.CalendarProperties
) {
  const targetStartDate = moment(targetInterval.start);
  const targetEndDate = moment(targetInterval.end);

  // offset by 1 to convert closed-open interval to closed-closed interval
  const openToClosedOffset = 1;

  // stock metrics in dashboards are offset one day compared to the storage date
  // due to the fact that they use an ending stock aggregator (see LAST in Aggregator.java)
  const offsetForEndingStockAggregator = metric.type === Types.MetricType.STOCK ? 1 : 0;

  if (intervalType !== Types.IntervalType.WEEKLY) {
    return {
      start: targetStartDate
        .clone()
        .subtract(offsetForEndingStockAggregator, 'days')
        .format(DATE_FORMAT),
      // -1 in addition to stock offset to convert closed-open interval to closed-closed interval
      end: targetEndDate
        .clone()
        .subtract(offsetForEndingStockAggregator + openToClosedOffset, 'days')
        .format(DATE_FORMAT),
    };
  } else {
    // get the first date within the delete range that is the correct DOW of the data range
    const applicableStartDate = nextOrSameMoment(targetStartDate, dataRangeDOW);

    // if the end date isn't the correct dow, extend the range to the correct dow
    const applicableEndDate = nextOrSameMoment(targetEndDate, dataRangeDOW);

    // startOfWeekIndex is 0-indexed such that 0 is Monday, 6 is Sunday
    const calendarDOW = calendar.startOfWeekIndex;
    // nextOrSame expects iso weekdays (1 is Monday, 7 is Sunday)
    const isoDOW = calendarDOW + 1;

    // this shift needs to match the WEEKLY_STOCK_SHIFT in pewter
    const weeklyStockShift = 3;

    // weekly metrics are offset backwards by three days and then aligned to the next calendar DOW
    // this should match `DateUtils.adjustToNearest` in pewter
    const calendarStart = nextOrSameMoment(
      applicableStartDate.clone().subtract(weeklyStockShift, 'days'),
      isoDOW
    );

    // for stock metrics, the DOW aligned interval is then adjusted such that the first day of it
    // is in the middle of the dashboard interval (see `getStockApplicableInterval` in pewter)
    const stockAdjustedCalendarStart =
      metric.type === Types.MetricType.STOCK
        ? calendarStart.clone().subtract(weeklyStockShift + offsetForEndingStockAggregator, 'days')
        : calendarStart;

    // adjust the end of the interval by the same amount that was adjusted for the start
    const calendarOffset = applicableStartDate.diff(stockAdjustedCalendarStart, 'days');

    const adjustedEndDate = applicableEndDate
      .clone()
      .subtract(calendarOffset + openToClosedOffset, 'days');

    if (stockAdjustedCalendarStart.isSameOrBefore(adjustedEndDate)) {
      return {
        start: stockAdjustedCalendarStart.format(DATE_FORMAT),
        end: adjustedEndDate.format(DATE_FORMAT),
      };
    } else {
      return {
        start: 'Nothing To Delete',
        end: 'Nothing To Delete',
      };
    }
  }
}

export function isToday(datePeriod: Types.DatePeriod) {
  return (
    datePeriod.type === 'future' &&
    datePeriod.amount === 0 &&
    datePeriod.unit === Types.CalendarUnit.DAYS
  );
}

export function isYesterday(datePeriod: Types.DatePeriod) {
  return (
    (datePeriod.type === 'lastn' || datePeriod.type === 'previous') &&
    datePeriod.amount === 1 &&
    datePeriod.unit === Types.CalendarUnit.DAYS
  );
}

export function getPeriodUnit(period: Types.DatePeriod): Types.CalendarUnit | null {
  const {type} = period;
  if (
    type === 'complex' ||
    type === 'fixed' ||
    type === 'fixed_to_now' ||
    type === 'now_to_fixed'
  ) {
    return null;
  }
  return period.unit;
}

export function getDaysForPeriod(period: Types.DatePeriod): number | null {
  switch (period.type) {
    case 'lastn':
    case 'nextn':
    case 'future':
    case 'trailing':
    case 'previous':
      return daysPerUnit[period.unit] * period.amount;
    case 'fixed':
      return moment(period.end).diff(moment(period.start), 'days');
    case 'todate':
    case 'todate_weekend':
    case 'to_go':
      return daysPerUnit[period.unit];
    default:
      return null;
  }
}

export function getDaysForSimplePeriod(period: Types.SimplePeriod): number {
  return daysPerUnit[period.unit] * period.amount;
}

export function minDate(a: string | Moment, b: string | Moment): Moment;
export function minDate(a: string | null, b: string | null): Moment | null;
export function minDate(a: string | Moment | null, b: string | Moment | null): Moment | null {
  if (!a) {
    return b ? moment(b) : null;
  }
  if (!b) {
    return a ? moment(a) : null;
  }
  return moment(moment(a).isBefore(b) ? a : b);
}

export function maxDate(a: string | Moment, b: string | Moment): Moment;
export function maxDate(a: string | null, b: string | null): Moment | null;
export function maxDate(a: string | Moment | null, b: string | Moment | null) {
  if (!a) {
    return b ? moment(b) : null;
  }
  if (!b) {
    return a ? moment(a) : null;
  }
  return moment(moment(a).isBefore(b) ? b : a);
}

export function parseLocalDateFromQueryString(s: string | string[] | null): string | null {
  if (s && typeof s === 'string' && moment(s).isValid()) {
    return s;
  }
  return null;
}

export function intervalToFixedPeriod(interval: Types.LocalInterval): Types.FixedDatePeriod {
  return {
    type: 'fixed',
    ...interval,
  };
}

export function roundUpToNextHour(dateTime: string | Moment): Moment {
  const currentDateTime = moment(dateTime);
  return currentDateTime.isSame(currentDateTime.clone().startOf('hour'), 'minute')
    ? currentDateTime
    : currentDateTime.endOf('hour').add(1, 'second');
}

import {ConnectedRouter} from 'connected-react-router';
import * as History from 'history';
import Cookies from 'js-cookie';
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Redirect, Route, RouteProps, Switch, useLocation} from 'react-router';
import {RouteComponentProps} from 'react-router-dom';

import {CacheInspector} from 'app/CacheInspector';
import Watermark from 'app/Watermark';
import Boundary from 'toolkit/components/Boundary';
import Format from 'toolkit/format/format';
import {LOGGED_IN} from 'utils/cookies';
import {reloadAppOnError} from 'utils/exceptions';

import AppContainer from './AppContainer';
import {isBrowserBroken} from './browser-utils';
import EmptyAppLoader from './EmptyAppLoader';

const ActivationPage = React.lazy(() => import('login/ActivationPage').catch(reloadAppOnError));
const ForgotPasswordPage = React.lazy(() =>
  import('login/ForgotPasswordPage').catch(reloadAppOnError)
);
const BrokenBrowserPage = React.lazy(() =>
  import('login/BrokenBrowserPage').catch(reloadAppOnError)
);
const HelpCenterPage = React.lazy(() => import('app/HelpCenterPage').catch(reloadAppOnError));
const GcpRegistrationPage = React.lazy(() =>
  import('login/GcpRegistrationPage').catch(reloadAppOnError)
);
const LoginPage = React.lazy(() => import('login/LoginPage').catch(reloadAppOnError));
const LogoutPage = React.lazy(() => import('login/LogoutPage').catch(reloadAppOnError));
const CredentialActivationPage = React.lazy(() =>
  import('settings/credentials/CredentialActivationPage').catch(reloadAppOnError)
);
const OauthCompletionPage = React.lazy(() =>
  import('settings/credentials/OauthCompletionPage').catch(reloadAppOnError)
);
const SecurityHelpPage = React.lazy(() => import('login/SecurityHelpPage').catch(reloadAppOnError));
const UnsubscribePage = React.lazy(() =>
  import('unsubscribe/UnsubscribePage').catch(reloadAppOnError)
);

function isLoggedIn() {
  return Cookies.get(LOGGED_IN) === 'true';
}

function checkAlreadyLoggedIn(Component: React.ComponentType<any>) {
  return () => (isLoggedIn() ? <Redirect to="/" /> : <Component />);
}

const AuthenticatedRoute: React.FunctionComponent<AuthenticatedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const renderOrRedirect = (props: any) => {
    return isLoggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{pathname: '/login', state: {from: props.location}}} />
    );
  };
  return <Route {...rest} render={renderOrRedirect} />;
};
AuthenticatedRoute.displayName = 'AuthenticatedRoute';
type AuthenticatedRouteProps = RouteProps & {
  component: React.ComponentType<any>;
  location?: History.Location;
};

const CredentialActivationRoute: React.FunctionComponent<CredentialActivationRouteProps> = ({
  component: Component,
  redirectPath,
  ...rest
}) => {
  const location = useLocation();
  const vendor = location.pathname.split('/')[1];
  const redirect = `/${vendor}${redirectPath}`;

  const renderOrRedirect = (props: RouteComponentProps) => {
    return isLoggedIn() ? (
      <Redirect to={{pathname: redirect, search: location.search}} />
    ) : (
      <Component {...props} />
    );
  };
  return <Route {...rest} render={renderOrRedirect} />;
};
CredentialActivationRoute.displayName = 'CredentialActivationRoute';

type CredentialActivationRouteProps = RouteProps & {
  component: React.ComponentType<any>;
  redirectPath: string;
};

const AppRouter: React.FC<Props> = ({history, publicEnvironmentName}) => {
  const defaultTitle = Format.helmetTitle(publicEnvironmentName);
  const titleTemplate = `${defaultTitle} - %s`;

  return (
    // noTimeTravelDebugging disables time travel detection in `ConnectedRouter`.
    // It's not compatible with fetching views asynchronously as it will pollute the browser history
    // unfortunately redux dev tools do not provide a way to detect time travel debugging natively
    <ConnectedRouter history={history} noTimeTravelDebugging>
      <>
        <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} />
        <Boundary fallback={<EmptyAppLoader />}>
          <Switch>
            {isBrowserBroken() && <Route component={BrokenBrowserPage} path="/broken-browser" />}
            {isBrowserBroken() && <Redirect to="/broken-browser" />}
            <Route component={checkAlreadyLoggedIn(ActivationPage)} path="/activate" />
            <CredentialActivationRoute
              component={CredentialActivationPage}
              path="/:vendor/add-credentials"
              // FIXME: we can go to data/status directly once the feature flag has been removed
              redirectPath="/data"
            />
            <Route component={CredentialActivationPage} path="/add-credentials" />
            <Route component={OauthCompletionPage} path="/oauth/callback" />
            <Route component={checkAlreadyLoggedIn(ForgotPasswordPage)} path="/forgot-password" />
            <Route component={SecurityHelpPage} path="/help/security" />
            <Route component={checkAlreadyLoggedIn(LoginPage)} path="/login/:method?/:status?" />
            <Route component={LogoutPage} path="/logout" />
            <Route component={checkAlreadyLoggedIn(ActivationPage)} path="/reset-password" />
            <Route component={UnsubscribePage} path="/unsubscribe" />
            <Route component={GcpRegistrationPage} path="/register/gcp" />
            <AuthenticatedRoute component={HelpCenterPage} path="/help/:article?" />
            <AuthenticatedRoute component={AppContainer} path="/:vendor?" />
          </Switch>
        </Boundary>
        <Watermark />
        <CacheInspector />
      </>
    </ConnectedRouter>
  );
};
AppRouter.displayName = 'AppRouter';

interface Props {
  history: History.History;
  publicEnvironmentName?: string;
}

export default AppRouter;

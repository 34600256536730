import equal from 'fast-deep-equal';
import {List, OrderedMap, Set} from 'immutable';

import {shouldUseGrossSales} from 'planning/utils';
import {CurrentUser} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import Format, {StatisticalAggregation} from 'toolkit/format/format';
import * as Types from 'types';
import {
  CurrencyCode,
  ForecastType,
  MetricArgument,
  MetricArguments,
  MetricInstance,
  MetricValueSeverity,
  ReturnsCountingMethod,
  StockAggregator,
} from 'types';
import {UserFacingError} from 'types/error';
import {first, getMostCommonItem, hasIntersection, last} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isNonNullish, isNullish, isTruthy} from 'utils/functions';
import {without} from 'utils/objects';

import {getUnitConvertedMetric} from './editor/utils';
import {BaseMetricDisplayType, MetricDirection, MetricsByName} from './types';

const isNegative = (value: number | null) => Number.isFinite(value) && value! < 0;
const isPositive = (value: number | null) => Number.isFinite(value) && value! > 0;

const isLessThanOne = (value: number | null) => Number.isFinite(value) && value! < 1;
const isPositiveOverOne = (value: number | null) => Number.isFinite(value) && value! > 1;

export const BACKEND_DEFAULT_DIRECTION: MetricDirection = 'undirected';
export const DEFAULT_DIRECTION: MetricDirection = 'inbound';
export const DEFAULT_PERSPECTIVE: Types.GraphPerspective = Types.GraphPerspective.AT_LOCATION;
export const DEFAULT_STATISTICAL_AGGREGATOR = 'mean';

export const DEFAULT_INSIGHT_PILL_METRICS_LENGTH = 3;

// Expected metrics are explicitly about an expected total, so for any vendors whose default forecast composition is
// not total, this metric will produce a flawed analysis by default. As such, we disable editing forecast composition
// and default it to TOTAL for these metrics.
export const FORECAST_COMPOSITION_DISABLED_METRICS: ReadonlyArray<string | null | undefined> = [
  'expected_sales_units_net',
  'expected_sales_net',
];
export const FORECAST_COMPOSITION_AVAILABLE_FOR_ALL_VENDORS_METRICS = [
  'sales_units_net',
  'sales_units_gross_composable',
];

const SEVERITY_PRIORITIES: {readonly [key in Types.MetricValueSeverity]: number} = {
  ALERT: 100,
  INFO: 0,
  WARNING: 50,
};

const DOLLAR_TYPE_ORDER: OrderedMap<Types.DollarType, Types.MetricFeature> = OrderedMap([
  [Types.DollarType.RETAIL_PRICE, Types.MetricFeature.HAS_RETAIL_PRICE_METRIC],
  [Types.DollarType.SELL_IN_PRICE, Types.MetricFeature.HAS_SELL_IN_PRICE_METRIC],
  [Types.DollarType.MANUFACTURING_COST, Types.MetricFeature.HAS_MANUFACTURING_COST_METRIC],
]);

export type ConfigurableMetricArguments = Exclude<
  Types.MetricArgument,
  | Types.MetricArgument.needsTimeSeries
  | Types.MetricArgument.includeNewProductForecasts
  | Types.MetricArgument.locationType
  | Types.MetricArgument.fillGaps
>;

export type VisibleMetricArguments = Exclude<
  ConfigurableMetricArguments,
  | Types.MetricArgument.convertCurrency
  | Types.MetricArgument.isTimeAgoCalendarPeriodAligned
  | Types.MetricArgument.comparisonMetrics
  | Types.MetricArgument.groupings
  | Types.MetricArgument.childMetrics
>;

export const orderedArgumentTypes: ReadonlyArray<VisibleMetricArguments> = [
  Types.MetricArgument.period,
  Types.MetricArgument.stockAggregator,
  Types.MetricArgument.salesType,
  Types.MetricArgument.dollarType,
  Types.MetricArgument.unitConversionAttribute,
  Types.MetricArgument.outOfStockCalculationMethod,
  Types.MetricArgument.inventoryTypes,
  Types.MetricArgument.inflowTypes,
  Types.MetricArgument.outflowTypes,
  Types.MetricArgument.forecastType,
  Types.MetricArgument.returnsCountingMethod,
  Types.MetricArgument.growthFactor,
  Types.MetricArgument.historicalPeriod,
  Types.MetricArgument.granularity,
  Types.MetricArgument.inventoryMeasure,
  Types.MetricArgument.currency,
  Types.MetricArgument.leadTime,
  Types.MetricArgument.supplyTargetPeriod,
  Types.MetricArgument.timeAgo,
  Types.MetricArgument.timeUnit,
  Types.MetricArgument.storeType,
  Types.MetricArgument.demandSignalPerspective,
  Types.MetricArgument.versionRecency,
  Types.MetricArgument.forecastComposition,
];

export const configurableArgumentTypes: ReadonlyArray<ConfigurableMetricArguments> = [
  ...orderedArgumentTypes,
  Types.MetricArgument.currency,
  Types.MetricArgument.convertCurrency,
  Types.MetricArgument.errorMetric,
  Types.MetricArgument.groupings,
  Types.MetricArgument.isTimeAgoCalendarPeriodAligned,
  Types.MetricArgument.comparisonMetrics,
  Types.MetricArgument.childMetrics,
];

export function filterMetricArguments(
  args: Types.MetricArguments,
  filter: <K extends ConfigurableMetricArguments>(key: K, arg: Types.MetricArguments[K]) => boolean
): Types.MetricArguments {
  const newArgs: any = {};
  getMetricArgumentKeys(args).forEach(key => {
    if (filter(Types.MetricArgument[key], args[Types.MetricArgument[key]])) {
      newArgs[Types.MetricArgument[key]] = args[Types.MetricArgument[key]];
    }
  });
  return newArgs;
}

export function getMetricArgumentKeys(
  args: Types.MetricArguments
): ReadonlyArray<ConfigurableMetricArguments> {
  return configurableArgumentTypes.filter(arg => args[arg] !== undefined);
}

export function getOrderedVisibleMetricArgumentKeys(
  args: Types.MetricArguments
): ReadonlyArray<VisibleMetricArguments> {
  return orderedArgumentTypes.filter(arg => args[arg] !== undefined);
}

export function canHighlightMetric(metricInstance?: Types.MetricInstance | null) {
  if (!metricInstance) {
    return false;
  }

  const features = metricInstance.metric.features.concat(
    metricInstance.arguments.childMetrics
      ? List(metricInstance.arguments.childMetrics)
          .flatMap(metric => metric.features)
          .toSet()
          .toArray()
      : []
  );
  return features.includes(Types.MetricFeature.IS_HIGHLIGHTABLE);
}

export function getSeverity(
  metadata: ReadonlyArray<Types.MetricValueMetadata> | null | undefined
): Types.MetricValueSeverity {
  if (!metadata || !metadata.length) {
    return Types.MetricValueSeverity.INFO;
  }
  return Set(metadata.map(item => item.severity))
    .sortBy(severity => SEVERITY_PRIORITIES[severity])
    .last();
}

export function getMetricHighlight(
  metricInstance: Types.MetricInstance | null,
  value: number | null,
  metadata?: ReadonlyArray<Types.MetricValueMetadata> | null,
  isReversed?: boolean | null,
  ignoreIsNegativeFeature?: boolean
) {
  if (
    !canHighlightMetric(metricInstance) ||
    (Number.isNaN(value) && getSeverity(metadata) !== MetricValueSeverity.INFO)
  ) {
    return getHighlightFromMetadata(metadata);
  }

  const isInverted =
    !ignoreIsNegativeFeature &&
    getEffectiveMetric(metricInstance)?.features.includes(Types.MetricFeature.IS_NEGATIVE);

  const requiresPositiveOverOne =
    isRatioComparisonVariant(metricInstance) &&
    getMetricDisplayType(metricInstance!) === Types.MetricDisplayType.PERCENT;

  const positivePredicate = requiresPositiveOverOne ? isPositiveOverOne : isPositive;
  const negativePredicate = requiresPositiveOverOne ? isLessThanOne : isNegative;

  const goodPredicate = isInverted ? negativePredicate : positivePredicate;
  const badPredicate = isInverted ? positivePredicate : negativePredicate;

  if (goodPredicate(value)) {
    return isReversed ? 'negative' : 'positive';
  } else if (badPredicate(value)) {
    return isReversed ? 'positive' : 'negative';
  }
  return 'neutral';
}

export function getMetricHeaderName(
  metricInstanceConfig: Types.MetricInstanceConfig,
  displayName: string | null,
  hideForecastSuffixes: boolean,
  hideCurrencySuffix: boolean
) {
  return (
    displayName ||
    metricInstanceConfig.customName ||
    Format.metric(metricInstanceConfig.metricInstance, {
      hideDollarType: true,
      hideForecastSuffixes,
      hideCurrencySuffix,
    })
  );
}

export function isComparisonMetric(metricInstance: Types.MetricInstance | null | undefined) {
  if (!metricInstance) {
    return false;
  }

  return (
    getMetricsList(metricInstance)
      .flatMap(metric => metric.features)
      .some(feature => feature === Types.MetricFeature.IS_COMPS) &&
    // time shift isn't considered a comparison metric
    getMetricsList(metricInstance).every(metric => metric.name !== 'metric_comps_value')
  );
}

function isRatioComparisonVariant(metricInstance: Types.MetricInstance | null | undefined) {
  if (metricInstance?.arguments.comparisonMetrics) {
    return areComparisonPeriodsEqual(
      metricInstance.arguments.comparisonMetrics[0],
      metricInstance.arguments.comparisonMetrics[1]
    );
  }

  return false;
}

// should match evaluation of comparisonPeriodsAreEqual in CompsVariantMetricImpl
export function areComparisonPeriodsEqual(
  first: Types.MetricInstance,
  second: Types.MetricInstance | null
) {
  if (!second) {
    return false;
  }
  return (
    equal(first.arguments.period, second.arguments.period) &&
    equal(first.arguments.timeAgo, second.arguments.timeAgo)
  );
}

function getHighlightFromMetadata(
  metadata: ReadonlyArray<Types.MetricValueMetadata> | null | undefined
) {
  switch (getSeverity(metadata)) {
    case Types.MetricValueSeverity.WARNING:
    case Types.MetricValueSeverity.ALERT:
      return 'negative';
    case Types.MetricValueSeverity.INFO:
    default:
      return 'neutral';
  }
}

function getPreciseDisplayType(displayType: BaseMetricDisplayType): BaseMetricDisplayType {
  switch (displayType) {
    case Types.MetricDisplayType.MONEY:
      return Types.MetricDisplayType.PRICE;
    case Types.MetricDisplayType.INTEGER:
      return Types.MetricDisplayType.FLOAT;
    default:
      return displayType;
  }
}

export function getDisplayTypeOfInventory(
  inventoryMeasure: Types.InventoryMeasure
): BaseMetricDisplayType {
  switch (inventoryMeasure) {
    case Types.InventoryMeasure.UNITS:
      return Types.MetricDisplayType.INTEGER;
    case Types.InventoryMeasure.RETAIL:
    case Types.InventoryMeasure.COST:
      return Types.MetricDisplayType.MONEY;
    default:
      throw new Error(`Unsupported inventory measure ${inventoryMeasure}`);
  }
}

function getDisplayTypeOfTimeUnit(timeUnit: Types.CalendarUnit): BaseMetricDisplayType {
  switch (timeUnit) {
    case Types.CalendarUnit.DAYS:
      return Types.MetricDisplayType.INTEGER;
    case Types.CalendarUnit.WEEKS:
      return Types.MetricDisplayType.FLOAT;
    default:
      throw new Error(`Unsupported time unit ${timeUnit}`);
  }
}

function getDisplayTypeOfErrorMetric(errorMetric: Types.ErrorMetric): BaseMetricDisplayType {
  switch (errorMetric) {
    case Types.ErrorMetric.MAPE:
    case Types.ErrorMetric.WAPE:
    case Types.ErrorMetric.MEAN_PERCENTAGE_ERROR:
    case Types.ErrorMetric.ROLLING_WAPE:
      return Types.MetricDisplayType.PERCENT;
    case Types.ErrorMetric.TRACKING_SIGNAL:
      return Types.MetricDisplayType.INTEGER;
    case Types.ErrorMetric.MEAN_ERROR:
      return Types.MetricDisplayType.FLOAT;
    default:
      throw new Error(`Unsupported error metric ${errorMetric}`);
  }
}

export function getMetricDisplayType(metricInstance: Types.MetricInstance): BaseMetricDisplayType {
  const displayType = metricInstance.metric.displayType;
  if (displayType === Types.MetricDisplayType.CHILD) {
    return getMetricDisplayType(getChildMetricInstance(metricInstance));
  } else if (displayType === Types.MetricDisplayType.PRECISE_CHILD) {
    return getPreciseDisplayType(getMetricDisplayType(getChildMetricInstance(metricInstance)));
  } else if (displayType === Types.MetricDisplayType.INVENTORY_MEASURE) {
    return getDisplayTypeOfInventory(metricInstance.arguments.inventoryMeasure!);
  } else if (displayType === Types.MetricDisplayType.TIME_UNIT) {
    return getDisplayTypeOfTimeUnit(metricInstance.arguments.timeUnit!);
  } else if (displayType === Types.MetricDisplayType.ERROR_METRIC) {
    return getDisplayTypeOfErrorMetric(metricInstance.arguments.errorMetric!);
  }
  return displayType;
}

function withoutOwnArguments(metricInstance: Types.MetricInstance) {
  return metricInstance.metric.requiredArguments.reduce(
    (args, key) => ({...args, [key]: null}),
    metricInstance.arguments
  );
}

export function getChildMetricInstance(metricInstance: Types.MetricInstance): Types.MetricInstance {
  const newChildMetrics = metricInstance.arguments.childMetrics!.slice(1);
  return {
    arguments: {
      ...withoutOwnArguments(metricInstance),
      childMetrics: newChildMetrics.length > 0 ? newChildMetrics : null,
    },
    metric: metricInstance.arguments.childMetrics![0],
  };
}

export function getEffectiveMetricName(metricInstance: Types.MetricInstance | null | undefined) {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return effectiveMetric ? effectiveMetric.name : undefined;
}

export function getMetricDisplayName(
  metricInstance: Types.MetricInstance | null
): string | undefined {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return effectiveMetric ? effectiveMetric.displayName : undefined;
}

export function isFlowOrStock(metric: Types.Metric) {
  return metric.type === Types.MetricType.FLOW || metric.type === Types.MetricType.STOCK;
}

export function isForecastMetric(metric: Types.Metric | null | undefined) {
  return metric?.features.includes(Types.MetricFeature.IS_FORECAST);
}

export function isFutureMetric(metric: Types.Metric | null | undefined) {
  return metric?.features.includes(Types.MetricFeature.IS_FUTURE);
}

export function isForecastMetricInstance(metricInstance: Types.MetricInstance | null) {
  return metricInstance && isForecastMetric(getEffectiveMetric(metricInstance));
}

export function isFutureMetricInstance(metricInstance: Types.MetricInstance | null) {
  return metricInstance && isFutureMetric(getEffectiveMetric(metricInstance));
}

export const SHIPMENT_PLAN_METRICS = Set.of(
  'shipment_plan',
  'shipment_plan_cogs',
  'expected_remaining_supply'
);

export function isUndirectedEdgeMetricInstance(metricInstance?: Types.MetricInstance): boolean {
  return (
    !!metricInstance &&
    isEdgeMetric(getEffectiveMetric(metricInstance)) &&
    getDirection(metricInstance) === 'undirected'
  );
}

export function isEdgeMetric(metric?: Types.Metric | null | undefined): boolean {
  return (
    metric?.features.includes(Types.MetricFeature.IS_EDGE_ONLY) ||
    metric?.features.includes(Types.MetricFeature.IS_EDGE) ||
    false
  );
}

export function isForecastMetricWithActualData(metricInstance: Types.MetricInstance | null) {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return (
    effectiveMetric &&
    isForecastMetric(effectiveMetric) &&
    (effectiveMetric.features.includes(Types.MetricFeature.HAS_ACTUAL_PAST_DATA) ||
      metricInstance?.arguments.forecastType === Types.ForecastType.HISTORICAL_AVERAGE)
  );
}

export function isUnitMetric(metricInstance: Types.MetricInstance) {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return !!effectiveMetric?.features.find(
    feature => feature === Types.MetricFeature.IS_UNIT_METRIC
  );
}

export function getMetricsList(
  metricInstance: Types.MetricInstance | null
): ReadonlyArray<Types.Metric> {
  if (!metricInstance) {
    return [];
  }
  return [metricInstance.metric, ...(metricInstance.arguments.childMetrics || [])].filter(isTruthy);
}

export function metricListToMetricInstance(
  metrics: ReadonlyArray<Types.Metric>,
  args: Types.MetricArguments
): Types.MetricInstance {
  if (metrics.length > 1) {
    return {
      metric: first(metrics),
      arguments: {
        ...args,
        childMetrics: metrics.slice(1),
      },
    };
  } else {
    return {metric: last(metrics), arguments: {...args, childMetrics: null}};
  }
}

export function getEffectiveMetric(metricInstance: null | undefined): null;
export function getEffectiveMetric(metricInstance: Types.MetricInstance): Types.Metric;
export function getEffectiveMetric(
  metricInstance: Types.MetricInstance | null | undefined
): Types.Metric | null;
export function getEffectiveMetric(
  metricInstance: Types.MetricInstance | null | undefined
): Types.Metric | null {
  if (!metricInstance) {
    return null;
  }
  const childMetrics = metricInstance.arguments.childMetrics;
  return (childMetrics && last(childMetrics)) || metricInstance.metric;
}

export function isSameEffectiveMetric(
  a: Types.MetricInstance | null | undefined,
  b: Types.MetricInstance | null | undefined
) {
  if (!a && !b) {
    return true;
  }
  if (a && b) {
    return getEffectiveMetric(a).name === getEffectiveMetric(b).name;
  }
  return false;
}

export function replaceInvalidArgs(
  metric: Types.Metric,
  proposedArgs: Types.MetricArguments,
  analysisSettings: Types.VendorAnalysisSettings
) {
  if (SHIPMENT_PLAN_METRICS.has(metric.name)) {
    return {
      ...proposedArgs,
      forecastType: Types.ForecastType.PLAN,
      forecastComposition: Types.ForecastComposition.TOTAL,
    };
  }

  if (metricHasInAndOutFlow(metric)) {
    proposedArgs = {
      ...proposedArgs,
      ...resetForecastTypeOnInOrOutFlowUpdate(
        proposedArgs.inflowTypes,
        proposedArgs.outflowTypes,
        analysisSettings.forecastType
      ),
    };
  }

  if (proposedArgs.currency !== null && proposedArgs.currency) {
    proposedArgs = {
      ...proposedArgs,
      convertCurrency: proposedArgs.currency !== 'AS_REPORTED',
    };
  }

  if (
    proposedArgs.timeAgo?.unit !== Types.CalendarUnit.YEARS &&
    proposedArgs.isTimeAgoCalendarPeriodAligned
  ) {
    proposedArgs = {
      ...proposedArgs,
      isTimeAgoCalendarPeriodAligned: false,
    };
  }

  return proposedArgs;
}

function resetForecastTypeOnInOrOutFlowUpdate(
  inflowTypes: ReadonlyArray<Types.InflowType> | null,
  outflowTypes: ReadonlyArray<Types.OutflowType> | null,
  defaultForecastType: Types.ForecastType
): Partial<MetricArguments> {
  const hasForecastArg = inOrOutFlowHasForecastArg(inflowTypes, outflowTypes);
  const hasPlanArg = inOrOutFlowHasPlanArg(inflowTypes, outflowTypes);

  return !hasForecastArg && !hasPlanArg
    ? {forecastType: defaultForecastType}
    : hasPlanArg
      ? {forecastType: ForecastType.PLAN}
      : {};
}

export function createDefaultMetricInstance(
  metric: Types.Metric,
  period: Types.DatePeriod,
  settings: Settings,
  availableMetrics: MetricsByName,
  currency: Types.CurrencyCode = settings.analysisSettings.currency,
  useDirectionalHigherOrderMetric = true,
  unitConversionAttribute: Types.Attribute | null = null
): Types.MetricInstance {
  const args = getDefaultMetricInstanceArgs(
    settings,
    metric,
    period,
    currency,
    metric.features.includes(Types.MetricFeature.IS_EDGE) && useDirectionalHigherOrderMetric
      ? DEFAULT_DIRECTION
      : undefined
  );

  const metricInstance: Types.MetricInstance =
    metric.features.includes(Types.MetricFeature.IS_EDGE) &&
    availableMetrics.get(DEFAULT_DIRECTION) &&
    useDirectionalHigherOrderMetric
      ? {
          arguments: {...args, childMetrics: [metric]},
          metric: assertTruthy(availableMetrics.get(DEFAULT_DIRECTION)),
        }
      : {
          arguments: args,
          metric,
        };

  return getUnitConvertedMetric(
    settings,
    availableMetrics,
    metricInstance,
    unitConversionAttribute,
    currency
  );
}

export function getDefaultMetricInstanceArgs(
  settings: Settings,
  metric: Types.Metric,
  period: Types.DatePeriod,
  currency: CurrencyCode,
  direction?: MetricDirection
) {
  const vendorSettingsArgs = getMetricArgumentsFromSettings(settings, metric, direction);
  const proposedArgs: Types.MetricArguments =
    SHIPMENT_PLAN_METRICS.has(metric.name) ||
    metric.features.includes(Types.MetricFeature.MATERIALIZED_FROM_PLAN)
      ? getPlanArgs(vendorSettingsArgs, period)
      : {
          ...vendorSettingsArgs,
          period,
          forecastType:
            metric.name === 'target_inventory' || metric.name === 'recommended_shipments'
              ? getNonHistoricalForecastType(assertTruthy(vendorSettingsArgs.forecastType))
              : vendorSettingsArgs.forecastType,
          returnsCountingMethod: shouldUseGrossSales(settings.planSettings)
            ? Types.ReturnsCountingMethod.GROSS
            : Types.ReturnsCountingMethod.NET,
        };

  const argFilter = (arg: Types.MetricArgument) =>
    metric.features.includes(Types.MetricFeature.IS_HIGHER_ORDER) ||
    metric.requiredArguments.includes(arg) ||
    metric.optionalArguments.includes(arg);
  const metricArguments = replaceInvalidArgs(
    metric,
    filterMetricArguments(proposedArgs, argFilter),
    settings.analysisSettings
  );
  if (metricArguments.currency === CurrencyCode.AS_REPORTED) {
    return metricArguments;
  }
  return {...metricArguments, currency};
}

function getPlanArgs(
  defaultArgs: Omit<Types.MetricArguments, 'period'>,
  period: Types.DatePeriod
): Types.MetricArguments {
  return {
    ...defaultArgs,
    period,
    forecastComposition: Types.ForecastComposition.TOTAL,
    forecastType: Types.ForecastType.PLAN,
    versionRecency: null,
  };
}

export function withMetricArguments(
  metricInstance: Types.MetricInstance,
  metricArguments: Partial<Types.MetricArguments>
) {
  return {
    ...metricInstance,
    arguments: {
      ...metricInstance.arguments,
      ...metricArguments,
    },
  };
}

export function getDirection(metricInstance: Types.MetricInstance): MetricDirection {
  const direction = getMetricsList(metricInstance).find(metric =>
    metric.features.includes(Types.MetricFeature.IS_DIRECTION)
  );
  return direction ? (direction.name as MetricDirection) : BACKEND_DEFAULT_DIRECTION;
}

export function getPerspective(metricInstance: Types.MetricInstance): Types.GraphPerspective {
  const perspective = getMetricsList(metricInstance).find(metric =>
    metric.features.includes(Types.MetricFeature.IS_PERSPECTIVE)
  );
  return perspective
    ? (perspective.name.toUpperCase() as Types.GraphPerspective)
    : DEFAULT_PERSPECTIVE;
}

export function getPerspectiveMetric(
  perspective: Types.GraphPerspective | null,
  availableMetrics: MetricsByName
) {
  return perspective && perspective !== DEFAULT_PERSPECTIVE
    ? availableMetrics.get(perspective.toLowerCase())
    : null;
}

export function getStatisticalAggregator(
  metricInstance: Types.MetricInstance | null
): StatisticalAggregation {
  const statisticalAggregator = getMetricsList(metricInstance).find(metric =>
    metric.features.includes(Types.MetricFeature.IS_STATISTICAL_AGGREGATOR)
  ) as {name: StatisticalAggregation};
  if (statisticalAggregator) {
    return statisticalAggregator.name;
  }
  return metricInstance &&
    getEffectiveMetric(metricInstance)!.features.includes(
      Types.MetricFeature.HAS_OPTIONAL_GRANULARITY
    )
    ? 'none'
    : DEFAULT_STATISTICAL_AGGREGATOR;
}

export function getNonHistoricalForecastType(vendorForecastType: Types.ForecastType) {
  return vendorForecastType !== Types.ForecastType.ANNUAL_GROWTH
    ? vendorForecastType
    : Types.ForecastType.PARTNER;
}

export function getGrowthFactorFromSettings(
  settings: Types.VendorAnalysisSettings
): Types.GrowthFactor {
  return {
    value: settings.growthFactorConstant,
  };
}

// Returns the first available metric from a list of given metric names, or a fallback if none
// were found.
export function getFirstAvailableMetric(
  availableMetrics: MetricsByName,
  preferredMetrics: ReadonlyArray<string>
) {
  return (
    preferredMetrics.map(metricName => availableMetrics.get(metricName)).filter(isTruthy)[0] ||
    getFirstSimpleMetric(availableMetrics)
  );
}

function getFirstSimpleMetric(availableMetrics: MetricsByName) {
  const requiredArgsSet = Set([Types.MetricArgument.period]);
  return availableMetrics
    .filter(metric => Set(metric.requiredArguments).equals(requiredArgsSet))
    .first();
}

export function getDefaultMetricArguments(period: Types.DatePeriod): Types.MetricArguments {
  return {
    period,
    childMetrics: null,
    comparisonMetrics: null,
    demandSignalPerspective: null,
    dollarType: null,
    errorMetric: null,
    forecastComposition: null,
    forecastType: null,
    granularity: null,
    groupings: null,
    growthFactor: null,
    historicalPeriod: null,
    inflowTypes: null,
    inventoryMeasure: null,
    inventoryTypes: null,
    leadTime: null,
    outOfStockBehaviour: null,
    outOfStockCalculationMethod: null,
    outflowTypes: null,
    salesType: null,
    stockAggregator: null,
    storeType: null,
    supplyTargetPeriod: null,
    versionRecency: null,
    timeAgo: null,
    isTimeAgoCalendarPeriodAligned: null,
    timeUnit: null,
    unitConversionAttribute: null,
    returnsCountingMethod: null,
    currency: null,
    convertCurrency: null,
    locationType: null,
  };
}

export function getDefaultMetricArgumentsWithStockAggregator(
  period: Types.DatePeriod,
  metric: Types.Metric,
  stockAgg: Types.StockAggregator
): Types.MetricArguments {
  return {
    ...getDefaultMetricArguments(period),
    stockAggregator: metric.requiredArguments.includes(MetricArgument.stockAggregator)
      ? stockAgg
      : null,
  };
}

export function getMetricArgumentsFromSettings(
  settings: Settings,
  metric?: Types.Metric | null,
  direction?: MetricDirection
): Omit<Types.MetricArguments, 'period'> {
  return {
    childMetrics: null,
    comparisonMetrics: null,
    demandSignalPerspective: settings.analysisSettings.demandSignalPerspective,
    dollarType:
      (metric && getSupportedDollarTypes(metric, direction)[0]) || Types.DollarType.RETAIL_PRICE,
    errorMetric: settings.analysisSettings.errorMetric,
    forecastType: settings.analysisSettings.forecastType,
    growthFactor: getGrowthFactorFromSettings(settings.analysisSettings),
    forecastComposition:
      metric?.name === 'sales_units_gross_composable' ||
      metric?.name === 'sales_units_net' ||
      FORECAST_COMPOSITION_DISABLED_METRICS.includes(metric?.name)
        ? Types.ForecastComposition.TOTAL
        : settings.analysisSettings.forecastComposition,
    granularity: settings.analysisSettings.granularity,
    groupings:
      metric?.name === 'forecast_error' ? settings.analysisSettings.forecastErrorGranularity : null,
    historicalPeriod: settings.analysisSettings.historicalPeriod,
    inventoryMeasure: settings.analysisSettings.inventoryMeasure,
    inventoryTypes: settings.analysisSettings.inventoryTypes,
    leadTime: settings.analysisSettings.leadTime,
    outOfStockBehaviour: settings.analysisSettings.outOfStockBehaviour,
    outOfStockCalculationMethod: settings.analysisSettings.outOfStockCalculationMethod,
    stockAggregator: settings.analysisSettings.stockAggregator,
    supplyTargetPeriod: settings.analysisSettings.supplyTargetPeriod,
    timeAgo: null, // FIXME: why isn't this `settings.timeAgo`?
    timeUnit: settings.analysisSettings.timeUnit,
    isTimeAgoCalendarPeriodAligned: false,
    unitConversionAttribute: settings.analysisSettings.unitConversionAttributes[0] || null,
    salesType: settings.analysisSettings.salesType,
    storeType: settings.analysisSettings.storeType,
    inflowTypes: settings.analysisSettings.inflowType ? [settings.analysisSettings.inflowType] : [],
    outflowTypes: settings.analysisSettings.outflowType
      ? [settings.analysisSettings.outflowType]
      : [],
    versionRecency: null,
    returnsCountingMethod: shouldUseGrossSales(settings.planSettings)
      ? ReturnsCountingMethod.GROSS
      : ReturnsCountingMethod.NET,
    currency: settings.analysisSettings.currency,
    convertCurrency: settings.analysisSettings.currency !== 'AS_REPORTED',
    locationType: null,
  };
}

export function getMetricArgumentsFromSettingsAndPlanVersion(
  settings: Settings,
  planVersion: Types.PlanVersion
): Omit<Types.MetricArguments, 'period'> {
  return {
    ...getMetricArgumentsFromSettings(settings),
    forecastType: Types.ForecastType.PLAN,
    forecastComposition: Types.ForecastComposition.TOTAL,
    granularity: planVersion.basePlan.granularity,
    growthFactor: planVersion.growthFactor,
    timeUnit: planVersion.basePlan.granularity,
    stockAggregator: StockAggregator.ENDING,
  };
}

export function getMetadata(
  metadata: ReadonlyArray<Types.MetricValueMetadata> | null | undefined,
  type: Types.MetricValueMetadata['type']
) {
  return metadata?.find(item => item.type === type);
}

export function getOriginalValue(
  metadata: ReadonlyArray<Types.MetricValueMetadata> | null | undefined
): Types.OriginalValueMetricValueMetadata | null {
  const originalValue = getMetadata(metadata, 'ORIGINAL_VALUE');
  return originalValue as Types.OriginalValueMetricValueMetadata;
}

export function hasOverride(metadata: ReadonlyArray<Types.MetricValueMetadata> | null | undefined) {
  return !!getOriginalValue(metadata);
}

export function isRankBasedMetricFilter(metricFilter: Types.MetricFilter) {
  return isRankBasedMetricFilterPredicate(metricFilter.predicate);
}

export function isRankBasedMetricFilterPredicate(predicate: Types.MetricFilterPredicate) {
  return (
    predicate === Types.MetricFilterPredicate.TOP ||
    predicate === Types.MetricFilterPredicate.BOTTOM
  );
}

const predicateInverse: {
  readonly [key in Types.MetricFilterPredicate]: Types.MetricFilterPredicate;
} = {
  [Types.MetricFilterPredicate.EQ]: Types.MetricFilterPredicate.NE,
  [Types.MetricFilterPredicate.NE]: Types.MetricFilterPredicate.EQ,
  [Types.MetricFilterPredicate.GE]: Types.MetricFilterPredicate.LT,
  [Types.MetricFilterPredicate.LT]: Types.MetricFilterPredicate.GE,
  [Types.MetricFilterPredicate.LE]: Types.MetricFilterPredicate.GT,
  [Types.MetricFilterPredicate.GT]: Types.MetricFilterPredicate.LE,
  [Types.MetricFilterPredicate.TOP]: Types.MetricFilterPredicate.BOTTOM,
  [Types.MetricFilterPredicate.BOTTOM]: Types.MetricFilterPredicate.TOP,
};

export function invertMetricFilter(metricFilter: Types.MetricFilter): Types.MetricFilter {
  return {...metricFilter, predicate: predicateInverse[metricFilter.predicate]};
}

export function getMostCommonMetricArg<K extends keyof Types.MetricArguments>(
  metrics: ReadonlyArray<Types.MetricInstanceConfig>,
  argName: K,
  defaultValue: Types.MetricArguments[K]
): Types.MetricArguments[K] {
  const arg = getMostCommonItem(
    metrics.map(({metricInstance}) => metricInstance.arguments[argName] || null).filter(isTruthy)
  );
  return arg ?? defaultValue;
}

export function supportsEditingVersionRecency(
  metric: Types.Metric,
  forecastType: Types.ForecastType | null | undefined
) {
  if (metricHasInAndOutFlow(metric)) {
    return true;
  } else {
    return (
      isPlanningMetric(metric) ||
      (forecastType !== null &&
        forecastType !== undefined &&
        forecastType !== Types.ForecastType.USAGE)
    );
  }
}

export function supportsEditingForecastComposition(
  metricInstance: Types.MetricInstance,
  user: CurrentUser
) {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return (
    (effectiveMetric.optionalArguments.includes(Types.MetricArgument.forecastComposition) ||
      effectiveMetric.requiredArguments.includes(Types.MetricArgument.forecastComposition)) &&
    (user.vendor.isDemandPlanningEnabled || user.featureFlags.has(FeatureFlag.DEPROMO)) &&
    !FORECAST_COMPOSITION_DISABLED_METRICS.includes(effectiveMetric.name)
  );
}

export function supportsEditingForecastType(
  metric: Types.Metric,
  inflowTypes: ReadonlyArray<Types.InflowType> | null | undefined,
  outflowTypes: ReadonlyArray<Types.OutflowType> | null | undefined
) {
  const metricRequiresInAndOutFlows = metricHasInAndOutFlow(metric);
  if (metricRequiresInAndOutFlows) {
    const hasForecastInOrOutFlows = inOrOutFlowHasForecastArg(inflowTypes, outflowTypes);
    const hasPlanInOrOutFlows = inOrOutFlowHasPlanArg(inflowTypes, outflowTypes);
    return hasForecastInOrOutFlows && !hasPlanInOrOutFlows;
  } else {
    return !metric.features.includes(Types.MetricFeature.MATERIALIZED_FROM_PLAN);
  }
}

export function isPlanningMetric(metric: Types.Metric | null) {
  if (metric === null) {
    return false;
  }
  return (
    metric.features.includes(Types.MetricFeature.IS_DEMAND_PLAN_METRIC) ||
    metric.features.includes(Types.MetricFeature.IS_INVENTORY_PLAN_METRIC)
  );
}

export function isPlanningMetricOrForecastType(
  metric: Types.Metric | null,
  forecastType: ForecastType | null
) {
  if (metric === null) {
    return false;
  }
  return isPlanningMetric(metric) || forecastType === Types.ForecastType.PLAN;
}

/**
 * this will also include the actual forecast type compared to `isPlanningMetric`
 * @param metricInstance
 */
export function isPlanningMetricInstance(metricInstance: MetricInstance) {
  return (
    isPlanningMetric(getEffectiveMetric(metricInstance)) ||
    metricInstance.arguments.forecastType === Types.ForecastType.PLAN
  );
}

const FORECAST_INFLOW_TYPES: ReadonlyArray<Types.InflowType> = [
  Types.InflowType.FORECASTED_RECEIPTS,
  Types.InflowType.FORECASTED_ORDERS,
  Types.InflowType.FORECASTED_SHIPMENTS,
];

const FORECAST_OUTFLOW_TYPES: ReadonlyArray<Types.OutflowType> = [
  Types.OutflowType.UNIT_SALES,
  Types.OutflowType.FORECASTED_SHIPMENTS,
  Types.OutflowType.DOWNSTREAM_FORECASTED_SHIPMENTS,
  Types.OutflowType.FORECASTED_SHIPMENTS_BLENDED,
];

const PLAN_INFLOW_TYPES: ReadonlyArray<Types.InflowType> = [
  Types.InflowType.PLANNED_SHIPMENTS,
  Types.InflowType.PLANNED_RECEIPTS,
  Types.InflowType.PROJECTED_RECEIPTS_IN_TRANSIT,
];

const PLAN_OUTFLOW_TYPES: ReadonlyArray<Types.OutflowType> = [
  Types.OutflowType.PLANNED_SHIPMENTS,
  Types.OutflowType.DOWNSTREAM_PLANNED_SHIPMENTS,
];

export function isForecastInflowType(inflowType: Types.InflowType) {
  return FORECAST_INFLOW_TYPES.includes(inflowType);
}

export function isForecastOutflowType(outflowType: Types.OutflowType) {
  return FORECAST_OUTFLOW_TYPES.includes(outflowType);
}

export function inOrOutFlowHasForecastArg(
  inflowTypes: ReadonlyArray<Types.InflowType> | null | undefined,
  outflowTypes: ReadonlyArray<Types.OutflowType> | null | undefined
) {
  return (
    (inflowTypes && hasIntersection(FORECAST_INFLOW_TYPES, inflowTypes)) ||
    (outflowTypes && hasIntersection(FORECAST_OUTFLOW_TYPES, outflowTypes))
  );
}

export function inOrOutFlowHasPlanArg(
  inflowTypes: ReadonlyArray<Types.InflowType> | null | undefined,
  outflowTypes: ReadonlyArray<Types.OutflowType> | null | undefined
) {
  return (
    (inflowTypes && hasIntersection(PLAN_INFLOW_TYPES, inflowTypes)) ||
    (outflowTypes && hasIntersection(PLAN_OUTFLOW_TYPES, outflowTypes))
  );
}

export function isShipmentPlanMetric(metricInstance: Types.MetricInstance): boolean {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return SHIPMENT_PLAN_METRICS.has(effectiveMetric?.name ?? '');
}

export function isSupplyNetworkPerspectiveSupported(metricInstance: Types.MetricInstance): boolean {
  return getEffectiveMetricName(metricInstance) !== 'total_unconstrained_demand';
}

export function metricInstanceSupportsCurrency(metricInstance: Types.MetricInstance) {
  const metric = getEffectiveMetric(metricInstance);
  return (
    metric.requiredArguments.includes(Types.MetricArgument.currency) ||
    (metric.optionalArguments.includes(Types.MetricArgument.currency) &&
      (metricInstance.arguments.inventoryMeasure === Types.InventoryMeasure.COST ||
        metricInstance.arguments.inventoryMeasure === Types.InventoryMeasure.RETAIL))
  );
}

export function metricHasInAndOutFlow(metric: Types.Metric) {
  return (
    metric.requiredArguments.includes(Types.MetricArgument.inflowTypes) &&
    metric.requiredArguments.includes(Types.MetricArgument.outflowTypes)
  );
}

export function getSupportedDollarTypes(
  metric: Types.Metric | null,
  direction: MetricDirection = 'undirected'
): Types.DollarType[] {
  const metricFeatures =
    direction === 'undirected' || !metric?.features.includes(Types.MetricFeature.IS_EDGE)
      ? metric?.features
      : direction === 'inbound'
        ? metric?.destinationMetric?.features
        : metric?.originMetric?.features;
  return DOLLAR_TYPE_ORDER.filter(feature => metricFeatures?.includes(feature))
    .keySeq()
    .toArray();
}

export function isAsReportedCurrencyMetric(metricInstance: Types.MetricInstance) {
  const effectiveMetric = getEffectiveMetric(metricInstance);
  return (
    effectiveMetric.requiredArguments.includes(Types.MetricArgument.currency) &&
    metricInstance.arguments.currency === Types.CurrencyCode.AS_REPORTED
  );
}

// Null out any unpopulated (undefined) arguments
export function populateMetricArguments(
  metricArguments: Types.MetricArguments,
  ignoredMetricArguments?: ReadonlyArray<keyof Types.MetricArguments>
): Types.MetricArguments {
  return {
    ...getDefaultMetricArguments(metricArguments.period),
    ...(ignoredMetricArguments
      ? without(metricArguments, ignoredMetricArguments)
      : metricArguments),
  };
}

export function areMetricArgumentsEqual(
  metricArgumentsA: Types.MetricArguments,
  metricArgumentsB: Types.MetricArguments,
  ignoreArguments?: ReadonlyArray<keyof Types.MetricArguments>
) {
  return equal(
    populateMetricArguments(metricArgumentsA, ignoreArguments),
    populateMetricArguments(
      // period cannot be null, so instead force it to be equal
      ignoreArguments?.includes('period')
        ? {...metricArgumentsB, period: metricArgumentsA.period}
        : metricArgumentsB,
      ignoreArguments
    )
  );
}

export function areMetricsEqual(
  metricA: Types.MetricInstance,
  metricB: Types.MetricInstance,
  ignoreArguments?: ReadonlyArray<keyof Types.MetricArguments>
): boolean {
  return (
    metricA.metric.id === metricB.metric.id &&
    equal(
      populateMetricArguments(metricA.arguments, ignoreArguments),
      populateMetricArguments(metricB.arguments, ignoreArguments)
    )
  );
}

export function isPriorValueOrContributionComparison(
  baseMetricInstance: Types.MetricInstance,
  comparedToMetricInstance: Types.MetricInstance
): boolean {
  return (
    isPriorValueComparisonMetric(baseMetricInstance, comparedToMetricInstance) &&
    areMetricArgumentsEqual(baseMetricInstance.arguments, comparedToMetricInstance.arguments, [
      'timeAgo',
    ])
  );
}

export function getEffectiveComparisonMetrics(metricInstance: Types.MetricInstance) {
  // the effective compared to metric can include `inbound` or `outbound` higher order metrics
  return getMetricsList(metricInstance).filter(
    metric =>
      ['inbound', 'outbound'].includes(metric.name) ||
      !metric.features.includes(Types.MetricFeature.IS_HIGHER_ORDER)
  );
}

export function getComparisonMetricInstanceIdentifier(
  metricInstance: Types.MetricInstance
): string {
  return getEffectiveComparisonMetrics(metricInstance)
    .map(({name}) => name)
    .join('_');
}

export function isPriorValueComparisonMetric(
  baseMetricInstance: Types.MetricInstance,
  comparisonMetricInstance: Types.MetricInstance | null
): boolean {
  if (!comparisonMetricInstance) {
    return false;
  }
  return (
    getComparisonMetricInstanceIdentifier(baseMetricInstance) ===
    getComparisonMetricInstanceIdentifier(comparisonMetricInstance)
  );
}

/**
 * Check to make sure the passed in metrics are in the correct order for the insight
 * pill. The expected order is [comparison metric, base metric, compared to metric].
 */
export function areValidInsightPillMetrics(
  metrics: ReadonlyArray<Types.MetricInstance> | null | undefined
): boolean {
  if (!metrics || metrics.length < DEFAULT_INSIGHT_PILL_METRICS_LENGTH) {
    return false;
  }
  const [comparisonMetric, baseMetric, comparedToMetric] = metrics;
  const baseComparisonMetric = first(comparisonMetric.arguments.comparisonMetrics ?? []);
  const comparedToComparisonMetric = last(comparisonMetric.arguments.comparisonMetrics ?? []);
  const baseMetricsMatch =
    baseMetric &&
    getComparisonMetricInstanceIdentifier(baseMetric) ===
      getComparisonMetricInstanceIdentifier(baseComparisonMetric) &&
    areMetricArgumentsEqual(baseMetric.arguments, baseComparisonMetric.arguments, [
      'childMetrics',
      'timeAgo',
    ]);
  const comparedToMetricsMatch =
    comparedToMetric &&
    getComparisonMetricInstanceIdentifier(comparedToMetric) ===
      getComparisonMetricInstanceIdentifier(comparedToComparisonMetric) &&
    areMetricArgumentsEqual(comparedToMetric.arguments, comparedToComparisonMetric.arguments, [
      'childMetrics',
      'timeAgo',
    ]);
  return isComparisonMetric(comparisonMetric) && baseMetricsMatch && comparedToMetricsMatch;
}

export function isSimulationMetric(metric: Types.Metric | null | undefined) {
  return metric && metric.requiredArguments.includes(Types.MetricArgument.inflowTypes);
}

export function isMoney(metricInstance: Types.MetricInstance): boolean {
  return getEffectiveMetric(metricInstance).displayType === Types.MetricDisplayType.MONEY;
}
export function getMetricOrThrow(
  metricName: string,
  metricsByName: MetricsByName,
  customErrorMessage?: string
): Types.Metric {
  const metric = metricsByName.get(metricName);
  if (isNullish(metric)) {
    throw isNonNullish(customErrorMessage)
      ? new UserFacingError(customErrorMessage)
      : new Error(`Could not find required metric ${metricName}`);
  }
  return metric;
}

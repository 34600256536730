import * as Api from 'api';
import * as Types from 'types';

import ActionType from './types';

export type PoliciesAction = {
  type: ActionType.SetPolicyData;
  data?: ReadonlyArray<Types.UserFilters>;
  promise: Promise<ReadonlyArray<Types.UserFilters>>;
};

export function fetchPolicyData(): PoliciesAction {
  return {
    promise: Api.Policies.getPolicies(),
    type: ActionType.SetPolicyData,
  };
}

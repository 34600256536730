import * as Types from 'types';
import {Status} from 'utils/status';

export interface SaveStatus {
  readonly isSaving?: boolean;
  readonly isFailed?: boolean;
  readonly updatedFields?: ReadonlyArray<string>;
  readonly errorMessage?: string;
}

export interface CredentialsValidityStatus {
  readonly requestStatus: Status;
  readonly result?: Types.ConnectionTestResult;
}

// order of enum entries matters
export enum CredentialRequestStatus {
  UNKNOWN,
  UP_TO_DATE,
  OWNER_NOT_SPECIFIED,
  EMAIL_FAILED,
  REQUEST_PENDING,
  REQUEST_EXPIRED,
}

import './TransactionMetricCell.scss';
import {List} from 'immutable';
import moment from 'moment';
import React, {useMemo, useState} from 'react';

import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';
import {withColorizedMetricValueCell} from 'toolkit/ag-grid/cell-renderers/ColorizedMetricValueCell';
import {
  ComputeResultData,
  MetricCellValue,
  TypedCellRendererParams,
  TypedRowNode,
} from 'toolkit/ag-grid/types';
import {isPartner} from 'toolkit/attributes/utils';
import Button, {ButtonDisplayVariant} from 'toolkit/components/Button';
import {DATE_FORMAT} from 'toolkit/format/constants';
import {MetricsByName} from 'toolkit/metrics/types';
import {getDirection, getEffectiveMetric, withMetricArguments} from 'toolkit/metrics/utils';
import OrdersPreviewModal from 'toolkit/transactions/OrdersPreviewModal';
import * as Types from 'types';
import {EdgeDataSourceType} from 'types';
import {isTruthy} from 'utils/functions';

import {
  createSelectionFilterFromAttributeValue,
  getFlattenedTableAttributeValues,
  getGroupedTableAttributeValues,
  getLevelFromData,
} from './selection';
import {TableSelectionAttributeValue, TransactionMetricCellParams} from './types';

const TransactionMetricCell: React.FC<Props> = ({
  columnGroupings,
  columnPath,
  data,
  node,
  attributeInstances,
  metricInstanceConfig,
  valueFormatted,
  calendarProperties,
  evaluationDate,
  isFlattened,
  viewFilters,
  ignoreViewFilters,
  widgetAttributeFilters,
  otherFilters,
}) => {
  const edgeDataRanges = useSelector(state => state.analysis.data.edgeDataRanges);
  const currentUser = useCurrentUser();
  const metricInstance = metricInstanceConfig.metricInstance;
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);

  // attributeInstance = rowGroupings
  // columnGroupings = columns of PIVOT_TABLE
  // Get list of all attribute values of the row/column
  const attributeValues: TableSelectionAttributeValue[] = useMemo(() => {
    const pivotTableGroupings = columnGroupings || [];
    const pivotTablePath = columnPath ? columnPath.toArray() : [];
    return [
      ...(isFlattened
        ? getFlattenedTableAttributeValues(attributeInstances, data.data.values)
        : getGroupedTableAttributeValues(attributeInstances, node)),
      ...getFlattenedTableAttributeValues(pivotTableGroupings, pivotTablePath),
    ];
  }, [isFlattened, columnGroupings, attributeInstances, node, columnPath, data]);
  // Convert 'selectable' (non-date, non-interval) attribute values to filters
  const attributeFilters: Types.AttributeFilter[] = useMemo(() => {
    return attributeValues.map(createSelectionFilterFromAttributeValue).filter(isTruthy);
  }, [attributeValues]);

  const getPathPartnerScope = (
    attributeValues: TableSelectionAttributeValue[],
    attributeFilters: Types.AttributeFilter[]
  ): (number | null)[] => {
    const partnerFromGrouping = attributeValues.filter(value =>
      isPartner(value.attributeInstance.attribute)
    );
    if (partnerFromGrouping.length > 0) {
      return partnerFromGrouping.map(value => value.attributeValue?.id || null);
    }
    return attributeFilters.flatMap(filter =>
      filter.attributeInstance.attribute.name === 'Partner' && filter.inclusive
        ? filter.values.map(value => value.id)
        : []
    );
  };

  const pathPartnerScope = getPathPartnerScope(attributeValues, [
    ...(ignoreViewFilters ? [] : viewFilters),
    ...widgetAttributeFilters,
  ]);
  const metric = getEffectiveMetric(metricInstance);
  const direction = getDirection(metricInstance);
  const transactionRangeMatches = (range: Types.EdgeDataRange) => {
    if (
      range.vendorId !== currentUser.vendor.id ||
      range.dataSourceType !== EdgeDataSourceType.TRANSACTION ||
      metric === null ||
      range.metric.id !== metric.id
    ) {
      return false;
    }
    if (pathPartnerScope.length === 0) {
      return true;
    }
    if (direction === 'undirected')
      return (
        pathPartnerScope.includes(range.destinationPartnerId) ||
        pathPartnerScope.includes(range.originPartnerId)
      );

    if (direction === 'inbound') {
      return pathPartnerScope.includes(range.destinationPartnerId);
    }
    if (direction === 'outbound') {
      return pathPartnerScope.includes(range.originPartnerId);
    }
    return false;
  };

  const hasTransactionData = edgeDataRanges.some(transactionRangeMatches);

  // To support filtering the OrdersPreviewModal by a date/interval grouping
  // we have to find the lowest level attribute of type `interval`/`date` and
  // replace the transaction metric instance's `period` with a fixed date
  // period using that attribute value
  const modifiedMetricInstance: Types.MetricInstance = useMemo(() => {
    const dateAttributeValues = attributeValues.filter(
      attributeValue =>
        attributeValue.attributeInstance &&
        attributeValue.attributeValue &&
        attributeValue.attributeValue.value &&
        [Types.AttributeValueType.date, Types.AttributeValueType.interval].includes(
          attributeValue.attributeInstance.attribute.valueType
        )
    );
    if (dateAttributeValues.length > 0) {
      const lastValue = dateAttributeValues[dateAttributeValues.length - 1];
      const attributeInstance = lastValue.attributeInstance;
      const value = lastValue.attributeValue.value;
      const period: Types.FixedDatePeriod = {
        type: 'fixed',
        ...(attributeInstance.attribute.valueType === Types.AttributeValueType.date
          ? {
              start: value,
              end: moment(value).add(1, 'day').format(DATE_FORMAT),
            }
          : value),
      };
      return withMetricArguments(metricInstance, {period});
    }

    return metricInstance;
  }, [metricInstance, attributeValues]);

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setTransactionModalOpen(true);
  };

  return (
    <div className="TransactionMetricCell">
      {getLevelFromData(data) !== -1 && hasTransactionData ? (
        <Button displayVariant={ButtonDisplayVariant.LINK} onClick={handleClick}>
          {valueFormatted}
        </Button>
      ) : (
        valueFormatted
      )}
      {transactionModalOpen && (
        <OrdersPreviewModal
          attributeFilters={attributeFilters
            .concat([
              ...widgetAttributeFilters,
              ...otherFilters,
              ...(ignoreViewFilters ? [] : viewFilters),
            ])
            .filter(
              attributeFilter =>
                Types.AttributeType.DATE !== attributeFilter.attributeInstance.attribute.type &&
                attributeFilter.values.length > 0 &&
                attributeFilter.values.every(attributeValue => attributeValue.value)
            )}
          calendarProperties={calendarProperties}
          evaluationDate={evaluationDate}
          metricInstance={modifiedMetricInstance}
          onClose={() => setTransactionModalOpen(false)}
        />
      )}
    </div>
  );
};

TransactionMetricCell.displayName = 'TransactionMetricCell';
export default withColorizedMetricValueCell(TransactionMetricCell);

type Props = TransactionMetricCellParams &
  TypedCellRendererParams<ComputeResultData> & {
    columnGroupings?: ReadonlyArray<Types.AttributeInstance>;
    columnPath?: List<Types.ThinAttributeValue>;
    eGridCell: HTMLElement;
    data: ComputeResultData;
    node: TypedRowNode<ComputeResultData>;
    planVersionId?: Types.PlanVersion['id'];
    value: MetricCellValue;
    valueFormatted: string;
    disableTooltip: boolean;
    metricsByName: MetricsByName;
  };

import './RouterLinkCell.scss';

import classNames from 'classnames';
import {default as React, ReactElement, useMemo, useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';

import {TagsEditor} from 'dashboard/TableComponents';
import {CurrentUserProps, useCurrentUser} from 'redux/context/user';
import BaseTruncatedCellRenderer from 'toolkit/ag-grid/cell-renderers/BaseTruncatedCellRenderer';
import {TypedCellRendererParams} from 'toolkit/ag-grid/types';
import {AnalysisEntity, AnalysisEntityType} from 'toolkit/analysis/types';
import {ButtonDisplayVariant} from 'toolkit/components/Button';
import Centered from 'toolkit/components/Centered';
import ContextMenuPopover from 'toolkit/components/ContextMenuPopover';
import DateTooltip from 'toolkit/components/DateTooltip';
import Icon from 'toolkit/components/Icon';
import IconButton from 'toolkit/components/IconButton';
import WalmartNovaRecommendationsIcon from 'toolkit/components/images/walmart-nova-recommendations-icon.svg';
import Tooltip from 'toolkit/components/Tooltip';
import {titleCase} from 'toolkit/format/text';
import {TextLengthFormat} from 'toolkit/format/types';
import {IconSpec} from 'toolkit/icons/types';
import NavLinkWithBreadcrumb from 'toolkit/plans/NavLinkWithBreadcrumb';
import TagsList from 'toolkit/tags/TagsList';
import {isViewEditable, hasPermission} from 'toolkit/users/utils';
import {defaultOverlayProps} from 'toolkit/utils/react-bootstrap';
import * as Types from 'types';
import {isTruthy} from 'utils/functions';
import calendarIcon from 'widgets/icons/widget-type-calendar.svg';
import tableIcon from 'widgets/icons/widget-type-table.svg';

const nameCellWorkflowIcons: {[key in Types.Workflow]: IconSpec} = {
  [Types.Workflow.WALMART_NOVA_RECOMMENDATIONS]: {alloyIcon: WalmartNovaRecommendationsIcon},
  [Types.Workflow.PROMOTION_ANALYSIS]: {alloyIcon: calendarIcon},
  [Types.Workflow.DIAGNOSTICS_INSTOCK_PERCENT_CHANGE]: {alloyIcon: tableIcon},
  [Types.Workflow.DIAGNOSTICS_LOCATION_COUNT_CHANGE]: {alloyIcon: tableIcon},
  [Types.Workflow.DIAGNOSTICS_MIX_SHIFT]: {alloyIcon: tableIcon},
  [Types.Workflow.DIAGNOSTICS_PRICE_CHANGE]: {alloyIcon: tableIcon},
  [Types.Workflow.DIAGNOSTICS_PROMOTIONS]: {alloyIcon: tableIcon},
  [Types.Workflow.DIAGNOSTICS_SALES_PERFORMANCE]: {alloyIcon: tableIcon},
};

export const NameCell: React.FC<NameCellProps> = ({
  onChangeFavoriteStatus,
  showTypeIcons,
  wrapWithLink,
  ...rest
}) => {
  const content = (
    <>
      {!onChangeFavoriteStatus && showTypeIcons && (
        <Icon className="prefix-icon" icon={rest.data.icon} />
      )}
      <span className="name">{rest.data.name}</span>
      {rest.data.type === AnalysisEntityType.DASHBOARD &&
        rest.data.workflows &&
        rest.data.workflows.map(workflow => (
          <Tooltip key={workflow} tooltip={titleCase(workflow, '_')}>
            <Icon icon={nameCellWorkflowIcons[workflow]} />
          </Tooltip>
        ))}
      {rest.data.isPrivate && <Icon icon="lock" />}
    </>
  );
  return (
    <div className="NameCell">
      {onChangeFavoriteStatus && (
        <span onClick={() => onChangeFavoriteStatus(rest.data)}>
          <Icon className="prefix-icon" icon={[rest.data.isFavorite ? 'fas' : 'far', 'star']} />
        </span>
      )}
      {wrapWithLink ? <RouterLinkCell {...rest}>{content}</RouterLinkCell> : content}
    </div>
  );
};
export type NameCellParams = {
  onChangeFavoriteStatus?: (item: AnalysisEntity) => void;
  showTypeIcons: boolean;
  wrapWithLink: boolean;
};
type NameCellProps = TypedCellRendererParams<AnalysisEntity, boolean> & NameCellParams;

export const RouterLinkCell: React.FC<RouterLinkCellProps> = props => {
  const linkingEntityName =
    props.data.type === AnalysisEntityType.PLAN ? props.data.linkingEntityName : undefined;
  return (
    <NavLinkWithBreadcrumb
      className="RouterLinkCell"
      linkingEntityName={linkingEntityName}
      to={props.data.url}
    >
      <div className="contents">{props.children || props.valueFormatted || props.value}</div>
    </NavLinkWithBreadcrumb>
  );
};
export type RouterLinkCellParams = {
  children?: React.ReactNode;
};
export type RouterLinkCellProps = TypedCellRendererParams<AnalysisEntity, unknown> &
  RouterLinkCellParams;

export const TruncatedRouterLinkCell: React.FC<RouterLinkCellProps> = props => {
  return (
    <RouterLinkCell {...props}>
      <BaseTruncatedCellRenderer {...props} />
    </RouterLinkCell>
  );
};

export const DateCell: React.FC<DateCellProps> = ({
  wrapWithLink,
  noDatePlaceholder = 'Never',
  ...rest
}) => {
  const content = (
    <DateTooltip
      children={rest.value ? undefined : <span>{noDatePlaceholder}</span>}
      date={rest.value}
      options={{lengthFormat: TextLengthFormat.COMPACT}}
    />
  );
  return wrapWithLink ? <RouterLinkCell {...rest}>{content}</RouterLinkCell> : content;
};
export type DateCellParams = {
  noDatePlaceholder?: string;
  wrapWithLink?: boolean;
};
type DateCellProps = TypedCellRendererParams<AnalysisEntity, string> & DateCellParams;

export function DashboardConfigMenuCell<T extends AnalysisEntity>(
  props: DashboardConfigMenuCellProps<T>
) {
  const [isTagEditorOpen, setTagEditorOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const tagsListRef = useRef<HTMLElement | null>(null);

  const currentUser = useCurrentUser();

  const canDelete =
    props.data.type === AnalysisEntityType.EXPERIMENT
      ? hasPermission(currentUser, Types.PermissionKey.EXPERIMENT_DELETE_EXPERIMENT)
      : props.data.type === AnalysisEntityType.DASHBOARD &&
        hasPermission(currentUser, Types.PermissionKey.DASHBOARD_DELETE_DASHBOARD);

  const configActions: ReadonlyArray<ConfigOptionWithActionAndIcon<T>> = [
    hasPermission(currentUser, Types.PermissionKey.DASHBOARD_UPDATE_DASHBOARD) && {
      label: 'Edit tags...',
      onClick: () => {
        tagsListRef.current =
          props.eGridCell.parentNode!.querySelector<HTMLElement>('.TagListCell');
        setTagEditorOpen(true);
      },
      icon: 'tag' as const,
    },
    props.data.type === AnalysisEntityType.DASHBOARD && !props.data.value.archivedAt
      ? hasPermission(currentUser, Types.PermissionKey.DASHBOARD_ARCHIVE_DASHBOARD) && {
          label: 'Archive...',
          onClick: props.onArchive,
          icon: 'archive' as const,
          iconButtonDisplayVariant: ButtonDisplayVariant.DESTRUCTIVE,
        }
      : canDelete && {
          label: 'Delete...',
          onClick: props.onDelete,
          icon: 'trash' as const,
          iconButtonDisplayVariant: ButtonDisplayVariant.DESTRUCTIVE,
        },
  ].filter(isTruthy);

  if (
    props.data.type === AnalysisEntityType.TEMPLATE &&
    !isViewEditable(props.currentUser, props.data.value)
  ) {
    // ag-grid throws an error if we return null
    return <div />;
  }
  return (
    <ConfigMenuCell
      allTags={props.allTags}
      className="DashboardConfigMenuCell"
      configActions={configActions}
      currentUser={props.currentUser}
      data={props.data}
      eGridCell={props.eGridCell}
      isOpen={isOpen}
      isTagEditorOpen={isTagEditorOpen}
      setOpen={setIsOpen}
      setTagEditorOpen={setTagEditorOpen}
      tags={props.data.tags}
      showAsInlineIcons
      onEditTags={props.onEditTags}
    />
  );
}

type ConfigMenuCellProps<T> = CurrentUserProps & {
  className?: string;
  allTags: ReadonlyArray<string>;
  eGridCell: HTMLElement;
  data: T;
  hideTagIcon?: boolean;
  noTagsText?: string | ReactElement;
  onEditTags: (item: T, selectedTags: ReadonlyArray<string>) => void;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  tags: ReadonlyArray<string>;
  isTagEditorOpen: boolean;
  setTagEditorOpen: (isOpen: boolean) => void;
} & (
    | {showAsInlineIcons?: true; configActions: ReadonlyArray<ConfigOptionWithActionAndIcon<T>>}
    | {showAsInlineIcons?: false; configActions: ReadonlyArray<ConfigOptionWithAction<T>>}
  );

export type DashboardConfigMenuCellParams<T extends AnalysisEntity> = CurrentUserProps & {
  allTags: ReadonlyArray<string>;
  onEditTags: (item: T, selectedTags: ReadonlyArray<string>) => void;
  onDelete: (item: T) => void;
  onArchive: (item: T) => void;
};
type DashboardConfigMenuCellProps<T extends AnalysisEntity = AnalysisEntity> =
  DashboardConfigMenuCellParams<T> & {
    eGridCell: HTMLElement;
    data: T;
  };

export function ConfigMenuCell<T extends object>(props: ConfigMenuCellProps<T>) {
  const {
    data,
    configActions,
    isOpen,
    setOpen,
    isTagEditorOpen,
    setTagEditorOpen,
    showAsInlineIcons,
    className,
  } = props;

  const buttonRef = useRef(null);
  const buttons = useMemo(
    () =>
      showAsInlineIcons ? (
        <div className={classNames('ConfigMenuCell', 'inline-icons', className)}>
          {configActions.map((item, index) => (
            <IconButton
              key={index}
              disabled={item.disabled}
              displayVariant={item.iconButtonDisplayVariant ?? ButtonDisplayVariant.SECONDARY}
              icon={item.icon}
              onClick={() => item.onClick(data)}
            />
          ))}
        </div>
      ) : (
        <>
          <Centered
            className={classNames('ConfigMenuCell', className)}
            onClick={() => setOpen(!isOpen)}
          >
            <IconButton ref={buttonRef} className="button-wrapper" icon="ellipsis-v" />
          </Centered>
          {isOpen && (
            <Overlay
              {...defaultOverlayProps}
              placement="bottom-end"
              show={isOpen}
              target={buttonRef.current}
              onHide={() => setOpen(false)}
            >
              <ContextMenuPopover className="config-menu-popover" id="config-menu-popover">
                {configActions.map((item, index) => (
                  <DropdownItem
                    key={index}
                    closeDropdown={() => setOpen(false)}
                    data={data}
                    disabled={item.disabled}
                    label={item.label}
                    onClick={item.onClick}
                  />
                ))}
              </ContextMenuPopover>
            </Overlay>
          )}
        </>
      ),
    [className, configActions, data, isOpen, setOpen, showAsInlineIcons]
  );

  return (
    <>
      {buttons}
      {isTagEditorOpen && (
        <Overlay
          {...defaultOverlayProps}
          placement="bottom"
          show={isTagEditorOpen}
          target={props.eGridCell.parentNode!.querySelector<HTMLElement>('.TagListCell')}
          onHide={() => setTagEditorOpen(false)}
        >
          <Popover className="tags-editor-popover" id="config-menu-popover">
            <TagsEditor
              allTags={props.allTags}
              currentUser={props.currentUser}
              hideTagIcon={props.hideTagIcon}
              noTagsText={props.noTagsText}
              selectedTags={props.tags}
              onClose={() => {
                setTagEditorOpen(false);
                setOpen(false);
              }}
              onConfirm={tags => props.onEditTags(props.data, tags)}
            />
          </Popover>
        </Overlay>
      )}
    </>
  );
}

function DropdownItem<T>(props: DropdownItemProps<T>) {
  const onClick = () => {
    if (!props.disabled) {
      props.onClick(props.data);
      props.closeDropdown();
    }
  };
  return (
    <li
      className={classNames('EntityCellDropdownItem', {inactive: props.disabled})}
      onClick={onClick}
    >
      {props.label}
    </li>
  );
}
export const TagListCell: React.FC<TagListCellProps> = props => {
  const content = (
    <div className="TagListCell">
      <TagsList
        maxWidth={props.column.getActualWidth()}
        value={props.value}
        onItemClick={props.onItemClick}
      />
    </div>
  );
  return props.wrapWithLink ? <RouterLinkCell {...props}>{content}</RouterLinkCell> : content;
};
export type TagListCellParams = {
  onItemClick?: (name: string) => void;
  wrapWithLink: boolean;
};
type TagListCellProps = TypedCellRendererParams<AnalysisEntity, ReadonlyArray<string>> &
  TagListCellParams;

export type ConfigOptionWithAction<T> = {
  label: string;
  onClick: (item: T) => void;
  disabled?: boolean;
};

type ConfigOptionWithActionAndIcon<T> = ConfigOptionWithAction<T> & {
  icon: IconSpec;
  iconButtonDisplayVariant?: ButtonDisplayVariant;
};

type DropdownItemProps<T> = ConfigOptionWithAction<T> & {
  closeDropdown: () => void;
  data: T;
};

export function capitalize(str: string) {
  if (!str) {
    return str;
  }

  // replace underscores with spaces and capitalize each word
  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(^|\s|\()\w/g, word => word.toUpperCase());
}

export function capitalizeFirstLetter(str: string) {
  if (!str) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Converts all words in the string to Title Case (upper case first letter and lower case rest)
 */
export function titleCase(str: string, separator = ' ') {
  return str
    .split(separator)
    .filter(word => !!word)
    .map(word => {
      return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
    })
    .join(' ');
}

/**
 * If optionalText exists, the formatted string will be
 * "${main_text} (${optional_text})"
 */
export const textWithOptionalInfo = (mainText: string, optionalText?: string | null) => {
  if (!optionalText) {
    return mainText;
  } else {
    return `${mainText} (${optionalText})`;
  }
};

export function depluralize(str: string) {
  return (
    str && (str.charAt(str.length - 1).toLowerCase() === 's' ? str.slice(0, str.length - 1) : str)
  );
}

export function slugify(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replaceAll(/-$/g, '');
}

type Stringer<T> = (item: T) => React.ReactNode;

/**
 * Produces a `a, b, c, +X more` type of list, or `a, b, c` if max count is not exceeded.
 */
export function truncateList<T>(
  items: ReadonlyArray<T>,
  maxCount = 5,
  stringer: Stringer<T> = (item: T) => `${item}`
): string {
  const visibleItems = items.slice(0, maxCount);
  const remainingCount = items.length - visibleItems.length;

  const truncatedList = `${visibleItems.map(stringer).join(', ')} ${
    remainingCount ? `, +${remainingCount} more` : ''
  }`;

  return truncatedList;
}

import {IHeaderParams, RowNode} from 'ag-grid-community';
import {List} from 'immutable';
import moment from 'moment-timezone';

import {SortModel} from 'toolkit/ag-grid/types';
import {MetricDescriptionsByName} from 'toolkit/metrics/types';
import {DateFormatOptions} from 'toolkit/time/types';
import {ConcreteViewLinks, ViewLinkParams} from 'toolkit/views/view-link-types';
import * as Types from 'types';
import {TableHeaderColorScheme} from 'widgets/types';

export interface CommonHeaderParams {
  readonly compact?: boolean;
  readonly headerColorScheme?: TableHeaderColorScheme;
  readonly isChangingSortDisabled?: boolean;
}

export interface AttributeTreeCellParams extends ViewLinkParams {
  readonly attributeInstances: ReadonlyArray<Types.AttributeInstance>;
  readonly calendarProperties: Types.CalendarProperties;
  readonly compact: boolean;
  readonly evaluationDate: moment.Moment;
  readonly evaluationPeriod: Types.DatePeriod;
  readonly isFlattened: boolean;
  readonly onRowSelectionChanged?: (node: RowNode) => void;
  readonly showSelectionCheckbox?: boolean;
  readonly viewFilters: ReadonlyArray<Types.AttributeFilter>;
}

export type AttributeHeaderParams = Partial<IHeaderParams> &
  CommonHeaderParams & {
    readonly hasCapitalizedHeader?: boolean;
    readonly isFlattened?: boolean;
    readonly isLeafColumn?: boolean;
    readonly subtitle?: string | null;
    readonly textAlignment?: 'left' | 'right' | 'center';
    readonly infoHoverText?: string | null;
  };

export interface GroupedColumnOptions {
  readonly calendarProperties: Types.CalendarProperties;
  readonly compact: boolean;
  readonly defaultCellRendererParams: (
    metricInstanceConfig: Types.MetricInstanceConfig,
    index: number
  ) => MetricCellParams;
  readonly defaultHeaderRendererParams: (
    metricInstanceConfig: Types.MetricInstanceConfig
  ) => MetricHeaderParams;
  readonly isIconizedColumn?: (metricInstanceConfig: Types.MetricInstanceConfig) => boolean;
  readonly weekFormat: Types.WeekFormat;
  readonly viewLinks: ConcreteViewLinks | null;
  readonly sortByFirstGroupedColumn: boolean;
  readonly onColumnSort?: (level: number) => void;
  readonly headerSortOrder?: Types.SortDirection;
}

export interface MetricCellParams extends ViewLinkParams {
  readonly attributeInstances: ReadonlyArray<Types.AttributeInstance>;
  readonly compact: boolean;
  readonly metricInstanceConfig: Types.MetricInstanceConfig;
  readonly isFlattened: boolean;
  readonly viewFilters: ReadonlyArray<Types.AttributeFilter>;
  readonly isOrdersPreviewEnabled: boolean;
}

export interface TransactionMetricCellParams extends MetricCellParams {
  readonly ignoreViewFilters: boolean;
  readonly widgetAttributeFilters: ReadonlyArray<Types.AttributeFilter>;
  readonly otherFilters: ReadonlyArray<Types.AttributeFilter>;
}

export interface MetricHeaderParams extends CommonHeaderParams {
  readonly additionalPopoverContent?: string;
  readonly descriptionOverrides?: MetricDescriptionsByName;
  readonly displayName?: string | null;
  readonly isBold?: string;
  readonly isIconizedColumn?: boolean;
  readonly isSubtotal?: boolean;
  readonly isTooltipsEnabled?: boolean;
  readonly isTotal?: boolean;
  readonly isSubtitleVisible?: boolean;
  readonly hideForecastSuffixes?: boolean;
  readonly hideCurrencySuffix?: boolean;
  readonly metricInstanceConfig: Types.MetricInstanceConfig;
  readonly onlyShowTimeshift?: boolean;
  readonly hidePeriod?: boolean;
}

export interface TransposedHeaderParams {
  readonly columnPath: List<Types.ThinAttributeValue>;
  readonly compact: boolean;
  readonly isFlattened: boolean;
  readonly isLeafColumn: boolean;
}

export interface AttributeColumnOptions {
  readonly attributeColumnSortIndex: number | null;
  readonly calendarProperties: Types.CalendarProperties;
  readonly compact: boolean;
  readonly dateFormatOptions: DateFormatOptions;
  readonly defaultHeaderRendererParams?: AttributeHeaderParams;
  readonly defaultCellRendererParams: AttributeTreeCellParams;
  /* If true, reduces the minWidth of columns so the auto resizing has more room to work with */
  readonly isAutosizedWidget?: boolean;
  readonly isRowSelectionAllowed: boolean;
  readonly isSortable?: boolean;
  readonly isChangingSortDisabled?: boolean;
  readonly customHeaderValueGetter?: (attributeInstance: Types.AttributeInstance) => string;
}

export interface MetricColumnOptions {
  readonly compact: boolean;
  readonly isIconizedColumn?: (metricInstanceConfig: Types.MetricInstanceConfig) => boolean;
  readonly defaultCellRendererParams: (
    metricInstanceConfig: Types.MetricInstanceConfig,
    index: number
  ) => MetricCellParams;
  readonly defaultHeaderRendererParams: (
    metricInstanceConfig: Types.MetricInstanceConfig
  ) => MetricHeaderParams;
  readonly isSortable?: boolean;
  readonly sortByMetricColumn: boolean;
  readonly customHeaderValueGetter?: (metricInstanceConfig: Types.MetricInstanceConfig) => string;
}

export interface TableSelectionAttributeValue {
  readonly attributeInstance: Types.AttributeInstance;
  readonly attributeValue: Types.AttributeValue;
}

export type TableSortProps = {
  sortModel?: SortModel;
  onSortModelChanged?: (sortModel: SortModel) => void;
};

export enum ValueColumnSize {
  DEFAULT = 'DEFAULT',
  COMPACT = 'COMPACT',
  ICONIZED = 'ICONIZED',
}

export enum ColumnSortMode {
  ENABLED = 'ENABLED',
  /**
   * View only allows for sorting when editing the widget, but when not in the widget editor
   * the sorting behavior is only shown but changing it is disabled.
   */
  VIEW_ONLY = 'VIEW_ONLY',
  DISABLED = 'DISABLED',
}

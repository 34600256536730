import {GridApi} from 'ag-grid-community';

import {ComputeResultExtended} from 'toolkit/compute/types';
import * as Types from 'types';

export enum PlanningReportType {
  INVENTORY = 'inventory-plan',
  PURCHASE = 'purchase-plan',
  // disabled due to https://app.shortcut.com/alloytech/story/147170/some-metrics-are-not-materialized-for-some-nodes
  // https://alloytech.slack.com/archives/CAJUV2MFG/p1685646999547579?thread_ts=1685546391.820699&cid=CAJUV2MFG
  // RECEIPT = 'receipt-plan',
  PLANNED_PURCHASES = 'planned-purchases',
}

export interface PlanningUrlParams {
  readonly vendor?: string;
}

export interface PlanningReportTableBaseProps {
  attributeFilters: ReadonlyArray<Types.AttributeFilter>;
  onGridReady: (gridApi: GridApi) => void;
  filterText?: string;
}

export interface PlanningTableDetailRow {
  rowData: ComputeResultExtended;
  connectedPlanPathValues: ReadonlyArray<Types.AttributeValue>;
}

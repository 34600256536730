export enum EntityTableColumn {
  BULK_SELECTION = 'Bulk Selection',
  ID = 'id',
  NAME = 'Name',
  TAGS = 'Tags',
  TYPE = 'Type',
  STATUS = 'Status',
  OWNER = 'Owner',
  START_DATE = 'Start Date',
  LAST_UPDATED = 'Last Updated',
  LAST_UPDATED_BY = 'Last Updated By',
  LAST_VIEWED = 'Last Viewed',
  CONFIG_MENU = 'Config Menu',
  LAST_SENT = 'Last Sent',
  LAST_TRIED = 'Last Tried',
  LAST_COMPLETED_STATUS = 'Last Completed Status',
  NEXT_TRY = 'Next Try',
  WIDGET_COUNT = 'Widget Count',
}

export const ALL_SELECTED = Symbol('ALL');
export type SelectableValues = typeof ALL_SELECTED | ReadonlyArray<string>;
export type SelectableValuesByProperty<K extends string> = {readonly [key in K]: SelectableValues};

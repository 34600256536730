import moment from 'moment';

import ColorizedMetricValueCell from 'toolkit/ag-grid/cell-renderers/ColorizedMetricValueCell';
import {TableTruncatedTooltipProps} from 'toolkit/ag-grid/TableTruncatedTooltip';
import {getTruncatedAttributeCellOffsetPx} from 'toolkit/ag-grid/truncated-tooltip-utils';
import {
  ComputeResultData,
  MetricCellValue,
  TypedColDef,
  TypedRowNode,
  UntypedColDef,
} from 'toolkit/ag-grid/types';
import {getMetricValueAtLevel} from 'toolkit/ag-grid/utils';
import {NAN_STRING} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {
  getEffectiveMetric,
  getEffectiveMetricName,
  getMetricHighlight,
  isAsReportedCurrencyMetric,
} from 'toolkit/metrics/utils';
import {DateFormatOptions} from 'toolkit/time/types';
import {ConcreteViewLinks} from 'toolkit/views/view-link-types';
import * as Types from 'types';
import {isNonNullish} from 'utils/functions';
import {TableHeaderColorScheme} from 'widgets/types';

import AttributeCell from './AttributeCell';
import EventsValueCell from './EventsValueCell';
import MetricDirectionIconCell from './MetricDirectionIconCell';
import MetricHeaderCell from './MetricHeaderCell';
import MetricValueCell from './MetricValueCell';
import QuickFilterCell from './QuickFilterCell';
import TableHeaderCell from './TableHeaderCell';
import TransactionMetricCell from './TransactionMetricCell';
import TreeCell from './TreeCell';
import {
  AttributeColumnOptions,
  AttributeHeaderParams,
  MetricCellParams,
  MetricColumnOptions,
  MetricHeaderParams,
  ValueColumnSize,
} from './types';

export const defaultFrameworkComponents = {
  eventCellRenderer: EventsValueCell,
  metadataCellRenderer: MetricValueCell,
  colorizedMetricValueCellRenderer: ColorizedMetricValueCell,
  iconizedCellRenderer: MetricDirectionIconCell,
  transactionMetricCellRenderer: TransactionMetricCell,
  blankCellRenderer: () => null,
  quickFilterCellRenderer: QuickFilterCell,
  attributeCellRenderer: AttributeCell,
};

export function getMetricSortDirection(metric: Types.MetricInstance) {
  return getEffectiveMetric(metric).features.includes(Types.MetricFeature.SORT_ASCENDING_BY_DEFAULT)
    ? 'asc'
    : 'desc';
}

export function getMetricColumnDef(
  metric: Types.MetricInstanceConfig,
  index: number,
  options: MetricColumnOptions
): TypedColDef<ComputeResultData> {
  const metricInstance = metric.metricInstance;
  return {
    ...getBaseMetricColumnDef(
      metric,
      options.defaultHeaderRendererParams(metric),
      options.defaultCellRendererParams(metric, index),
      options.isIconizedColumn?.(metric) ?? false
    ),
    sort:
      options.sortByMetricColumn && index === 0
        ? getMetricSortDirection(metricInstance)
        : undefined,
    ...getSortableField(options.isSortable),
    valueGetter: params => getMetricValueAtLevel(params.data.data.columnData, index),
    comparator: getMetricCellComparator(),
    headerValueGetter:
      options.customHeaderValueGetter !== undefined
        ? () => options.customHeaderValueGetter!(metric)
        : undefined,
  };
}

export function getMetricCellComparator<T>() {
  return (
    left: MetricCellValue,
    right: MetricCellValue,
    _nodeLeft: TypedRowNode<T>,
    _nodeRight: TypedRowNode<T>,
    isInverted: boolean
  ) => getCompValueComparator(left ? left.value : null, right ? right.value : null, isInverted);
}

export function getCompValueComparator(
  left: number | null,
  right: number | null,
  isInverted: boolean
) {
  const ascending = !isInverted;
  return getCompValue(left, ascending) - getCompValue(right, ascending);
}

export function getCompValue(value: number | null, ascending: boolean): number {
  if (value === null || Number.isNaN(value)) {
    // we want nulls last, no matter the sort order
    return ascending ? Number.MAX_VALUE : -Number.MAX_VALUE;
  }
  if (!Number.isFinite(value)) {
    // -Infinity should be shown before nulls if sorting in descending order, same for +Infinity
    // when sorting in ascending order
    return value > 0 ? Number.MAX_VALUE - 1 : -(Number.MAX_VALUE - 1);
  }

  return value!;
}

export const MIN_COLUMN_WIDTH = 100;
const MIN_ICONIZED_COLUMN_WIDTH = 49;
export function getBaseValueColumnDef(size: ValueColumnSize): Partial<UntypedColDef> {
  return {
    minWidth: size === ValueColumnSize.ICONIZED ? MIN_ICONIZED_COLUMN_WIDTH : MIN_COLUMN_WIDTH,
    ...(size === ValueColumnSize.ICONIZED ? {maxWidth: MIN_ICONIZED_COLUMN_WIDTH} : {}),
    pinned: size === ValueColumnSize.COMPACT ? 'right' : undefined,
  };
}

export function shouldUseMetricValueCellRenderer(
  metricInstance: Types.MetricInstance | null,
  metadata: ReadonlyArray<Types.MetricValueMetadata> | null | undefined,
  viewLinks: ConcreteViewLinks
): metricInstance is Types.MetricInstance {
  const hasMetadata = (metadata?.length ?? 0) > 0;
  const metricName = metricInstance ? getEffectiveMetricName(metricInstance) : null;
  return (
    hasMetadata ||
    viewLinks.metricLinks.has(metricName ?? '') ||
    viewLinks.metricUrlLinks.has(metricName ?? '')
  );
}

export function shouldUseTransactionMetricCellRenderer(
  metricInstance: Types.MetricInstance | null
) {
  const metric = getEffectiveMetric(metricInstance);
  return (
    !metricInstance?.metric.features.includes(Types.MetricFeature.IS_COMPS) &&
    metric?.features.includes(Types.MetricFeature.DERIVED_FROM_TRANSACTION_EVENTS)
  );
}

export function getBaseMetricColumnDef(
  metricInstanceConfig: Types.MetricInstanceConfig,
  headerRendererParams: MetricHeaderParams,
  cellRendererParams: MetricCellParams,
  isIconizedColumn: boolean
): TypedColDef<ComputeResultData> {
  const metricInstance = metricInstanceConfig.metricInstance;
  return {
    ...getBaseValueColumnDef(
      isIconizedColumn
        ? ValueColumnSize.ICONIZED
        : cellRendererParams.compact
          ? ValueColumnSize.COMPACT
          : ValueColumnSize.DEFAULT
    ),
    cellClass: params =>
      getMetricColumnCellClasses(metricInstanceConfig, params.value as MetricCellValue),
    cellRendererParams,
    // There is a default set, but cellRendererFramework is mutually exclusive with cellRendererSelector
    cellRendererFramework: null,
    cellRendererSelector: params => {
      const metricValue = (params as any).value as MetricCellValue;
      if (
        params.valueFormatted !== NAN_STRING &&
        shouldUseTransactionMetricCellRenderer(metricInstance) &&
        cellRendererParams.isOrdersPreviewEnabled
      ) {
        return {component: 'transactionMetricCellRenderer', params: cellRendererParams};
      } else if (isIconizedColumn) {
        return {component: 'iconizedCellRenderer', params: cellRendererParams};
      }
      if (
        metricValue &&
        shouldUseMetricValueCellRenderer(
          metricInstance,
          metricValue.metadata,
          cellRendererParams.viewLinks
        )
      ) {
        return {component: 'metadataCellRenderer', params: cellRendererParams};
      }
      return {component: 'colorizedMetricValueCellRenderer', params: cellRendererParams};
    },
    comparator: getMetricCellComparator(),
    headerComponentFramework: MetricHeaderCell,
    headerComponentParams: {
      ...headerRendererParams,
    } satisfies MetricHeaderParams,
    valueFormatter: params => {
      const metricValue = params.value as MetricCellValue;
      return metricValue
        ? Format.metricValue(metricInstance, metricValue.value, metricValue.metadata, {
            includeCurrency: isAsReportedCurrencyMetric(metricInstance),
          })
        : '';
    },
    ...(isIconizedColumn ? {tooltipValueGetter: undefined} : {}),
  };
}

export function getFormattedValue(
  value: Types.AttributeValue | null,
  dateFormatOptions: DateFormatOptions
) {
  return Format.attributeValue(value, {
    calendar: dateFormatOptions.calendarProperties.name,
    weekFormat: dateFormatOptions.weekFormat,
  });
}

export function getBaseAttributeColumnDef<NVT = unknown>(
  attributeInstance: Types.AttributeInstance,
  options: AttributeColumnOptions
): TypedColDef<ComputeResultData, Types.AttributeValue, NVT> {
  const headerComponentParams: AttributeHeaderParams = {
    headerColorScheme: TableHeaderColorScheme.DEFAULT,
    isChangingSortDisabled: options.isChangingSortDisabled,
  };
  return {
    cellRendererFramework: TreeCell,
    getQuickFilterText: params => getFormattedValue(params.value, options.dateFormatOptions),
    headerComponentFramework: TableHeaderCell,
    headerName: '',
    headerComponentParams,
    headerValueGetter: options.customHeaderValueGetter
      ? () => options.customHeaderValueGetter!(attributeInstance)
      : () => Format.attributeInstance(attributeInstance),
    tooltipComponentParams: {
      getOffsetInPx: getTruncatedAttributeCellOffsetPx,
    } satisfies TableTruncatedTooltipProps<unknown>,
  };
}

export const METRIC_CELL_CLASSNAME = 'value-cell';

export function getMetricColumnCellClasses(
  metric: Types.MetricInstanceConfig | null,
  value: MetricCellValue
) {
  return [
    METRIC_CELL_CLASSNAME,
    `value-cell-${
      value
        ? getMetricHighlight(
            metric?.metricInstance || null,
            value.value,
            value.metadata,
            metric?.useReverseColors
          )
        : 'neutral'
    }`,
  ];
}

export function getEventColumnCellClasses() {
  return ['value-cell', 'event-value-cell', 'editable-cell'];
}

/**
 * Uses ordering of data from the server, eg. for days of week which also depends on the calendar
 */

function dateCompare(dateAttribute: Types.Attribute, valueA: unknown, valueB: unknown) {
  const valueType = dateAttribute.valueType;
  if (valueType === Types.AttributeValueType.interval) {
    const intervalA = valueA as Types.LocalInterval;
    const intervalB = valueB as Types.LocalInterval;
    return intervalA.start.localeCompare(intervalB.start);
  } else if (valueType === Types.AttributeValueType.date) {
    return ((valueA as string) || '').localeCompare(valueB as string);
  }
  return (valueA as any) > (valueB as any) ? 1 : (valueA as any) === (valueB as any) ? 0 : -1;
}

export function compareObjectsAlphanumerically(left: any, right: any): number {
  if (left !== null && right !== null) {
    return String(left).localeCompare(String(right), undefined, {numeric: true});
  } else if (left !== null) {
    return 1;
  } else if (right !== null) {
    return -1;
  } else {
    return 0;
  }
}

export function compareColumns(
  attr: Types.Attribute,
  valueA: Types.AttributeValue,
  valueB: Types.AttributeValue,
  dataA: unknown,
  dataB: unknown
) {
  if (
    (attr.type === 'DATE' || attr.valueType === Types.AttributeValueType.date) &&
    dataA !== null &&
    dataB !== null
  ) {
    return dateCompare(attr, dataA, dataB);
  }
  const displayValueA = valueA && valueA.displayValue;
  const displayValueB = valueB && valueB.displayValue;
  const valueAValue = valueA && valueA.value;
  const valueBValue = valueB && valueB.value;
  return compareObjectsAlphanumerically(displayValueA || valueAValue, displayValueB || valueBValue);
}

export function dateColumnComparator(
  valueA: string | moment.Moment,
  valueB: string | moment.Moment
) {
  return valueA === valueB ? 0 : moment(valueA).isAfter(moment(valueB)) ? 1 : -1;
}

export function nullableDateColumnComparator(
  valueA: string | moment.Moment | null,
  valueB: string | moment.Moment | null
) {
  if (valueA === valueB) {
    return 0;
  } else if (valueA === null) {
    return 1;
  } else if (valueB === null) {
    return -1;
  } else {
    return dateColumnComparator(valueA, valueB);
  }
}

export function getSortableField(
  isSortable: boolean | undefined
): {sortable: boolean | undefined} | object {
  return isNonNullish(isSortable) ? {sortable: isSortable} : {};
}

import {Map} from 'immutable';

import {AttributesAction} from 'redux/actions/attributes';
import ActionType from 'redux/actions/types';
import * as Types from 'types';

interface RecommendedAttributesState {
  readonly attributes: ReadonlyArray<Types.Attribute>;
  readonly values: Map<Types.FilteredValuesQuery, ReadonlyArray<Types.ThinAttributeValue>>;
}

interface AttributeMasterState {
  readonly assignableValues: Map<number, ReadonlyArray<Types.ThinAttributeValue>>;
}

export interface AttributesState {
  readonly location: AttributeMasterState;
  readonly product: AttributeMasterState;
  readonly recommended: RecommendedAttributesState;
  readonly isFetchingProductIdentifiers: boolean;
}

const initialAttributesState: AttributesState = {
  location: {
    assignableValues: Map(),
  },
  product: {
    assignableValues: Map(),
  },
  recommended: {
    attributes: [],
    values: Map(),
  },
  isFetchingProductIdentifiers: false,
};

export default function attributes(
  state = initialAttributesState,
  action: AttributesAction
): AttributesState {
  if (action.type === ActionType.SetAssignableValues) {
    const assignableValues = Map(
      action.attributes.map<[number, ReadonlyArray<Types.ThinAttributeValue>]>(
        (attribute, index) => [attribute.id!, action.assignableValues[index]]
      )
    );
    const oldState = state[action.attributeType];
    return {...state, [action.attributeType]: {...oldState, assignableValues}};
  } else if (action.type === ActionType.AddAssignableValue) {
    const attribute = action.attribute;
    const attributeType = attribute.type.toLowerCase();
    if (attributeType !== 'location' && attributeType !== 'product') {
      throw new TypeError('Attribute type must be either location or product.');
    }

    const attributesState = state[attributeType] || null;
    const values = attributesState.assignableValues;

    const newValues: ReadonlyArray<Types.ThinAttributeValue> = [
      ...values.get(attribute.id, []),
      action.newValue,
    ];
    const newState = {...attributesState, assignableValues: values.set(attribute.id!, newValues)};
    return {...state, [attributeType]: newState};
  } else if (action.type === ActionType.SetRecommendedFilterAttributes) {
    return {...state, recommended: {...state.recommended, attributes: action.attributes}};
  } else if (action.type === ActionType.SetRecommendedAttributeFilterValues) {
    const newValues = state.recommended.values.set(action.request, action.data);
    return {...state, recommended: {...state.recommended, values: newValues}};
  }
  return state;
}

// should match the special tags listed in Tags.java
export enum Tags {
  ALLOY_WORKFLOW = 'Alloy Workflow',
  ALLOY_LANDING_PAGE = 'Alloy Landing Page',
  ALLOY_ADDITIONAL_TEMPLATES = 'Alloy Additional Templates',
  ALLOY_LEGACY_TEMPLATE = 'Alloy Legacy Template',
}

/** Tags that only super admins can edit */
export const SUPER_ADMIN_ONLY_TAGS: ReadonlyArray<string> = Object.values(Tags);

/** Tag sections that only super admins can see on the dashboard management page */
export const HIDDEN_SUPER_ADMIN_TAGS: ReadonlyArray<string> = [
  Tags.ALLOY_LANDING_PAGE,
  Tags.ALLOY_ADDITIONAL_TEMPLATES,
  Tags.ALLOY_LEGACY_TEMPLATE,
];

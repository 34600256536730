import {Placement} from '@popperjs/core';
import {List, Map, Set} from 'immutable';
import mapboxgl from 'mapbox-gl';
import moment from 'moment-timezone';
import React from 'react';

import {CurrentUserProps} from 'redux/context/user';
import {Settings} from 'settings/utils';
import {UntypedColDef} from 'toolkit/ag-grid/types';
import {AgGridColResizeMethod} from 'toolkit/ag-grid/utils';
import {AttributeHierarchies, AttributesById, PartnersById} from 'toolkit/attributes/types';
import {AttachmentProps} from 'toolkit/components/Dropdown';
import {ComputeResultExtended} from 'toolkit/compute/types';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {ForecastTypesByForecastedMetricName, MetricsByName} from 'toolkit/metrics/types';
import {
  AttributeHierarchyWidgetData,
  AttributeWidgetData,
  ComputeWidgetData,
  DiagnosticTableWidgetData,
  EventWidgetData,
  PlanningWidgetData,
  ViewState,
  ViewUrlParams,
  WidgetData,
} from 'toolkit/views/types';
import {ConcreteViewLinks, emptyViewLinks} from 'toolkit/views/view-link-types';
import * as Types from 'types';
import {noop} from 'utils/functions';
import {Status} from 'utils/status';
import {WidgetExporter} from 'widgets/actions/export';
import {CommonWidgetAction} from 'widgets/actions/types';

import {InsightColorVariant, InsightTextSize} from './chrome/headers/types';
import {ColumnSortMode} from './tables/impl/types';

export interface SearchableOption<T> {
  readonly key: string;
  readonly label: string;
  readonly shorthand: string;
  readonly subtitle?: React.ReactNode;
  readonly value: T;
}

export enum SelectionBehavior {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export type WidgetDisplayMode = 'compact' | 'normal' | 'padded';

export enum WidgetInteractivityMode {
  FULL = 'FULL',
  WYSIWYG = 'WYSIWYG',
  NONE = 'NONE',
}

export interface MapProps {
  readonly fitBoundsOptions?: mapboxgl.FitBoundsOptions;
  readonly autoSelectOnlyLocation?: boolean;
}

export enum TableHeaderColorScheme {
  DEFAULT = 'DEFAULT',
  GREY = 'GREY',
}

export enum TableRowLinkMode {
  /** No special behavior for table rows. */
  NONE = 'NONE',
  /**
   * Rows act as attribute links. User is taken to the dashboard defined by the widget's view link id.
   * Only supported in flattened and grouped tables.
   */
  ATTRIBUTE_LINK = 'ATTRIBUTE_LINK',
  /**
   * Rows act as attribute links to the dashboard to which the dashboard defined by
   * the widget's view link id points to. If such a dashboard does not exist, the user is taken to
   * dashboard defined by the widget's view link id.
   *
   * Only supported in flattened and grouped tables.
   */
  ATTRIBUTE_SKIP_LINK = 'ATTRIBUTE_SKIP_LINK',
  /**
   * Rows act as attribute links, but the filters aren't place into the top bar filters, instead
   * they try to match a widget's groupings and act as a selection filter.
   */
  ATTRIBUTE_SELECTION_LINK = 'ATTRIBUTE_SELECTION_LINK',
}

export interface TableProps {
  /** Sets the sorting interactivity for columns. Defaults to ENABLED. */
  readonly columnSortMode?: ColumnSortMode;
  readonly columnDefsMapper?: (coldef: UntypedColDef) => UntypedColDef;
  /** Alters the table header color scheme. Defaults to black. */
  readonly headerColorScheme?: TableHeaderColorScheme;
  /** Alters whether a drop shadow is displayed under the header. Defaults to true. */
  readonly hasHeaderDropShadow?: boolean;
  /** Sets whether row divider lines are present. Defaults to true. */
  readonly hasRowDividerLines?: boolean;
  /** Hides (Seasonal Historical Average) etc suffixes for forecast metrics. Defaults to false. */
  readonly hideForecastSuffixes?: boolean;
  /** If true, header subtitles (e.g. "Last X Weeks" for metrics) are shown. Defaults to true. */
  readonly isHeaderSubtitleVisible?: boolean;
  /** If true, the totals row is shown. Defaults to true in most cases. */
  readonly isTotalsRowVisible?: boolean;
  /** Sets the row linking mode. Defaults to none. */
  readonly rowLinkMode?: TableRowLinkMode;
  /** If specified, show up to the specified number of rows for the table. */
  readonly maxRowCount?: number;
  /** If specified, show up to the specified number of metrics for the table */
  readonly maxMetricsShown?: number;
  readonly scrollDisabled?: boolean;
  /** If specified, columns have no minimum width and are entirely auto-sized */
  readonly autoSizeColumns?: boolean;
  /** Sets the column resizing method. Defaults to FIT_CONTENT. */
  readonly columnResizeMethod?: AgGridColResizeMethod;
}

export type ContainerWidgetType =
  | Types.WidgetType.TAB_WIDGET
  | Types.WidgetType.STACKED_WIDGET
  | Types.WidgetType.NETWORK_WIDGET
  | Types.WidgetType.PERFORMANCE_VS_PLAN;

export enum ExploreButtonType {
  ICON_BUTTON = 'ICON_BUTTON',
  ICON_ONLY = 'ICON_ONLY',
  FULL = 'FULL',
}

export type CustomHeaderBarRenderer = (controlsToEmbed: React.ReactNode) => React.ReactNode;

// Widget props that are passed from layout to container and to widget as-is.
// Most props will go here, aside from those props that depend on layout or data.
export type BaseWidgetProps = CurrentUserProps & {
  allGroupings: AttributesById;
  allCalendars: Map<Types.RetailCalendarEnum, Types.CalendarProperties>;
  availableMetrics: MetricsByName;
  calendar: Types.RetailCalendarEnum;
  className?: string;
  contextMenuActions?: ReadonlyArray<CommonWidgetAction>;
  customHeaderBarRenderer?: CustomHeaderBarRenderer;
  customInsightHeader?: (result: ComputeResultExtended) => string | React.ReactNode;
  customWidgetControlsRenderer?: () => React.ReactNode;
  defaultAttributeHierarchies: AttributeHierarchies;
  displayMode: WidgetDisplayMode;
  weekFormat: Types.WeekFormat;
  defaultEvaluationPeriod: Types.DatePeriod;
  experiment: Types.Experiment | null;
  evaluationDate: moment.Moment;
  fetchingAllGroupingsStatus: Status;
  interactivityMode: WidgetInteractivityMode;
  insightColorVariant: InsightColorVariant;
  insightTextSize: InsightTextSize;
  isAnimationEnabled?: boolean;
  isEditing?: boolean;
  isEditable?: boolean;
  isExpanded: boolean;
  isExploreButtonVisible?: boolean;
  exploreButtonType?: ExploreButtonType;
  isAddContainerButtonVisible?: boolean;
  isMenuButtonVisible?: boolean;
  isPerformanceVsPlanWidgetEnabled?: boolean;
  shouldLoadOffscreenData?: boolean;
  isStackedWidgetEnabled?: boolean;
  isWidgetHeaderOneline?: boolean;
  isWidgetHeaderBarFloating?: boolean;
  isWidgetHeaderCondensedHeight?: boolean;
  isWidgetHeaderInsightsVisible?: boolean;
  isWidgetHeaderInsightTextBold?: boolean;
  isWidgetHeaderInsightTextVisible?: boolean;
  isWidgetHeaderPeriodTitleVisible?: boolean;
  isWidgetHeaderTitleVisible?: boolean;
  isWidgetHeaderUppercase?: boolean;
  isWidgetHeaderValueVisible?: boolean;
  isWidgetHeaderVisible?: boolean;
  mapProps?: MapProps;
  tableProps?: TableProps;
  partners: PartnersById;
  removeDoubleCountingPartners?: boolean;
  selectionBehavior: SelectionBehavior;
  selectedFilters: ReadonlyArray<Types.AttributeFilter> | null;
  showSeeAllButton?: boolean;
  otherFilters: ReadonlyArray<Types.AttributeFilter>;
  useMetricBasedEmptyState?: boolean;
  viewFilters: ReadonlyArray<Types.AttributeFilter>;
  viewId?: number | null;
  viewLinks: ConcreteViewLinks;
  viewSlugs: ReadonlyArray<string>;
  viewUrlParams: List<ViewUrlParams | null>;
  visible?: boolean;
  widgetHeaderSize?: 'medium' | 'large';
  currency: Types.CurrencyCode;
};

// Props for widgets that layout other widgets. For example,
// AnalysisWidgetContainer layouts a single widget, and TabWidget layouts children in tabs.
export type LayoutWidgetProps = Omit<BaseWidgetProps, 'currentUser'> & {
  className?: string;
  innerClassName?: string;
  layoutData: WidgetLayoutData;
  onChildSelected: (widgetIndex: number) => void;
  onConvertToContainerWidget: (containerType: ContainerWidgetType, widgetIndex: number) => void;
  onMoveChild: (widgetTreeIndex: number, fromChildIndex: number, toChildIndex: number) => any;
  onDelete: (widgetIndex: number) => void;
  onEdit: (widgetIndex: number) => void;
  onExpand: (widgetIndex: number) => void;
  onSelectionFiltersChangedForWidget: (
    widgetIndex: number,
    filters: ReadonlyArray<Types.AttributeFilter>
  ) => any;
  filtersSelectedByChildren: Map<number, ReadonlyArray<Types.AttributeFilter>>;
  onSetConfig: (widgetIndex: number, config: Types.Widget) => void;
  onSetConfigWithoutUpdates: (widgetIndex: number, partialConfig: Partial<Types.Widget>) => void;
  onSetSelectedCalendarDate: (date: moment.Moment, widgetIndex: number) => void;
  onSubscribeToEmails: (widgetIndex: number) => void;
  onViewWidgetFiles: (widgetIndex: number) => void;
  onOpenProductMappings: (widgetIndex: number) => void;
  exporter?: WidgetExporter;
  onUpdateEvent: () => void;
  onWidgetTreeChanged: (treeIndex: number, layoutData: WidgetLayoutData) => void;
  reloadWidget: (widgetIndex: number) => void;
  style?: React.CSSProperties;
  treeIndex: number;
  viewUpdateMode: Types.ViewUpdateMode;
  widgetListIndex: number;
  widgetIndexOffset: number;
};

// Common props between all dashboard widgets.
export type BaseAnalysisWidgetProps = BaseWidgetProps & {
  config: Types.Widget;
  customExploreContent?: React.ReactNode;
  exporter?: WidgetExporter;
  onConvertToContainerWidget: (containerType: ContainerWidgetType) => void;
  onDelete: () => void;
  onEdit: () => void;
  onExpand: () => void;
  onReload: () => void;
  handleDownloadWithTabs?: (exportFormat: Types.ExportFormat) => void;
  onExploreButtonClick?: () => void;
  onOpenProductMappings: () => void;
  onSetConfig: (config: Types.Widget) => void;
  onSetConfigWithoutUpdates: (partialConfig: Partial<Types.Widget>) => void;
  onSetSelectedCalendarDate: (date: moment.Moment) => void;
  onUpdateEvent: () => void;
  onSelectionFiltersChanged: (filters: ReadonlyArray<Types.AttributeFilter>) => void;
  onSubscribeToEmails: () => void;
  onViewWidgetFiles: () => void;
};

// Props for all dashboard widgets that get their data from the compute engine.
export type AnalysisWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: ComputeWidgetData;
};

export type EventWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: EventWidgetData;
};

export type AttributeWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: AttributeWidgetData;
};

export type AttributeHierarchyWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: AttributeHierarchyWidgetData;
};

export type DiagnosticTableWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: DiagnosticTableWidgetData;
};

export type PlanningWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: PlanningWidgetData;
};

export type PlanningHierarchyWidgetProps = BaseAnalysisWidgetProps & {
  widgetData: AttributeHierarchyWidgetData;
  planId: number;
  planType: Types.PlanType;
  pendingPlanVersion: Types.PlanVersion;
  planViewingGranularity?: Types.CalendarUnit | null;
};

export interface WidgetLayoutData {
  readonly children?: List<WidgetLayoutData>;
  readonly data: WidgetData<unknown>;
  readonly widget: Types.Widget;
}

// Props used by WidgetLayout that wraps around concrete dashboard layouts.
export type BaseLayoutProps = CurrentUserProps & {
  activeView: ViewState;
  addWidget: (widgetConfig: Types.Widget) => any;
  allGroupings: AttributesById;
  allCalendars: Map<Types.RetailCalendarEnum, Types.CalendarProperties>;
  availableMetrics: MetricsByName;
  defaultAttributeHierarchies: AttributeHierarchies;
  environment: Types.Environment;
  selectedExperiment?: Types.Experiment;
  viewLinks: ConcreteViewLinks;
  viewSlugs: ReadonlyArray<string>;
  viewUrlParams: List<ViewUrlParams | null>;
};

// Props that layout sub-types use.
export type LayoutProps = BaseLayoutProps & {
  className?: string;
  contextMenuActions: ReadonlyArray<CommonWidgetAction>;
  expandedWidgetIndex: number | null;
  isEditingExpandedWidget?: boolean;
  isMenuButtonVisible?: boolean;
  isEditable: boolean;
  layoutData: List<WidgetLayoutData>;
  onChildSelected: (widgetIndex: number) => void;
  onConvertToContainerWidget: (containerType: ContainerWidgetType, widgetIndex: number) => void;
  onCopy: (fromWidgetIndex: number, toWidgetIndex: number) => void;
  onDelete: (widgetIndex: number) => void;
  onEdit: (widgetIndex: number) => void;
  onMove: (fromWidgetIndex: number, toWidgetIndex: number) => void;
  onMoveChild: (widgetTreeIndex: number, fromChildIndex: number, toChildIndex: number) => void;
  onExpand: (widgetIndex: number) => void;
  onOpenProductMappings: LayoutWidgetProps['onOpenProductMappings'];
  onSelectionFiltersChangedForWidget: (
    widgetIndex: number,
    filters: ReadonlyArray<Types.AttributeFilter>
  ) => void;
  onSetConfig: (widgetIndex: number, config: Types.Widget) => void;
  onSetConfigWithoutUpdates: (widgetIndex: number, partialConfig: Partial<Types.Widget>) => void;
  onSetSelectedCalendarDate: (period: moment.Moment, widgetIndex: number) => void;
  onSubscribeToEmails: (widgetIndex: number) => void;
  onViewWidgetFiles: (widgetIndex: number) => void;
  onUpdateEvent: () => void;
  onWidgetTreeChanged: (treeIndex: number, layoutData: WidgetLayoutData) => void;
  reloadWidget: (widgetIndex: number) => void;
  widgetIndexOffset: number;
};

// Default props for a widget that's used outside the analysis dashboard context.
export const readOnlyWidgetDefaultProps = {
  insightColorVariant: InsightColorVariant.DEFAULT,
  insightTextSize: InsightTextSize.XL,
  isAnimationEnabled: false,
  isEditable: false,
  isEditing: false,
  isExpanded: false,
  isMenuButtonVisible: false,
  isWidgetHeaderVisible: false,
  isWidgetHeaderUppercase: true,
  onConvertToContainerWidget: noop,
  onDelete: noop,
  onEdit: noop,
  onExpand: noop,
  onOpenProductMappings: noop,
  onReload: noop,
  onSelectionFiltersChanged: noop,
  onSetConfig: noop,
  onSetConfigWithoutUpdates: noop,
  onSetSelectedCalendarDate: noop,
  onSubscribeToEmails: noop,
  onViewWidgetFiles: noop,
  onUpdateEvent: noop,
  selectionBehavior: SelectionBehavior.NONE,
  selectedFilters: Array<Types.AttributeFilter>(),
  otherFilters: Array<Types.AttributeFilter>(),
  viewFilters: Array<Types.AttributeFilter>(),
  viewLinks: emptyViewLinks,
  viewSlugs: [] as ReadonlyArray<string>,
  viewUrlParams: List.of<ViewUrlParams>(),
} satisfies Partial<BaseAnalysisWidgetProps>;

export type WidgetEditorProps = CurrentUserProps & {
  allGroupings: AttributesById;
  allowCustomMetricColors?: boolean;
  allowCustomMetricNames?: boolean;
  allowIconizedMetrics?: boolean;
  availableForecasts: ForecastTypesByForecastedMetricName;
  settings: Settings;
  availableMetrics: MetricsByName;
  calendar: Types.RetailCalendarEnum;
  childWidgets?: ReadonlyArray<Types.Widget> | null;
  className?: string;
  config: Types.Widget;
  defaultAttributeHierarchies: AttributeHierarchies;
  defaultCurrency: Types.CurrencyCode;
  defaultEvaluationPeriod: Types.DatePeriod;
  defaultUnitConversionAttribute?: Types.Attribute | null;
  evaluationDate: moment.Moment;
  featureFlags?: Set<FeatureFlag>;
  hiddenMetricArguments: Set<Types.MetricArgument>;
  isLaunchpadWidget?: boolean;
  metricNamesByCategory: Map<Types.MetricCategory, List<string>>;
  onChange: (config: Types.Widget) => void;
  onChildWidgetsChange?: (
    widgetConfig: Types.Widget,
    childWidgets: ReadonlyArray<Types.Widget>
  ) => void;
  popoverPlacement?: Placement;
  overlayPlacement?: Placement;
  dropdownAttachmentProps?: AttachmentProps;
};

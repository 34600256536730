import './Carousel.scss';

import React, {useEffect, useState} from 'react';
import Measure from 'react-measure';

import IconButton from 'toolkit/components/IconButton';
import {convertRemToPixels} from 'toolkit/utils/style';

const CarouselWrapper: React.FC<Omit<Props, 'widthPx'>> = props => (
  <Measure bounds>
    {({contentRect, measureRef}) => {
      return <Carousel ref={measureRef} {...props} widthPx={contentRect.bounds?.width ?? 0} />;
    }}
  </Measure>
);

const Carousel = React.forwardRef(
  ({items, itemWidthRem = 10, widthPx}: Props, forwardedRef: React.Ref<HTMLDivElement>) => {
    const [selectedPage, setSelectedPage] = useState(0);

    const itemWidthPx = convertRemToPixels(itemWidthRem);
    // if the window is too small, show at least one item
    const maxItemsPerPage = Math.max(Math.floor(widthPx / itemWidthPx), 1);
    const totalPages = Math.ceil(items.length / maxItemsPerPage);

    useEffect(() => {
      setSelectedPage(0);
    }, [widthPx]);

    return (
      <div ref={forwardedRef} className="Carousel">
        <div className="controls">
          <IconButton
            className="arrow-button"
            disabled={selectedPage === 0}
            icon="chevron-left"
            onClick={() => setSelectedPage(prevSelectedPage => prevSelectedPage - 1)}
          />
          <IconButton
            className="arrow-button"
            disabled={selectedPage >= totalPages - 1}
            icon="chevron-right"
            onClick={() => setSelectedPage(prevSelectedPage => prevSelectedPage + 1)}
          />
        </div>
        <div className="items-container">
          {items
            .slice(selectedPage * maxItemsPerPage, (selectedPage + 1) * maxItemsPerPage)
            .map((item, index) => (
              <div
                key={`carousel-item-${index}`}
                className="item"
                style={{width: Math.min(itemWidthPx, widthPx)}}
              >
                {item}
              </div>
            ))}
        </div>
      </div>
    );
  }
);
Carousel.displayName = 'Carousel';

type Props = {
  items: ReadonlyArray<React.ReactNode>;
  itemWidthRem: number;
  widthPx: number;
};

export default CarouselWrapper;

import './OrdersPreviewModal.scss';

import React, {useMemo} from 'react';

import {useCurrentUser} from 'redux/context/user';
import {withModalBoundary} from 'toolkit/components/Boundary';
import ErrorPlaceholder from 'toolkit/components/ErrorPlaceholder';
import ModalDialog from 'toolkit/components/ModalDialog';
import {isAtLeast} from 'toolkit/users/utils';
import * as Types from 'types';
import {isNullish} from 'utils/functions';
import {useAllAttributesById} from 'utils/useAllAttributes';

import {ORDERS_DEFAULT_LOCATION_ATTRIBUTE_NAME} from './constants';
import OrdersPreviewModalContent from './OrdersPreviewModalContent';
import OrdersPreviewTopBar from './OrdersPreviewTopBar';
import {getLocationColumnAttribute} from './utils';

function getMissingLocationAttributeErrorMessage(
  ordersDefaultLocationAttributeId: number | null,
  isAtLeastAdmin: boolean
) {
  const missingAttributeName = isNullish(ordersDefaultLocationAttributeId)
    ? ORDERS_DEFAULT_LOCATION_ATTRIBUTE_NAME
    : 'Location Identifier Default';
  return `The Orders Preview cannot be opened because the '${missingAttributeName}' attribute was not found.
        ${isAtLeastAdmin ? 'Please set a location identifier default in analysis settings.' : ''}`;
}

const OrdersPreviewModal: React.FC<OrdersPreviewModalProps> = ({
  attributeFilters,
  calendarProperties,
  evaluationDate,
  metricInstance,
  onClose,
}) => {
  const allGroupings = useAllAttributesById();
  const currentUser = useCurrentUser();

  const modifiedAttributeFilters: Types.AttributeFilter[] = useMemo(() => {
    const childMetrics = metricInstance.arguments.childMetrics ?? [];
    const isInbound =
      metricInstance.metric.name === 'inbound' ||
      childMetrics.some(childMetric => childMetric.name === 'inbound');
    const isOutbound =
      metricInstance.metric.name === 'outbound' ||
      childMetrics.some(childMetric => childMetric.name === 'outbound');
    return attributeFilters.map(filter => {
      if (
        filter.attributeInstance.attribute.type !== Types.AttributeType.LOCATION ||
        filter.attributeInstance.graphContext !== null
      ) {
        return filter;
      }
      const attributeInstance = {
        ...filter.attributeInstance,
      };
      if (isInbound) {
        attributeInstance.graphContext = Types.GraphContext.DESTINATION;
      } else if (isOutbound) {
        attributeInstance.graphContext = Types.GraphContext.ORIGIN;
      }
      return {
        ...filter,
        attributeInstance,
      };
    });
  }, [attributeFilters, metricInstance]);

  const locationIdentifier = useMemo(
    () =>
      getLocationColumnAttribute(
        currentUser.settings.analysisSettings.ordersDefaultLocationAttributeId,
        allGroupings
      ),
    [allGroupings, currentUser.settings.analysisSettings.ordersDefaultLocationAttributeId]
  );

  return (
    <ModalDialog
      cancelActionName="Close"
      dialogClassName="OrdersPreviewModal"
      isConfirmVisible={false}
      size="xl"
      title="Orders Preview"
      topBar={
        <OrdersPreviewTopBar
          attributeFilters={modifiedAttributeFilters}
          metricInstance={metricInstance}
        />
      }
      isOpen
      onClose={onClose}
    >
      {isNullish(locationIdentifier) ? (
        <ErrorPlaceholder
          error={getMissingLocationAttributeErrorMessage(
            currentUser.settings.analysisSettings.ordersDefaultLocationAttributeId,
            isAtLeast(currentUser.user.role, Types.Role.ADMIN)
          )}
        />
      ) : (
        <OrdersPreviewModalContent
          attributeFilters={modifiedAttributeFilters}
          calendarProperties={calendarProperties}
          evaluationDate={evaluationDate}
          metricInstance={metricInstance}
        />
      )}
    </ModalDialog>
  );
};

interface OrdersPreviewModalProps {
  attributeFilters: ReadonlyArray<Types.AttributeFilter>;
  calendarProperties: Types.CalendarProperties;
  evaluationDate: moment.Moment;
  metricInstance: Types.MetricInstance;
  onClose: () => void;
}

OrdersPreviewModal.displayName = 'OrdersPreviewModal';

export default withModalBoundary(OrdersPreviewModal);

import './EntityTable.scss';
import {
  FilterChangedEvent,
  GridApi,
  RowDataChangedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import {default as React, useCallback, useMemo} from 'react';

import {NavLinkCellRenderer} from 'toolkit/ag-grid/cell-renderers/ExternalLinkCellRenderer';
import Table from 'toolkit/ag-grid/Table';
import {SortModel, TypedColDef} from 'toolkit/ag-grid/types';
import {defaultTextColumnFilterParams} from 'toolkit/ag-grid/utils';
import {ANALYSIS_ENTITY_FUSE_OPTIONS} from 'toolkit/utils/input';
import {isNonNullish} from 'utils/functions';

const frameworkComponents = {navLinkCellRenderer: NavLinkCellRenderer as any};

const EntityTable: <T>(props: Props<T>) => React.ReactElement<Props<T>> = ({
  className,
  columnDefs,
  filterText,
  onGridReady,
  onSelect,
  onSortModelChanged,
  onTopmostItemChanged,
  onRowSelectionChanged,
  rowSelection = 'single',
  entities,
  sortModel,
  searchKeys = ['id', 'name'],
  headerHeight,
}) => {
  const updateTopmostView = useCallback(
    (gridApi: GridApi) => {
      const rows = (gridApi as any).rowModel.nodeManager.rootNode.childrenAfterSort;
      onTopmostItemChanged(rows.length ? rows[0].data : null);
    },
    [onTopmostItemChanged]
  );
  const handleFilterModelChange = useCallback(
    (event: FilterChangedEvent) => updateTopmostView(event.api),
    [updateTopmostView]
  );
  const handleSortModelChange = useCallback(
    (event: SortChangedEvent) => updateTopmostView(event.api),
    [updateTopmostView]
  );
  const handleRowDataChange = useCallback(
    (event: RowDataChangedEvent) => updateTopmostView(event.api),
    [updateTopmostView]
  );

  const rowData = useMemo(() => {
    const fuse = new Fuse(entities, {
      ...ANALYSIS_ENTITY_FUSE_OPTIONS,
      keys: searchKeys,
    });

    return filterText ? fuse.search(filterText.trim()).map(result => result.item) : entities;
  }, [entities, filterText, searchKeys]);

  return (
    <Table
      className={classNames('EntityTable', className)}
      columnDefs={columnDefs}
      defaultColDef={{
        filter: 'agTextColumnFilter',
        filterParams: defaultTextColumnFilterParams,
      }}
      frameworkComponents={frameworkComponents}
      rowData={rowData}
      rowSelection={rowSelection}
      sortModel={sortModel}
      hoverable
      isFlattened
      onFilterChanged={handleFilterModelChange}
      onGridReady={onGridReady}
      onRowClicked={e => !!onSelect && onSelect(e.data)}
      onRowDataChanged={handleRowDataChange}
      onSelectionChanged={onRowSelectionChanged}
      onSortChanged={handleSortModelChange}
      onSortModelChanged={onSortModelChanged}
      // passing in undefined overrides the default headerHeight set in Table.tsx
      {...(isNonNullish(headerHeight) ? {headerHeight} : {})}
    />
  );
};

interface Props<T> {
  className?: string;
  columnDefs: TypedColDef<T>[];
  filterText?: string;
  onGridReady?: (gridApi: GridApi) => void;
  onSelect?: (item: T) => void;
  onSortModelChanged?: (sortModel: SortModel) => void;
  onTopmostItemChanged: (item: T | null) => void;
  onRowSelectionChanged?: (event: SelectionChangedEvent) => void;
  entities: ReadonlyArray<T>;
  rowSelection?: 'single' | 'multiple';
  sortModel?: SortModel;
  searchKeys?: Array<string>;
  headerHeight?: number;
}

export default EntityTable;

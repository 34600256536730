import {Map} from 'immutable';

import {CurrentUser, CurrentUserState} from 'redux/reducers/user';
import {Option} from 'toolkit/components/LabeledOptionsSelect';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {isSubscriptionOnlyUser} from 'toolkit/subscriptions/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

import {UserInfosByEmail, UsersById} from './types';

const ROLES = {
  USER: 0,
  ADMIN: 1,
  SUPER_ADMIN: 2,
  ROOT: 3,
};

export const SUPPORT_EMAIL = 'support@alloy.ai';

export const PUBLIC_VENDOR_ID = -1;

export const roleToDisplayName = Map({
  USER: 'Limited',
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
});

export const functionalAreaOptions: Option<string>[] = [
  'Sales',
  'Marketing',
  'Operations',
  'Finance',
  'Demand Planning',
  'Product Development',
  'Executive',
  'IT',
  'Other',
].map(area => ({value: area, label: area}));

interface UserTitleFormatOptions {
  readonly defaultTitle?: string;
  readonly includeEmail?: boolean;
  readonly shorten?: boolean;
}

export const isAtLeast = (userRole: Types.Role, role: Types.Role) => ROLES[userRole] >= ROLES[role];

export const isAtMost = (userRole: Types.Role, role: Types.Role) => ROLES[userRole] <= ROLES[role];

// Returns whether the user has the given permission.
// We return true if `requiredPermission` is undefined for convenience when callers require an
// optional permission.
export function hasPermission(
  user: CurrentUserState,
  requiredPermission: Types.PermissionKey | undefined
): boolean {
  if (!user.featureFlags.includes(FeatureFlag.RBAC_SYSTEM)) {
    return true;
  }
  if (requiredPermission === undefined) {
    return true;
  }
  // eslint-disable-next-line no-restricted-syntax
  return user.permissionKeys.includes(requiredPermission);
}

export function hasAnyPermission(
  user: CurrentUserState,
  ...requiredPermissions: ReadonlyArray<Types.PermissionKey>
): boolean {
  return requiredPermissions.some(requiredPermission => hasPermission(user, requiredPermission));
}

// only checks the basic constraints if a view is editable. Does NOT include a permissions check!
export function isViewEditable(currentUser: CurrentUser, view: Types.ThinView) {
  return (
    isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) ||
    view.ownerId === currentUser.user.id ||
    view.shareLevel === Types.ShareLevel.SHARED_READ_WRITE
  );
}

export function isTestingUser(user: Types.User) {
  return user.email.includes('@alloymetrics.com');
}

export function canSeeView(currentUser: CurrentUser, view: Types.ThinView) {
  return (
    (isViewEditable(currentUser, view) || view.shareLevel !== Types.ShareLevel.PRIVATE) &&
    hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD)
  );
}

export function isManualViewUpdateMode(user: CurrentUser, view: Types.View) {
  return (
    view.updateMode === 'MANUAL' ||
    user.isManualViewUpdateMode ||
    user.featureFlags.includes(FeatureFlag.FAST_DASHBOARDS)
  );
}

export const defaultFormatOptions: UserTitleFormatOptions = {
  defaultTitle: 'Unknown',
  includeEmail: false,
};

export function getUserTitleById(
  userId: number | null,
  allUsersById: UsersById,
  allUserInfos: UserInfosByEmail,
  formatOptions: UserTitleFormatOptions = defaultFormatOptions
) {
  const user = userId !== null ? allUsersById.get(userId) : null;
  const userInfo = user && allUserInfos.get(user.email);
  return getUserTitle(user, userInfo, formatOptions);
}

export function getUserTitle(
  user: Types.User | null | undefined,
  userInfo: Types.UserInfo | null | undefined,
  formatOptions: UserTitleFormatOptions = defaultFormatOptions
): string {
  if (userInfo?.firstName && userInfo?.lastName) {
    if (formatOptions.shorten) {
      return userInfo.firstName;
    }

    const suffix = formatOptions.includeEmail && user ? ` (${user.email})` : '';
    return `${userInfo.firstName} ${userInfo.lastName}${suffix}`;
  }
  if (user?.email) {
    return user.email;
  }
  return formatOptions.defaultTitle ?? assertTruthy(defaultFormatOptions.defaultTitle);
}

function getFirstChar(text?: string | null) {
  return text ? text[0] : '';
}

interface Initials {
  readonly first: string;
  readonly last: string;
}

function getInitialsFromUserInfo(userInfo?: Types.UserInfo | null): Initials | null {
  const first = getFirstChar(userInfo && userInfo.firstName);
  const last = getFirstChar(userInfo && userInfo.lastName);
  return first && last ? {first, last} : null;
}

function getInitialsFromEmail(email: string | null | undefined) {
  if (!email) {
    return null;
  }

  const emailLocalPart = email.split('@')[0];
  // if the address is something like firstname.lastname, split by the dot
  const dotIndex = emailLocalPart.indexOf('.');
  const last = emailLocalPart[dotIndex !== -1 ? dotIndex : 1];

  return {
    first: emailLocalPart[0],
    last,
  };
}

export function getUserInitials(
  user: Types.User | null | undefined,
  userInfo: Types.UserInfo | null | undefined
) {
  const initials: Initials = getInitialsFromUserInfo(userInfo) ||
    getInitialsFromEmail(user && user.email) || {
      first: '?',
      last: '?',
    };
  return `${initials.first}${initials.last}`.toUpperCase();
}

export function isMultiVendor(user: CurrentUser) {
  return user.vendor && user.vendor.id === PUBLIC_VENDOR_ID;
}

export function getUserLabel(
  userId: Types.UserId,
  usersById: Map<number, Types.User>,
  userInfosByEmail?: Map<string, Types.UserInfo>,
  subscriptionOnlyUsers?: Map<number, Types.SubscriptionOnlyUser>
) {
  if (isSubscriptionOnlyUser(userId)) {
    const user = subscriptionOnlyUsers?.get(userId.id);
    if (!user) {
      return '';
    }
    const name = getSubscriptionOnlyUserNameToDisplay(user);
    return (name ? `${name} (${user.email})` : user.email) + ' - No Alloy.ai Access';
  } else {
    const user = usersById?.get(userId.id);
    if (!user) {
      return '';
    }
    const userInfo = userInfosByEmail?.get(user.email);
    return (
      getUserTitle(user, userInfo, {...defaultFormatOptions, includeEmail: true}) ??
      assertTruthy(defaultFormatOptions.defaultTitle)
    );
  }
}

export function isAlloyUser(userEmail: string | undefined | null): boolean {
  return userEmail?.endsWith('@alloy.ai') || userEmail?.endsWith('@alloymetrics.com') || false;
}

export function getSubscriptionOnlyUserNameToDisplay(
  subscriptionOnlyUser: Types.SubscriptionOnlyUser
) {
  const firstName = subscriptionOnlyUser.firstName || '';
  const lastName = subscriptionOnlyUser.lastName || '';
  if (firstName === '' && lastName === '') {
    return null;
  }
  return `${firstName} ${lastName}`;
}

export function createDefaultUser(vendorId: number): Types.User {
  return {
    id: null,
    active: true,
    email: '',
    role: Types.Role.USER,
    vendorId,
    type: Types.CredentialType.USER,
  };
}

export function createDefaultUserInfo(): Types.UserInfo {
  return {
    firstName: '',
    functionalArea: '',
    lastName: '',
    phoneNumber: '',
    createdAt: null,
    lastUsedAt: null,
  };
}

import {Set} from 'immutable';

import {PresentationFile} from 'app/presentation/types';
import {isFail, isLoading} from 'redux/actions/helpers';
import {SetPlanSettingsAction} from 'redux/actions/plans';
import ActionType from 'redux/actions/types';
import {CurrentUserAction} from 'redux/actions/user';
import {Settings} from 'settings/utils';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import * as Types from 'types';

export interface CurrentUserState {
  readonly featureFlags: Set<FeatureFlag>;
  readonly isDevelopmentMode: boolean;
  readonly isPermissionsOverrideDialogOpen: boolean;
  readonly isManualViewUpdateMode: boolean;
  readonly isPresentationMode: boolean;
  readonly presentationFile: PresentationFile | null;
  readonly user: Types.User | null;
  readonly userInfo: Types.UserInfo | null;
  readonly vendor: Types.Vendor | null;
  readonly settings: Partial<Settings>;
  readonly permissionKeys: ReadonlyArray<string>;
}

// Used in the app beyond AppContainer; as long as the user is logged in, the following types are
// guaranteed to be non-null.
export type CurrentUser = CurrentUserState & {
  readonly user: Types.User & {
    readonly id: number;
  };
  readonly userInfo: Types.UserInfo;
  readonly vendor: Types.Vendor & {
    readonly id: number;
  };
  readonly settings: Settings;
};

export const defaultUser: CurrentUserState = {
  featureFlags: Set(),
  isDevelopmentMode: false,
  isPermissionsOverrideDialogOpen: false,
  isPresentationMode: false,
  isManualViewUpdateMode: false,
  presentationFile: null,
  user: null,
  userInfo: null,
  vendor: null,
  settings: {
    analysisSettings: undefined,
    planSettings: undefined,
  },
  permissionKeys: [],
};

export default function user(
  state = defaultUser,
  action: CurrentUserAction | SetPlanSettingsAction
): CurrentUserState {
  if (action.type === ActionType.ResetReduxStore) {
    return defaultUser;
  } else if (action.type === ActionType.SetUser) {
    return {...state, user: action.user};
  } else if (action.type === ActionType.SetDevelopmentMode) {
    return {...state, isDevelopmentMode: action.isDevelopmentMode};
  } else if (action.type === ActionType.SetPresentationMode) {
    return {...state, isPresentationMode: true};
  } else if (action.type === ActionType.SetPresentationFile) {
    return {...state, presentationFile: action.file};
  } else if (action.type === ActionType.SetPermissionsOverrideDialogOpen) {
    return {...state, isPermissionsOverrideDialogOpen: action.isPermissionsOverrideDialogOpen};
  } else if (action.type === ActionType.SetUserInfo) {
    return {...state, userInfo: action.userInfo};
  } else if (action.type === ActionType.SetManualViewUpdateMode) {
    return {...state, isManualViewUpdateMode: action.isManualViewUpdateMode};
  } else if (action.type === ActionType.SetUserFeatureFlags) {
    return {...state, featureFlags: action.featureFlags};
  } else if (action.type === ActionType.SetAllowedVendors) {
    const userVendorId = state.user ? state.user.vendorId : null;
    const vendor =
      userVendorId === null
        ? null
        : (action.vendors.find(vendor => vendor.id === userVendorId) ?? null);
    return {...state, vendor};
  } else if (action.type === ActionType.SetCurrentVendor) {
    return {...state, vendor: action.vendor};
  } else if (action.type === ActionType.SetVendorAnalysisSettings) {
    return {...state, settings: {...state.settings, analysisSettings: action.analysisSettings}};
  } else if (action.type === ActionType.SetPlanSettings) {
    return {...state, settings: {...state.settings, planSettings: action.data} as Settings};
  } else if (action.type === ActionType.SetSavedFeatureFlags) {
    if (!isLoading(action) && !isFail(action)) {
      return {
        ...state,
        featureFlags: action.allFeaturesByUser.get(state.user!.id, Set()),
      };
    }
  } else if (action.type === ActionType.SetUserRole) {
    return {...state, user: {...state.user!, role: action.role}};
  } else if (action.type === ActionType.SetPermissions) {
    return {...state, permissionKeys: action.permissionKeys};
  }
  return state;
}

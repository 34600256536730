import './Watermark.scss';

import React from 'react';

import {getEndToEndTestBuildUrlFromCookie} from 'utils/cookies';
import {isTruthy} from 'utils/functions';

import * as globals from './globals';

const Watermark: React.FC = () => {
  const e2eBuildUrl = getEndToEndTestBuildUrlFromCookie();
  const isDev = globals.dev || !!e2eBuildUrl;
  if (!isDev) {
    return null;
  }
  const tokens: ReadonlyArray<string> = [
    globals.version === 'unknown' ? null : `Version: ${globals.version}`,
    globals.usingDevApi ? null : `API: ${globals.apiUrl}`,
  ].filter(isTruthy);

  return <div className="Watermark">{tokens.join(', ')}</div>;
};
Watermark.displayName = 'Watermark';

export default Watermark;

import {List, Map} from 'immutable';
import moment from 'moment-timezone';

import {MetricsByName} from 'toolkit/metrics/types';
import * as Types from 'types';
import {isNonNullish, isTruthy} from 'utils/functions';

import {ThinViewsById, ViewUrlParams} from './types';

export interface ViewLinkParams {
  readonly calendarProperties: Types.CalendarProperties;
  readonly evaluationDate: moment.Moment;
  readonly evaluationPeriod: Types.DatePeriod;
  readonly vendorName: string;
  readonly viewLinks: ConcreteViewLinks;
  readonly viewSlugs: ReadonlyArray<string>;
  readonly viewUrlParams: List<ViewUrlParams | null>;
  readonly widgetId: number | null;
}

export interface ViewLinks<T = number> {
  readonly attributeLinksByViewId: Map<number, Map<Types.AttributeType, T>>;
  readonly metricLinksByViewId: Map<number, Map<string, T>>;
  readonly attributeUrlLinksByViewId: Map<number, Map<Types.AttributeType, string>>;
  readonly metricUrlLinksByViewId: Map<number, Map<string, string>>;
}

export interface ConcreteViewLinks {
  readonly attributeLinks: Map<Types.AttributeType, Types.ThinView>;
  readonly metricLinks: Map<string, Types.ThinView>;
  readonly attributeUrlLinks: Map<Types.AttributeType, string>;
  readonly metricUrlLinks: Map<string, string>;
  readonly planLinksByWidgetId: Map<number, Types.PlanVersion>;
  readonly allViewLinks: ViewLinks<Types.ThinView>;
}

export const emptyViewLinks: ConcreteViewLinks = {
  attributeLinks: Map(),
  metricLinks: Map(),
  attributeUrlLinks: Map(),
  metricUrlLinks: Map(),
  planLinksByWidgetId: Map(),
  allViewLinks: {
    attributeLinksByViewId: Map(),
    metricLinksByViewId: Map(),
    attributeUrlLinksByViewId: Map(),
    metricUrlLinksByViewId: Map(),
  },
};

export type LinkType = Types.ViewLink['type'];
export const defaultLinkTypeOptions: ReadonlyArray<LinkType> = [
  'ATTRIBUTE',
  'METRIC',
  'ATTRIBUTE_URL',
  'METRIC_URL',
];

export function parseViewLinks(allViewLinks: ReadonlyArray<Types.ViewLink>): ViewLinks {
  const attributeLinksByViewId = Map<number, Map<Types.AttributeType, number>>(
    List(getAttributeLinks(allViewLinks))
      .groupBy(link => link.sourceViewId)
      .toMap()
      .map(links => toAttributeLinksMap(links.valueSeq().toArray()))
  );
  const attributeUrlLinksByViewId = Map<number, Map<Types.AttributeType, string>>(
    List(getAttributeUrlLinks(allViewLinks))
      .groupBy(link => link.sourceViewId)
      .toMap()
      .map(links => toAttributeUrlLinksMap(links.valueSeq().toArray()))
  );

  const metricLinksByViewId = Map<number, Map<string, number>>(
    List(getMetricLinks(allViewLinks))
      .groupBy(link => link.sourceViewId)
      .toMap()
      .map(links => toMetricLinksMap(links.valueSeq().toArray()))
  );
  const metricUrlLinksByViewId = Map<number, Map<string, string>>(
    List(getMetricUrlLinks(allViewLinks))
      .groupBy(link => link.sourceViewId)
      .toMap()
      .map(links => toMetricUrlLinksMap(links.valueSeq().toArray()))
  );

  return {
    attributeLinksByViewId,
    metricLinksByViewId,
    attributeUrlLinksByViewId,
    metricUrlLinksByViewId,
  };
}

export function mapViewLinksToViews(
  viewLinks: ViewLinks<number>,
  allViews: ThinViewsById
): ViewLinks<Types.ThinView> {
  return {
    attributeLinksByViewId: viewLinks.attributeLinksByViewId.mapEntries(
      ([viewId, viewIdsByType]) => [
        viewId,
        viewIdsByType
          .mapEntries(([attributeType, viewId]) => [attributeType, allViews.get(viewId)])
          .filter(isTruthy),
      ]
    ),
    metricLinksByViewId: viewLinks.metricLinksByViewId.mapEntries(
      ([viewId, viewIdsByMetricName]) => [
        viewId,
        viewIdsByMetricName
          .mapEntries(([metricName, viewId]) => [metricName, allViews.get(viewId)])
          .filter(isTruthy),
      ]
    ),
    attributeUrlLinksByViewId: viewLinks.attributeUrlLinksByViewId,
    metricUrlLinksByViewId: viewLinks.metricUrlLinksByViewId,
  };
}

function toAttributeLinksMap(links: ReadonlyArray<Types.AttributeViewLink>) {
  return Map<Types.AttributeType, number>(
    links.map(link => [link.attributeType, link.targetViewId])
  );
}

function toAttributeUrlLinksMap(links: ReadonlyArray<Types.AttributeUrlLink>) {
  return Map<Types.AttributeType, string>(links.map(link => [link.attributeType, link.url]));
}

function toMetricLinksMap(links: ReadonlyArray<Types.MetricViewLink>) {
  return Map<string, number>(links.map(link => [link.metricName, link.targetViewId]));
}

function toMetricUrlLinksMap(links: ReadonlyArray<Types.MetricUrlLink>) {
  return Map<string, string>(links.map(link => [link.metricName, link.url]));
}

export function isAttributeLink(link: Types.ViewLink): link is Types.AttributeViewLink {
  return link.type === 'ATTRIBUTE';
}

export function isMetricLink(link: Types.ViewLink): link is Types.MetricViewLink {
  return link.type === 'METRIC';
}

export function isAttributeUrlLink(link: Types.ViewLink): link is Types.AttributeUrlLink {
  return link.type === 'ATTRIBUTE_URL';
}

export function isMetricUrlLink(link: Types.ViewLink): link is Types.MetricUrlLink {
  return link.type === 'METRIC_URL';
}

function getAttributeLinks(
  links: ReadonlyArray<Types.ViewLink>
): ReadonlyArray<Types.AttributeViewLink> {
  return links.filter(isAttributeLink);
}

function getMetricLinks(links: ReadonlyArray<Types.ViewLink>): ReadonlyArray<Types.MetricViewLink> {
  return links.filter(isMetricLink);
}

function getAttributeUrlLinks(
  links: ReadonlyArray<Types.ViewLink>
): ReadonlyArray<Types.AttributeUrlLink> {
  return links.filter(isAttributeUrlLink);
}

function getMetricUrlLinks(
  links: ReadonlyArray<Types.ViewLink>
): ReadonlyArray<Types.MetricUrlLink> {
  return links.filter(isMetricUrlLink);
}

export function getSelectableViewLinkAttributeTypes(
  viewLinks: ReadonlyArray<Types.ViewLink>,
  proposedModel?: Types.ViewLink | null
) {
  const availableOptions = [Types.AttributeType.LOCATION, Types.AttributeType.PRODUCT];
  const ownAttributeType =
    proposedModel?.type === 'ATTRIBUTE' || proposedModel?.type === 'ATTRIBUTE_URL'
      ? proposedModel.attributeType
      : null;
  const otherAttributeTypes = viewLinks
    .map(link =>
      (isAttributeLink(link) || isAttributeUrlLink(link)) &&
      link.sourceViewId === proposedModel?.sourceViewId
        ? link.attributeType
        : null
    )
    .filter(isNonNullish);
  return availableOptions.filter(
    type => type === ownAttributeType || !otherAttributeTypes.includes(type)
  );
}

export function getSelectableViewLinkMetrics(
  availableMetrics: MetricsByName,
  viewLinks: ReadonlyArray<Types.ViewLink>,
  proposedModel?: Types.ViewLink | null
): MetricsByName {
  const ownMetricName =
    proposedModel?.type === 'METRIC' || proposedModel?.type === 'METRIC_URL'
      ? proposedModel.metricName
      : null;
  const otherMetricNames = viewLinks
    .map(link =>
      (isMetricLink(link) || isMetricUrlLink(link)) &&
      link.sourceViewId === proposedModel?.sourceViewId
        ? link.metricName
        : null
    )
    .filter(isNonNullish);
  return availableMetrics.filter(
    metric => metric.name === ownMetricName || !otherMetricNames.includes(metric.name)
  );
}

export function getViewLinkTypeOptions(
  selectableMetrics: MetricsByName,
  selectableAttributeTypes: ReadonlyArray<Types.AttributeType>
) {
  return defaultLinkTypeOptions
    .filter(type => !selectableMetrics.isEmpty() || (type !== 'METRIC' && type !== 'METRIC_URL'))
    .filter(
      type =>
        !!selectableAttributeTypes.length || (type !== 'ATTRIBUTE' && type !== 'ATTRIBUTE_URL')
    );
}

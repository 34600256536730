import './OrdersPreviewChart.scss';

import Chart, {ChartOptions, TooltipItem} from 'chart.js/auto';
import {Set} from 'immutable';
import moment from 'moment';
import pluralize from 'pluralize';
import React, {useCallback, useMemo, useRef} from 'react';
import {Bar} from 'react-chartjs-2';

import {SHORT_DISPLAY_DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {capitalize} from 'toolkit/format/text';
import {isMoney} from 'toolkit/metrics/utils';
import {usePeriodInterval} from 'toolkit/time/PeriodInterval';
import {getDayListFromInterval} from 'toolkit/time/utils';
import useForceUpdate from 'toolkit/utils/useForceUpdate';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {highlightSelectedItem, toggleDatasetVisibility} from 'widgets/charts/impl/chart-legend';
import ChartLegend from 'widgets/charts/impl/ChartLegend';
import {AugmentedChart, AugmentedChartDataset} from 'widgets/charts/impl/types';
import {defaultChartOptions} from 'widgets/charts/impl/utils';
import {ChartScaleId} from 'widgets/charts/types';

import {TransactionAnalysisData} from './OrdersPreviewTable';
import {
  getTransactionStatusMetricSumPerDay,
  getTransactionTypeFromMetric,
  transactionStatusColor,
} from './utils';

export function getValue(
  metricInstance: Types.MetricInstance,
  data: TransactionAnalysisData
): number {
  return isMoney(metricInstance) ? data.dollars! : data.units!;
}

const transactionStatuses = [
  Types.TransactionStatus.NEW,
  Types.TransactionStatus.CONFIRMED,
  Types.TransactionStatus.SHIPPED,
  Types.TransactionStatus.ARRIVED,
  Types.TransactionStatus.RECEIVED,
  Types.TransactionStatus.COMPLETED,
  Types.TransactionStatus.CANCELLED,
];

const OrdersPreviewChart: React.FC<OrdersPreviewChartProps> = ({
  augmentedTransactionData,
  metricInstance,
}) => {
  const chartRef = useRef<AugmentedChart<'bar'> | null | undefined>(null);
  const forceUpdate = useForceUpdate();
  const acquireChart = useCallback(
    (chart: Chart<'bar'> | undefined | null) => {
      chartRef.current = chart as AugmentedChart<'bar'> | null | undefined;
      if (chart) {
        // Ensure that we render with a non-null chart ref as ChartLegend has no other way of knowing that the chart is
        // now non-null (and renders empty otherwise)
        forceUpdate();
      }
    },
    [forceUpdate]
  );

  const interval = usePeriodInterval(metricInstance.arguments.period!);

  const days = useMemo(() => getDayListFromInterval(interval), [interval]);

  const datasets = useMemo<AugmentedChartDataset<'bar'>[]>(
    () =>
      transactionStatuses
        .filter(status =>
          augmentedTransactionData.some(transactionData => transactionData.status === status)
        )
        .map(status => {
          const bgColor = transactionStatusColor(status);
          return {
            barPercentage: 1,
            categoryPercentage: 0.7,
            label: status,
            showLabelInLegend: true,
            columnValue: null,
            metricInstance,
            widgetMetricIndex: 0,
            data: getTransactionStatusMetricSumPerDay(
              days,
              augmentedTransactionData,
              status,
              item => getValue(metricInstance, item)
            ),
            backgroundColor: bgColor,
            borderColor: bgColor,
            initialBackgroundColor: bgColor,
            initialBorderColor: bgColor,
          };
        }),
    [days, augmentedTransactionData, metricInstance]
  );

  const totalQuantity = useMemo(
    () =>
      datasets.reduce(
        (sum, dataset) => sum + dataset.data.reduce((sum, quantity) => sum + quantity, 0),
        0
      ),
    [datasets]
  );

  const chartOptions: ChartOptions<'bar'> = {
    ...defaultChartOptions,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<'bar'>) =>
            Format.transactionEventQuantity(
              metricInstance,
              parseFloat((context.raw as string) ?? '')
            ),
        },
      },
    },
    scales: {
      [ChartScaleId.X]: {
        stacked: true,
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          autoSkipPadding: 20,
          font: {size: 10},
          maxRotation: 0,
        },
      },
      [ChartScaleId.YLeft]: {
        stacked: true,
        grid: {
          drawBorder: true,
        },
        beginAtZero: true,
        ticks: {
          autoSkipPadding: 10,
          font: {size: 10},
          callback: value =>
            typeof value === 'string'
              ? value
              : Format.transactionEventQuantity(metricInstance, value),
        },
      },
    },
  };

  const handleLegendItemHover = useCallback(
    (datasetIndices: ReadonlyArray<number>) => {
      const chart = chartRef.current;
      if (chart) {
        highlightSelectedItem(chart, Set(datasetIndices), false);
      }
    },
    [chartRef]
  );

  const handleLegendItemToggle = useCallback(
    (datasetIndices: ReadonlyArray<number>) => {
      datasetIndices.forEach(datasetIndex =>
        toggleDatasetVisibility(datasetIndex, chartRef.current)
      );
    },
    [chartRef]
  );

  const resetAllHighlights = useCallback(() => {
    if (chartRef.current) {
      highlightSelectedItem(chartRef.current, Set.of(), true);
    }
  }, [chartRef]);

  return (
    <div className="OrdersPreviewChart">
      <div className="chart-title-container">
        <div className="chart-title">
          {pluralize(capitalize(assertTruthy(getTransactionTypeFromMetric(metricInstance))))}
        </div>
        <div className="chart-total">
          {Format.transactionEventQuantity(metricInstance, totalQuantity)}
        </div>
      </div>
      <div className="chart">
        <Bar
          ref={acquireChart}
          data={{
            datasets,
            labels: days.map(date =>
              Format.date(moment.utc(date), {
                formatString: SHORT_DISPLAY_DATE_FORMAT,
              })
            ),
          }}
          options={chartOptions}
          redraw={false}
        />
      </div>
      <ChartLegend
        acquireChart={() => chartRef.current ?? undefined}
        disabled={false}
        resetAllHighlights={resetAllHighlights}
        isToggleEnabled
        onItemHover={handleLegendItemHover}
        onToggle={handleLegendItemToggle}
      />
    </div>
  );
};

interface OrdersPreviewChartProps {
  augmentedTransactionData: ReadonlyArray<TransactionAnalysisData>;
  metricInstance: Types.MetricInstance;
}

OrdersPreviewChart.displayName = 'OrdersPreviewChart';

export default OrdersPreviewChart;

import {FilesAction} from 'redux/actions/files';
import {isFail, isLoading} from 'redux/actions/helpers';
import ActionType from 'redux/actions/types';
import * as Types from 'types';
import {Status} from 'utils/status';

export interface FilesState {
  readonly usedFileTypesFetchStatus: Status;
  readonly usedFileTypesRequest: Promise<unknown> | null;
  readonly usedFileTypes: ReadonlyArray<Types.FileTypeMetadata>;
  readonly classificationPatterns: ReadonlyArray<Types.ClassificationPattern>;
}

const initialFilesState: FilesState = {
  usedFileTypesFetchStatus: Status.unstarted,
  usedFileTypesRequest: null,
  usedFileTypes: [],
  classificationPatterns: [],
};

export default function files(state = initialFilesState, action: FilesAction): FilesState {
  if (action.type === ActionType.SetUsedFileTypes) {
    if (isLoading(action)) {
      return {
        ...state,
        usedFileTypesFetchStatus: Status.inProgress,
        usedFileTypesRequest: action.promise,
      };
    } else if (isFail(action)) {
      return {...state, usedFileTypesFetchStatus: Status.failed, usedFileTypesRequest: null};
    }
    return {
      ...state,
      usedFileTypes: action.data || [],
      usedFileTypesFetchStatus: Status.succeeded,
      usedFileTypesRequest: null,
    };
  } else if (action.type === ActionType.SetFileClassificationPatterns) {
    if (!isLoading(action) && !isFail(action)) {
      return {...state, classificationPatterns: action.data || []};
    }
  }
  return state;
}

import equal from 'fast-deep-equal';
import {List, Map, Set} from 'immutable';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import {useMemo} from 'react';

import {CurrentUser} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {AnalysisEntityType, ExperimentAnalysisEntity} from 'toolkit/analysis/types';
import {ComputeResultExtended, ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {invertFilter} from 'toolkit/filters/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {IconSpec} from 'toolkit/icons/types';
import {MetricsByName} from 'toolkit/metrics/types';
import {
  createDefaultMetricInstance,
  getDefaultMetricArguments,
  invertMetricFilter,
} from 'toolkit/metrics/utils';
import {
  getFixedPeriodDaysDuration,
  getVendorEvaluationDate,
  shiftFixedPeriod,
  spanFixedPeriods,
} from 'toolkit/time/utils';
import {ComputeWidgetData} from 'toolkit/views/types';
import {getExperimentUrl} from 'toolkit/views/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

import experimentIcon from './icons/icon-experiment.svg';
import groupIcon from './icons/icon-group.svg';
import {
  ExperimentBreakdownDisplayMode,
  ExperimentChartDisplayMode,
  ExperimentData,
  ExperimentState,
  ExperimentTimeDisplayMode,
} from './types';

export const EXPERIMENT_ICON: IconSpec = {
  alloyIcon: experimentIcon,
};
export const GROUP_ICON: IconSpec = {
  alloyIcon: groupIcon,
};

export enum ExperimentPeriod {
  FULL = 'FULL',
  PRE = 'PRE',
  POST = 'POST',
}

export enum ExperimentGroup {
  TEST = 0,
  CONTROL = 1,
}

// These indices are aligned with getExperimentTableMetrics.
// These metrics only exist after we've adjusted the compute result in the frontend to contain
// these metrics. This will move to pewter: https://app.shortcut.com/alloytech/story/39272
export enum ExperimentMetric {
  PRE_PERIOD = 0,
  POST_PERIOD = 1,
  CHANGE = 2,
  EXPECTATION = 3,
  LIFT = 4,
  IMPACT = 5,
}

export const experimentValueByGroupType: {[key in ExperimentGroup]: string} = {
  [ExperimentGroup.TEST]: 'Test',
  [ExperimentGroup.CONTROL]: 'Control',
};

// These indices correspond to the unadjusted compute result's metric indices.
export const metricIndexByExperimentPeriod: {[key in ExperimentPeriod]: number} = {
  FULL: 0,
  PRE: 1,
  POST: 2,
};

export const defaultExperimentState: ExperimentState = {
  breakdownDisplayMode: ExperimentBreakdownDisplayMode.AGGREGATED,
  chartDisplayMode: ExperimentChartDisplayMode.RAW,
  timeDisplayMode: ExperimentTimeDisplayMode.EXPERIMENT_DURATION,
};

export const SUPPORTED_GROUPING_TYPES = Set.of(
  Types.AttributeType.LOCATION,
  Types.AttributeType.PRODUCT
);

interface ExperimentValues {
  readonly preValue: number;
  readonly postValue: number;
  readonly prePostChange: number;
  readonly expectation: number;
  readonly lift: number;
  readonly impact: number;
}

export function createValueGroup(
  group: Types.Group,
  value: string
): Types.VirtualAttributeValueGroup {
  return {
    value,
    group,
    valueId: null,
  };
}

const predicateInverseDescription: {
  readonly [key in Types.MetricFilterPredicate]: string;
} = {
  [Types.MetricFilterPredicate.EQ]: 'Different',
  [Types.MetricFilterPredicate.NE]: 'Equal',
  [Types.MetricFilterPredicate.GE]: 'Lower',
  [Types.MetricFilterPredicate.LT]: 'Higher',
  [Types.MetricFilterPredicate.LE]: 'Higher',
  [Types.MetricFilterPredicate.GT]: 'Lower',
  [Types.MetricFilterPredicate.TOP]: 'Lower',
  [Types.MetricFilterPredicate.BOTTOM]: 'Higher',
};

// The resulting groups are sorted by their "usefulness", i.e. how likely it is that
// the specific group is a good choice. Specifically, sorting is by the respective
// inverted filter, with
// - all non-Partner attribute filters, sorted by the number of values
// - then any partner filter
// - and finally any metric filters
export function generateConverseGroups(group: Types.Group): ReadonlyArray<Types.Group> {
  if (!group) {
    return [];
  }
  return List(group.filters)
    .map(filterToInvert => ({
      order:
        filterToInvert.attributeInstance.attribute.name === 'Partner'
          ? 0
          : -filterToInvert.values.length,
      group: {
        ...group,
        filters: group.filters.map(filter =>
          equal(filter, filterToInvert) ? invertFilter(filter) : filter
        ),
        name: `Other ${pluralize(filterToInvert.attributeInstance.attribute.name)}`,
      },
    }))
    .sortBy(elem => elem.order)
    .map(elem => elem.group)
    .concat(
      group.metricFilters.map(metricFilterToInvert => ({
        ...group,

        metricFilters: group.metricFilters.map(metricFilter =>
          equal(metricFilter, metricFilterToInvert)
            ? invertMetricFilter(metricFilter)
            : metricFilter
        ),
        name: `${predicateInverseDescription[metricFilterToInvert.predicate]} ${Format.metric(
          metricFilterToInvert.metric,
          {lengthFormat: TextLengthFormat.COMPACT}
        )}`,
      }))
    )
    .map((group, index) => ({
      ...group,
      id: -1 - index,
      creationSource: Types.GroupCreationSource.EXPERIMENT_CONVERSE,
    }))
    .toArray();
}

export function isManuallyCreatedGroup(group: Types.Group | undefined | null) {
  return group?.creationSource === Types.GroupCreationSource.MANUALLY_CREATED;
}

type ExperimentMetricOption = {
  metricName: string;
  argumentOverrides: Partial<Types.MetricArguments>;
  displayMetricName: string;
};

export const ALLOWED_EXPERIMENT_METRIC_OPTIONS: ReadonlyArray<ExperimentMetricOption> = [
  {
    metricName: 'sales_units_net',
    argumentOverrides: {},
    displayMetricName: 'sales_units_net',
  },
  {
    metricName: 'sales_net',
    argumentOverrides: {dollarType: Types.DollarType.RETAIL_PRICE},
    displayMetricName: 'sales_dollars_net',
  },
];

export const createDefaultExperimentMetricInstance = (
  option: ExperimentMetricOption,
  period: Types.DatePeriod,
  settings: Settings,
  availableMetrics: MetricsByName
) => {
  const metricInstance = createDefaultMetricInstance(
    availableMetrics.get(option.metricName)!,
    period,
    settings,
    availableMetrics,
    settings.analysisSettings.currency,
    false
  );
  return {
    ...metricInstance,
    arguments: {...metricInstance.arguments, ...option.argumentOverrides},
  };
};

export function getAllowedExperimentMetricOptions(availableMetrics: MetricsByName) {
  return ALLOWED_EXPERIMENT_METRIC_OPTIONS.filter(option =>
    availableMetrics.has(option.displayMetricName)
  );
}

export function createDefaultExperiment(
  currentUser: CurrentUser,
  availableMetrics: MetricsByName
): Types.Experiment {
  const allowedMetricOptions = getAllowedExperimentMetricOptions(availableMetrics);
  if (!allowedMetricOptions.length) {
    throw new Error(
      `Cannot create default experiment; no allowed experiment metrics present for ${currentUser.vendor.name}`
    );
  }

  const defaultDatePeriod = getDefaultExperimentPeriod(currentUser.settings.analysisSettings);
  return {
    attribute: {
      attributeId: null,
      templateAttributeId: null,
      name: '',
      templateAttributeName: '',
      // FIXME strict-null: null is used as a placeholder here for an empty group, but
      // it's an invalid model on server-side and can't be saved.
      groups: [null!, null!],
    },
    id: null,
    groupings: [],
    lastModifiedAt: moment().toISOString(),
    lastModifiedBy: currentUser.user.id,
    name: 'New Experiment',
    metrics: [
      createDefaultExperimentMetricInstance(
        allowedMetricOptions[0],
        defaultDatePeriod,
        currentUser.settings,
        availableMetrics
      ),
    ],
    ownerId: currentUser.user.id,
    postPeriodEndDate: defaultDatePeriod.end,
    postPeriodStartDate: defaultDatePeriod.start,
    shareLevel: Types.ShareLevel.SHARED_READ_WRITE,
    slug: '',
    tags: [],
  };
}

export function getExperimentPostPeriod(experiment: ExperimentData): Types.FixedDatePeriod {
  return {
    type: 'fixed',
    start: experiment.postPeriodStartDate,
    end: experiment.postPeriodEndDate,
  };
}

export function getExperimentPrePeriod(experiment: ExperimentData) {
  const observationPeriod = getExperimentPostPeriod(experiment);
  const observationPeriodDuration = getFixedPeriodDaysDuration(observationPeriod);
  return shiftFixedPeriod(observationPeriod, -observationPeriodDuration);
}

export function getExperimentFullPeriod(experiment: ExperimentData) {
  const observationPeriod = getExperimentPostPeriod(experiment);
  const observationPeriodDuration = getFixedPeriodDaysDuration(observationPeriod);
  const prePeriod = shiftFixedPeriod(observationPeriod, -observationPeriodDuration);
  return spanFixedPeriods(observationPeriod, prePeriod);
}

export function getExperimentMetricInstance(
  metrics: ReadonlyArray<Types.MetricInstance>,
  experimentPeriod: ExperimentPeriod
) {
  return metrics[metricIndexByExperimentPeriod[experimentPeriod]];
}

export function getExperimentGroup(experiment: ExperimentData, group: ExperimentGroup) {
  return experiment.attribute.groups[group];
}

export function getExperimentMetric(
  result: ComputeResultExtended,
  experimentMetric: ExperimentMetric
) {
  return result.metrics[experimentMetric];
}

// this is aligned with ExperimentMetric
export function getExperimentTableMetrics(
  experimentData: ExperimentData
): ReadonlyArray<Types.MetricInstance> {
  const metric = experimentData.metrics[0].metric;
  return [
    {
      metric: {...metric, displayName: 'Pre-Period'},
      arguments: getDefaultMetricArguments(getExperimentPrePeriod(experimentData)),
    },
    {
      metric: {...metric, displayName: 'Post-Period'},
      arguments: getDefaultMetricArguments(getExperimentPostPeriod(experimentData)),
    },
    {
      metric: {
        ...metric,
        name: 'change',
        features: [Types.MetricFeature.IS_HIGHLIGHTABLE],
        displayName: 'Change',
        displayType: Types.MetricDisplayType.PERCENT,
      },
      arguments: getDefaultMetricArguments(getExperimentPostPeriod(experimentData)),
    },
    {
      metric: {...metric, name: 'expectation', displayName: 'Expectation'},
      arguments: getDefaultMetricArguments(getExperimentPostPeriod(experimentData)),
    },
    {
      metric: {
        ...metric,
        name: 'lift',
        displayName: 'Lift',
        features: [Types.MetricFeature.IS_HIGHLIGHTABLE],
        displayType: Types.MetricDisplayType.PERCENT,
      },
      arguments: getDefaultMetricArguments(getExperimentPostPeriod(experimentData)),
    },
    {
      metric: {
        ...metric,
        name: 'impact',
        features: [Types.MetricFeature.IS_HIGHLIGHTABLE],
        displayName: 'Impact',
      },
      arguments: getDefaultMetricArguments(getExperimentPostPeriod(experimentData)),
    },
  ];
}

export function getExperimentTableComputeResult(
  experimentData: ExperimentData | null,
  widgetData: ComputeWidgetData | null,
  breakdownDisplayMode: ExperimentBreakdownDisplayMode,
  timeDisplayMode: ExperimentTimeDisplayMode
): ComputeResultExtended | null {
  const testGroup = experimentData
    ? getExperimentGroup(experimentData, ExperimentGroup.TEST)
    : null;
  const controlGroup = experimentData
    ? getExperimentGroup(experimentData, ExperimentGroup.CONTROL)
    : null;

  if (!widgetData?.data?.data || !testGroup || !controlGroup || !experimentData) {
    return null;
  }

  const metrics = getExperimentTableMetrics(experimentData);

  const testBreakdownCount = getGroupBreakdownCount(
    experimentData,
    ExperimentGroup.TEST,
    widgetData
  );
  const controlBreakdownCount = getGroupBreakdownCount(
    experimentData,
    ExperimentGroup.CONTROL,
    widgetData
  );

  const controlValueId = getTestControlAttributeValueId(
    experimentData,
    ExperimentGroup.CONTROL,
    widgetData
  );

  const rootRow = widgetData.data.data;

  const mapColumn = (
    column: Types.ThinComputeResultColumn,
    experimentGroup: ExperimentGroup,
    breakdownCount: number,
    valueIdPath: ReadonlyArray<number | null>
  ): Types.ThinComputeResultColumn => {
    const experimentValues =
      experimentGroup === ExperimentGroup.TEST
        ? getExperimentTestValues(column, getControlColumn(experimentData, rootRow, valueIdPath))
        : getExperimentControlValues(column);
    const metricValues = toMetricValuesArray(
      experimentValues,
      experimentData,
      breakdownDisplayMode,
      breakdownCount,
      timeDisplayMode
    );

    return {
      children: [],
      metricMetadata: metricValues.map(_ => []),
      metricValues,
      value: null,
    };
  };

  return widgetData.data.merge({
    metrics,
    data: {
      ...rootRow,
      children: rootRow.children.map<ThinComputeResultRowExtended>(testControlRow => {
        const valueGroup =
          testControlRow.value!.value === testGroup.value ? testGroup : controlGroup;
        const experimentGroup =
          valueGroup === testGroup ? ExperimentGroup.TEST : ExperimentGroup.CONTROL;
        const breakdownCount =
          experimentGroup === ExperimentGroup.TEST ? testBreakdownCount : controlBreakdownCount;
        return {
          ...testControlRow,
          children: testControlRow.children.map(breakdownRow => {
            const path = [controlValueId, breakdownRow.value!.id!];
            return {
              ...breakdownRow,
              columnData: mapColumn(breakdownRow.columnData, experimentGroup, 1, path),
            };
          }),
          columnData: mapColumn(testControlRow.columnData, experimentGroup, breakdownCount, [
            controlValueId,
          ]),
          value: {
            ...testControlRow.value!,
            displayValue: valueGroup?.group.name || '',
          },
        };
      }),
    },
  });
}

function getControlColumn(
  experimentData: ExperimentData,
  rootRow: ThinComputeResultRowExtended | null | undefined,
  valueIdPath: ReadonlyArray<number | null>
): Types.ThinComputeResultColumn | null {
  if (!valueIdPath.length || !rootRow) {
    return rootRow?.columnData ?? null;
  }

  const valueId = valueIdPath[0];
  return getControlColumn(
    experimentData,
    rootRow.children.find(childRow => childRow.value!.id === valueId),
    valueIdPath.slice(1)
  );
}

function toMetricValuesArray(
  values: ExperimentValues,
  experimentData: ExperimentData,
  breakdownDisplayMode: ExperimentBreakdownDisplayMode,
  breakdownCount: number,
  timeDisplayMode: ExperimentTimeDisplayMode
): ReadonlyArray<number> {
  // The order here must be aligned with ExperimentMetric indices
  return [
    getAdjustedExperimentValue(
      values.preValue,
      experimentData,
      breakdownDisplayMode,
      timeDisplayMode,
      breakdownCount
    ),
    getAdjustedExperimentValue(
      values.postValue,
      experimentData,
      breakdownDisplayMode,
      timeDisplayMode,
      breakdownCount
    ),
    values.prePostChange,
    getAdjustedExperimentValue(
      values.expectation,
      experimentData,
      breakdownDisplayMode,
      timeDisplayMode,
      breakdownCount
    ),
    values.lift,
    getAdjustedExperimentValue(
      values.impact,
      experimentData,
      breakdownDisplayMode,
      timeDisplayMode,
      breakdownCount
    ),
  ];
}

export function getExperimentTestValues(
  testColumn: Types.ThinComputeResultColumn,
  controlColumn: Types.ThinComputeResultColumn | null
): ExperimentValues {
  const {preValue, postValue, prePostChange} = getExperimentBaseValues(testColumn);
  const {prePostChange: controlPrePostChange} = getExperimentBaseValues(controlColumn);

  const expectation = (1 + controlPrePostChange) * preValue;
  const lift = postValue / expectation - 1;
  const impact = postValue - expectation;

  return {
    preValue,
    postValue,
    prePostChange,
    expectation,
    lift,
    impact,
  };
}

export function getExperimentControlValues(
  column: Types.ThinComputeResultColumn
): ExperimentValues {
  return {
    ...getExperimentBaseValues(column),
    expectation: Number.NaN,
    lift: Number.NaN,
    impact: Number.NaN,
  };
}

function getExperimentBaseValues(column: Types.ThinComputeResultColumn | null) {
  if (!column) {
    return {
      preValue: Number.NaN,
      postValue: Number.NaN,
      prePostChange: Number.NaN,
    };
  }

  const preMetricIndex = metricIndexByExperimentPeriod[ExperimentPeriod.PRE];
  const postMetricIndex = metricIndexByExperimentPeriod[ExperimentPeriod.POST];

  const preValue = column.metricValues[preMetricIndex];
  const postValue = column.metricValues[postMetricIndex];
  const prePostChange = postValue / preValue - 1;

  return {
    preValue,
    postValue,
    prePostChange,
  };
}

export function getBreakdownAdjustedValue(
  value: number,
  breakdownDisplayMode: ExperimentBreakdownDisplayMode,
  breakdownCount: number
) {
  switch (breakdownDisplayMode) {
    case ExperimentBreakdownDisplayMode.AGGREGATED:
      return value;
    case ExperimentBreakdownDisplayMode.PER_BREAKDOWN:
      return value / breakdownCount;
  }
}

export function getTimeAdjustedValue(
  value: number,
  experimentData: ExperimentData,
  timeDisplayMode: ExperimentTimeDisplayMode
) {
  const postPeriodDayCount = getFixedPeriodDaysDuration({
    start: experimentData.postPeriodStartDate,
    end: experimentData.postPeriodEndDate,
    type: 'fixed',
  });
  switch (timeDisplayMode) {
    case ExperimentTimeDisplayMode.EXPERIMENT_DURATION:
      return value;
    case ExperimentTimeDisplayMode.PER_DAY:
      return value / postPeriodDayCount;
    case ExperimentTimeDisplayMode.PER_WEEK:
      return (value / postPeriodDayCount) * 7;
  }
}

export function getAdjustedExperimentValue(
  value: number,
  experimentData: ExperimentData,
  breakdownDisplayMode: ExperimentBreakdownDisplayMode,
  timeDisplayMode: ExperimentTimeDisplayMode,
  breakdownCount: number
) {
  const breakdownAdjustedValue = getBreakdownAdjustedValue(
    value,
    breakdownDisplayMode,
    breakdownCount
  );
  return getTimeAdjustedValue(breakdownAdjustedValue, experimentData, timeDisplayMode);
}

function getTestControlAttributeValueId(
  experimentData: ExperimentData,
  experimentGroup: ExperimentGroup,
  widgetData: ComputeWidgetData | null
) {
  const valueGroupValue = experimentData.attribute.groups[experimentGroup]?.value;
  const children = widgetData?.data?.data?.children;
  return children?.find(child => child.value!.value === valueGroupValue)?.value!.id ?? null;
}

export function getExperimentAnalysisEntities(
  experiments: ReadonlyArray<Types.Experiment>,
  visitedTimes: Map<number, string | null>,
  favoriteExperimentIds: ReadonlyArray<number>,
  vendorName: string
): ReadonlyArray<ExperimentAnalysisEntity> {
  return experiments.map(experiment =>
    getExperimentAnalysisEntity(experiment, visitedTimes, favoriteExperimentIds, vendorName)
  );
}

export function getExperimentAnalysisEntity(
  experiment: Types.Experiment,
  visitedTimes: Map<number, string | null>,
  favoriteExperimentIds: ReadonlyArray<number>,
  vendorName: string
): ExperimentAnalysisEntity {
  return {
    id: experiment.id,
    icon: getExperimentIcon(),
    isFavorite: favoriteExperimentIds.includes(assertTruthy(experiment.id)),
    isPrivate: false,
    lastVisited: visitedTimes.get(assertTruthy(experiment.id)),
    name: experiment.name,
    ownerId: experiment.ownerId,
    tags: experiment.tags,
    type: AnalysisEntityType.EXPERIMENT,
    url: getExperimentUrl(vendorName, experiment),
    value: experiment,
  };
}

export function getExperimentIcon(): IconSpec {
  return EXPERIMENT_ICON;
}

export function getDefaultExperimentPeriod(
  analysisSettings: Types.VendorAnalysisSettings
): Types.FixedDatePeriod {
  return {
    type: 'fixed',
    start: getVendorEvaluationDate(analysisSettings.evaluationDate)
      .subtract(4, 'weeks')
      .format(DATE_FORMAT),
    end: getVendorEvaluationDate(analysisSettings.evaluationDate).format(DATE_FORMAT),
  };
}

export function getGroupBreakdownCount(
  experimentData: ExperimentData,
  experimentGroup: ExperimentGroup,
  widgetData: ComputeWidgetData | null
): number {
  if (!widgetData?.data) {
    return Number.NaN;
  }

  const valueGroupValue = experimentData.attribute.groups[experimentGroup]?.value;
  return (
    widgetData.data.data.children.find(
      testControlRow => testControlRow.value!.value === valueGroupValue
    )?.children.length || Number.NaN
  );
}

function getExperimentData(
  experimentId: number | null | undefined,
  attribute: Types.VirtualAttribute,
  groupings: ReadonlyArray<Types.AttributeInstance>,
  metrics: ReadonlyArray<Types.MetricInstance>,
  postPeriodStartDate: string,
  postPeriodEndDate: string
): ExperimentData {
  return {
    id: experimentId,
    attribute,
    groupings,
    metrics,
    postPeriodStartDate,
    postPeriodEndDate,
  };
}

export function useExperimentData(
  originalExperiment: Types.Experiment | null | undefined,
  proposedExperiment: Types.Experiment | null | undefined = null
): ExperimentData | null {
  const {id, attribute, groupings, postPeriodStartDate, postPeriodEndDate} =
    originalExperiment ?? proposedExperiment ?? {};
  // Metrics are part of the model, but are treated in the UI in a confusing manner; they are
  // the only setting that doesn't force a save prior to seeing results.
  // A good fix for this hack would be to have all props update the experiment without need to save,
  // or have metrics live in the sidebar and require save.
  const metrics = proposedExperiment?.metrics || originalExperiment?.metrics;
  return useMemo(
    () =>
      attribute
        ? getExperimentData(
            id,
            attribute,
            assertTruthy(groupings),
            assertTruthy(metrics),
            assertTruthy(postPeriodStartDate),
            assertTruthy(postPeriodEndDate)
          )
        : null,
    [id, attribute, groupings, metrics, postPeriodStartDate, postPeriodEndDate]
  );
}

export function hasValidGroups(experimentData: ExperimentData | null) {
  return (
    experimentData?.attribute.groups.length === 2 && experimentData.attribute.groups.every(Boolean)
  );
}

export function canSaveGroup(group: Types.Group) {
  if (!group.name.trim().length) {
    return false;
  }
  if (group.filters.length > 0) {
    const hasIncompleteMetricFilters =
      (group.metricFilterGrouping && !group.metricFilters.length) ||
      (!group.metricFilterGrouping && group.metricFilters.length > 0);
    // disallow saving if there are filters but incomplete metric filters
    return !hasIncompleteMetricFilters;
  }
  return group.metricFilters.length > 0 && !!group.metricFilterGrouping;
}

function getComparableExperimentData(
  experimentData: ExperimentData | null
): Partial<ExperimentData> | null {
  return experimentData ? {...experimentData, metrics: undefined} : null;
}

export function needsToSaveExperiment(
  originalExperimentData: ExperimentData | null,
  proposedExperimentData: ExperimentData | null
) {
  return !equal(
    getComparableExperimentData(originalExperimentData),
    getComparableExperimentData(proposedExperimentData)
  );
}

import './Select.scss';

import classNames from 'classnames';
import equal from 'fast-deep-equal';
import {List, Set} from 'immutable';
import React, {ReactElement, useEffect, useRef, useState} from 'react';

import ConfigLabel, {ConfigLabelDisplayVariant} from 'toolkit/components/ConfigLabel';
import SearchableDropdownList, {OptionProps} from 'toolkit/components/SearchableDropdownList';
import SelectOption from 'toolkit/components/SelectOption';
import {descendingBy} from 'utils/arrays';
import {isRunningEndToEndTests} from 'utils/cookies';
import {noop} from 'utils/functions';
import {matchesSearchQuery} from 'utils/search';
import {Lazy, resolve, useLazy} from 'utils/useLazy';
import {SearchableOption} from 'widgets/types';

import Dropdown, {AttachmentProps} from './Dropdown';

export const SELECT_CLASSNAME = 'Select';

function defaultSearchableOptionGetter<T>(toLabel: (option: T) => string) {
  return (option: T) => {
    const label = toLabel(option);
    return {key: label, label, shorthand: label, value: option};
  };
}

const MINIMUM_ITEMS_FOR_SEARCH = 5;
function shouldDisplaySearch(options: BaseProps<unknown>['options']) {
  if (options instanceof Lazy || isRunningEndToEndTests()) {
    return true;
  } else if (Array.isArray(options)) {
    return options.length >= MINIMUM_ITEMS_FOR_SEARCH;
  } else {
    return options.size >= MINIMUM_ITEMS_FOR_SEARCH;
  }
}

export type OptionValues = string | number | boolean;

function Select<T>(props: SelectProps<T>) {
  const [isDropdownOpen, setDropdownOpen] = useState(props.isOpenByDefault || false);

  const newProps = {
    ...props,
    isDropdownOpen,
    setDropdownOpen,
  };
  if (props.clearable) {
    // we know this because we define this in the type depending on clearable prop but typescript cannot infer it
    const clearableProps = {
      ...newProps,
      onChange: props.onChange as (value: T | null) => void | undefined,
      selectedOption: props.selectedOption as T | null,
    };
    return <ClearableSelect {...clearableProps} />;
  } else {
    return <ControlledSelect {...newProps} />;
  }
}

function ClearableSelect<T>({
  onChange,
  selectedOption,
  onDelete,
  isOpenByDefault,
  ...rest
}: ClearableSelectProps<T>) {
  const [currentValue, setCurrentValue] = useState(selectedOption);
  const [isDropdownOpen, setDropdownOpen] = useState(isOpenByDefault || false);
  useEffect(() => {
    setCurrentValue(selectedOption);
  }, [selectedOption]);
  return (
    <ControlledSelect<T>
      isDropdownOpen={isDropdownOpen}
      selectedOption={currentValue}
      setDropdownOpen={setDropdownOpen}
      isDeleteEnabled
      onChange={(option, {event}) => {
        setCurrentValue(option);
        onChange?.(option, {event});
      }}
      onDelete={event => {
        setCurrentValue(null);
        onChange?.(null, {event});
        onDelete?.(event);
      }}
      {...rest}
    />
  );
}

function ControlledSelect<T>(props: ControlledSelectProps<T>) {
  const buttonRef = useRef<HTMLDivElement>(null);

  const [initialFilterText, setInitialFilterText] = useState('');
  const openDropdown = () => !props.disabled && props.setDropdownOpen(true);
  const closeDropdown = () => {
    props.setDropdownOpen(false);
    setInitialFilterText('');
    if (props.onClose) {
      props.onClose();
    }
  };

  // eslint-disable-next-line fp/no-let
  let {isCreateEnabled, isDeleteEnabled} = props;

  if (props.disabled) {
    isCreateEnabled = false;
    isDeleteEnabled = false;
  }

  const {
    getOptionDisplayName = option => '' + option,
    getSearchableOption,
    optionTypeDisplayName,
    options,
    comparator,
  } = props;
  const getSearchableOptions = useLazy(() => {
    const resolved = resolve(options);
    return (Array.isArray(resolved) ? List(resolved) : resolved).map(
      getSearchableOption ?? defaultSearchableOptionGetter(getOptionDisplayName)
    );
  }, [getOptionDisplayName, getSearchableOption, options]);
  const getSearchResults = (filterText: string) => {
    props.onSearch?.(filterText);
    return getSearchableOptions
      .get()
      .filter(
        option =>
          matchesSearchQuery(option.label, filterText) ||
          matchesSearchQuery(option.shorthand, filterText)
      )
      .sort(
        descendingBy<SearchableOption<T>>(val =>
          val.label.toLowerCase().startsWith(filterText.toLowerCase())
        )
          .thenDescendingBy(val => val.label.toLowerCase().includes(filterText.toLowerCase()))
          .thenByComparator(
            comparator ? (optionA, optionB) => comparator(optionA.label, optionB.label) : () => 0
          )
      )
      .toArray();
  };

  const handleDeleteMultiOption = (deletedOption: T, event: React.MouseEvent) => {
    if (props.multi) {
      // condition only for the typing, should always be true
      // for the single case onDelete is passed to ConfigLabel
      props.onChange?.(
        (props.selectedOption ?? []).filter(selectedOption => selectedOption !== deletedOption),
        {event, created: false}
      );
      props.onDelete?.(event);
      event.stopPropagation();
    }
  };

  const createDeletableOption = (option: T, optionDisplay: string | React.ReactNode) => (
    <ConfigLabel
      key={getOptionDisplayName(option)}
      isDeleteEnabled={isDeleteEnabled}
      isEditEnabled={false}
      value={optionDisplay}
      onDelete={e => handleDeleteMultiOption(option, e)}
    />
  );

  function hasValue() {
    if (props.selectedOption === undefined || props.selectedOption === null) {
      return false;
    }
    if (props.multi) {
      return props.selectedOption.length !== 0;
    }

    return true;
  }

  const getLabelValue = () => {
    if (!hasValue()) {
      return props.placeholder
        ? props.placeholder
        : optionTypeDisplayName
          ? `+ Add ${optionTypeDisplayName}`
          : 'Select ...';
    }
    if (props.multi) {
      return props.valueRenderer
        ? props.selectedOption!.map(selectedOption =>
            createDeletableOption(
              selectedOption,
              props.valueRenderer!({
                openDropdown,
                option: selectedOption,
              })
            )
          )
        : props.selectedOption!.map(selectedOption =>
            createDeletableOption(selectedOption, getOptionDisplayName(selectedOption))
          );
    } else {
      return props.valueRenderer
        ? props.valueRenderer({
            openDropdown,
            option: props.selectedOption,
          })
        : getOptionDisplayName(props.selectedOption!);
    }
  };
  const labelValue = getLabelValue();

  const handleRootClose = (event: Event) => event.target !== buttonRef.current;

  const handleSelect = (
    searchableOption: SearchableOption<T>,
    event: React.UIEvent<HTMLElement>
  ) => {
    closeDropdown();
    if (props.multi) {
      const newValues = Set(props.selectedOption ?? []).add(searchableOption.value);
      props.onChange?.([...newValues], {event, created: false});
    } else {
      props.onChange?.(searchableOption.value, {event, created: false});
    }
  };

  const renderCreate = () => (
    <SelectOption<null>
      blurOnArrowLeft={false}
      className="create-option"
      option={null}
      optionLabel={props.createLabel || 'Create'}
      onBlur={closeDropdown}
      onSelect={props.onCreate!}
    />
  );

  const renderOption =
    props.optionRenderer ??
    ((optionProps: OptionProps<SearchableOption<T>>) => {
      const selected =
        hasValue() && Array.isArray(props.selectedOption) && props.multi
          ? props.selectedOption.some(option => equal(option, optionProps.option.value))
          : equal(props.selectedOption, optionProps.option.value);
      return (
        <SelectOption
          key={optionProps.key}
          blurOnArrowLeft={false}
          className={classNames(
            optionProps.className,
            {selected},
            props.getRowClassName?.(optionProps?.option.value)
          )}
          descriptionTooltip={
            props.getOptionDescriptionTooltip &&
            optionProps?.option.value &&
            props.getOptionDescriptionTooltip(optionProps?.option.value)
          }
          disabled={props.optionIsDisabled?.(optionProps.option.value)}
          option={optionProps.option}
          optionLabel={
            props.optionLabelRenderer
              ? props.optionLabelRenderer(optionProps)
              : optionProps.option.label
          }
          selected={selected}
          showCheckOnSelection={props.showCheckOnSelection}
          subtitle={optionProps.option.subtitle}
          onBlur={closeDropdown}
          onSelect={handleSelect}
        />
      );
    });

  const renderDropdownContent =
    props.menuRenderer ??
    (({renderOption, getSearchResults, handleSelect, renderCreate}) => (
      <>
        {props.menuHeader}
        <SearchableDropdownList<SearchableOption<T>>
          className="select-dropdown-list select-menu"
          createOption={(isCreateEnabled && renderCreate()) ?? undefined}
          initialFilterText={initialFilterText}
          listClassName={classNames('select-list', {virtualized: !!props.virtualized})}
          noResultsText={props.noResultsText}
          optionKey={option => option.key}
          render={renderOption}
          resourceName={optionTypeDisplayName}
          search={getSearchResults}
          searchable={props.searchable ?? shouldDisplaySearch(props.options)}
          virtualized={props.virtualized}
          onSelect={handleSelect}
        />
      </>
    ));

  const renderTetherTarget = (ref: React.RefObject<any> | undefined) => (
    <div
      ref={ref}
      className="select-tether-target"
      tabIndex={props.tabIndex}
      onClick={props.isDropdownOpen ? closeDropdown : openDropdown}
    >
      {props.children || (
        <ConfigLabel
          ref={buttonRef}
          className={classNames('select-current-selection-label', {active: props.isDropdownOpen})}
          displayVariant={props.configLabelDisplayVariant}
          isActive={props.isDropdownOpen}
          isCloseButtonEnabled={!!props.isCloseButtonEnabled && !hasValue()}
          isDeleteEnabled={!!isDeleteEnabled && hasValue()}
          isDisabled={props.disabled}
          isEditEnabled={false}
          isPlaceholder={!hasValue()}
          value={labelValue}
          showDropdownCaret
          onCloseButtonClick={props.onCloseButtonClick}
          onDelete={props.onDelete}
        />
      )}
    </div>
  );

  const openMenuOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (props.autoFocus && !props.isDropdownOpen && event.key.length === 1) {
      props.setDropdownOpen(true);
      setInitialFilterText(event.key);
    }
  };

  return (
    <div
      ref={comp => (props.autoFocus ? comp?.focus() : noop())}
      className={classNames(SELECT_CLASSNAME, props.className, {
        'select-multi': props.multi,
        disabled: props.disabled,
      })}
      tabIndex={props.autoFocus ? 0 : undefined}
      onKeyDown={openMenuOnKeyDown}
    >
      <Dropdown
        attachment={props.dropdownAttachmentProps?.attachment}
        className={props.menuClassName}
        dropdownContentClassName="select-dropdown-content"
        floatOffsetPx={props.floatOffsetPx}
        isFloating={props.isFloating}
        isOpen={props.isDropdownOpen}
        matchWidth={!props.menuWidth}
        renderDropdownContent={_ =>
          renderDropdownContent({
            renderOption,
            getSearchResults,
            renderCreate,
            handleSelect,
            value: props.selectedOption,
          })
        }
        renderTarget={renderTetherTarget}
        targetAttachment={props.dropdownAttachmentProps?.targetAttachment}
        width={props.menuWidth}
        onClose={closeDropdown}
        onRootClose={handleRootClose}
      />
    </div>
  );
}

Select.displayName = 'Select';

interface BaseProps<T> {
  autoFocus?: boolean;
  children?: React.ReactNode;
  className?: string;
  clearable?: boolean;
  comparator?: (displayNameA: string, displayNameB: string) => number;
  createLabel?: string;
  disabled?: boolean;
  dropdownAttachmentProps?: AttachmentProps;
  getOptionDescriptionTooltip?: (option: T) => React.ReactNode;
  getOptionDisplayName?: (option: T) => string;
  getRowClassName?: (option: T) => string | undefined;
  getSearchableOption?: (option: T) => SearchableOption<T>;
  isCloseButtonEnabled?: boolean;
  isCreateEnabled?: boolean;
  isDeleteEnabled?: boolean;
  isOpenByDefault?: boolean;
  isUserMenu?: boolean;
  menuClassName?: string;
  menuHeader?: React.ReactNode;
  menuRenderer?: MenuRendererHandlerMigrate<T>;
  menuWidth?: number | string;
  noResultsText?: string | ReactElement;
  onClose?: () => void;
  onCloseButtonClick?: () => void; // required if isCloseButtonEnabled
  onCreate?: () => void;
  onDelete?: (event: React.UIEvent) => void; // required if isDeleteEnabled
  onSearch?: (filterText: string) => void;
  // if specified with optionRenderer, optionRenderer will take precedence
  optionLabelRenderer?: (optionProps: OptionProps<SearchableOption<T>>) => React.ReactNode;
  optionRenderer?: (optionProps: OptionProps<SearchableOption<T>>) => React.ReactNode;
  optionTypeDisplayName?: string;
  options: List<T> | ReadonlyArray<T> | Lazy<List<T> | ReadonlyArray<T>>;
  placeholder?: string | ReactElement;
  searchable?: boolean;
  showCheckOnSelection?: boolean;
  tabIndex?: number;
  virtualized?: boolean;
  valueRenderer?: (props: {
    openDropdown: () => void;
    option: T | null | undefined;
  }) => React.ReactNode;
  configLabelDisplayVariant?: ConfigLabelDisplayVariant;
  isFloating?: boolean;
  floatOffsetPx?: number;
  optionIsDisabled?: (option: T) => boolean;
}

export type SingleSelectProps<T> = BaseProps<T> & {
  selectedOption?: T | null;
  onChange?: (options: T, meta: {event: React.UIEvent; created: boolean}) => void;
};

export type MultiSelectProps<T> = BaseProps<T> & {
  selectedOption?: ReadonlyArray<T>;
  onChange?: (
    options: ReadonlyArray<T>,
    meta: {
      event: React.UIEvent;
      created: boolean;
    }
  ) => void;
};

export type SelectProps<T> =
  | (BaseProps<T> & SingleSelectProps<T> & {multi?: false})
  | (BaseProps<T> & MultiSelectProps<T> & {multi: true});

type ControlledSelectProps<T> = SelectProps<T> & {
  isDropdownOpen: boolean;
  setDropdownOpen: (isOpen: boolean) => void;
};

interface ClearableSelectProps<T> extends Omit<SingleSelectProps<T>, 'onChange'> {
  onChange: (option: T | null, meta: {event: React.UIEvent}) => void;
}

export type MenuRendererHandlerMigrate<T> = (
  options: MenuRendererHandlerMigrateProps<T>
) => React.ReactNode;

export type MenuRendererHandlerMigrateProps<T> = {
  renderOption: (optionProps: OptionProps<SearchableOption<T>>) => React.ReactNode;
  getSearchResults: (filterText: string) => ReadonlyArray<SearchableOption<T>>;
  handleSelect: (option: SearchableOption<T>, event: React.UIEvent<HTMLElement>) => void;
  renderCreate: () => React.ReactNode;
  value: T | null | undefined | ReadonlyArray<T>;
};

export default Select;

import {VendorLocationChangeAction} from 'app/router-types';
import {dashboardProperties} from 'dashboard/DashboardManagementPage';
import {DestinationEntityProperties} from 'data/destinations/types';
import {TagOption} from 'data/integrations/DataIntegrationsPage';
import {Dispatch} from 'redux/reducers';
import {SortModel} from 'toolkit/ag-grid/types';
import {DateSortType} from 'toolkit/analysis/types';
import {ALL_SELECTED, SelectableValues, SelectableValuesByProperty} from 'toolkit/entities/types';
import {BreadcrumbItem} from 'toolkit/views/types';
import * as Types from 'types';

import {DeleteViewAction} from './analysis';
import ActionType from './types';

export type DeleteTagAction = {type: ActionType.DeleteTag; tag: Types.Tag};
export type SaveTagAction = {
  type: ActionType.SaveTag;
  tagToSave: Types.Tag;
  previousTag: Types.Tag | null;
};

export type NavigationAction =
  | SaveTagAction
  | DeleteTagAction
  | DeleteViewAction
  | VendorLocationChangeAction
  | {type: ActionType.SetDashboardManagementFilterText; filterText: string}
  | {type: ActionType.SetEventManagementFilterText; filterText: string}
  | {type: ActionType.SetDestinationManagementFilterText; filterText: string}
  | {
      type: ActionType.SetDashboardManagementActiveFilter;
      selectedValuesByProperty: SelectableValuesByProperty<'type' | 'tags'>;
    }
  | {
      type: ActionType.SetEventManagementActiveFilter;
      selectedValuesByProperty: SelectableValuesByProperty<'status' | 'type'>;
    }
  | {
      type: ActionType.SetDestinationManagementActiveFilter;
      selectedValuesByProperty: SelectableValuesByProperty<DestinationEntityProperties>;
    }
  | {type: ActionType.SetEventManagementTableSortModel; sortModel: SortModel}
  | {type: ActionType.SetDestinationManagementTableSortModel; sortModel: SortModel}
  | {type: ActionType.SetDashboardManagementTableDateSortType; dateSortType: DateSortType}
  | {type: ActionType.SetDashboardManagementTableSortModel; sortModel: SortModel}
  | {type: ActionType.SetGlobalSearchFilterText; filterText: string}
  | {type: ActionType.SetGlobalSearchSelectedTags; selectedTags: ReadonlyArray<string>}
  | {type: ActionType.SetUserMenuOpen; isMenuOpen: boolean}
  | {type: ActionType.SetGlobalCreateMenuOpen; isMenuOpen: boolean}
  | {type: ActionType.SetLoginRedirectUrl; redirectUrl: string}
  | {type: ActionType.SetPlanBreadcrumb; breadcrumb: BreadcrumbItem | null}
  | {type: ActionType.SetReturnTo; returnTo: BreadcrumbItem | null}
  | {type: ActionType.SetIntegrationsPageFilterText; filterText: string}
  | {
      type: ActionType.SetIntegrationsPageCountriesFilter;
      selectedValues: ReadonlyArray<string>;
    }
  | {
      type: ActionType.SetIntegrationsPageTypesFilter;
      selectedValues: ReadonlyArray<Types.DataIntegrationType>;
    }
  | {
      type: ActionType.SetIntegrationsPageTagsFilter;
      selectedValues: ReadonlyArray<TagOption>;
    }
  | {
      type: ActionType.SetIntegrationsPageScrollPosition;
      value: number;
    }
  | {type: ActionType.SetNavigationInProgress; inProgress: boolean};

export function setTag(tagToSave: Types.Tag, previousTag: Types.Tag | null) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SaveTag,
      tagToSave,
      previousTag,
    });
  };
}

export function setUserMenuOpen(isMenuOpen: boolean): NavigationAction {
  return {
    isMenuOpen,
    type: ActionType.SetUserMenuOpen,
  };
}

export function setGlobalCreateMenuOpen(isMenuOpen: boolean): NavigationAction {
  return {
    isMenuOpen,
    type: ActionType.SetGlobalCreateMenuOpen,
  };
}

export function deleteTag(tag: Types.Tag): NavigationAction {
  return {
    type: ActionType.DeleteTag,
    tag,
  };
}

export function setDashboardManagementFilterText(filterText: string): NavigationAction {
  return {
    type: ActionType.SetDashboardManagementFilterText,
    filterText,
  };
}

function makeSinglePropertyFilter<K extends readonly string[]>(
  allProperties: K,
  selectedProperty: K[number],
  selectedValues: SelectableValues
): SelectableValuesByProperty<K[number]> {
  return allProperties.reduce(
    (acc, property) => {
      acc[property as K[number]] = property === selectedProperty ? selectedValues : ALL_SELECTED;
      return acc;
    },
    {} as {[P in K[number]]: SelectableValues}
  );
}

export function setDashboardManagementActiveFilter(
  selectedProperty: (typeof dashboardProperties)[number],
  selectedValues: SelectableValues
): NavigationAction {
  return {
    type: ActionType.SetDashboardManagementActiveFilter,
    selectedValuesByProperty: makeSinglePropertyFilter(
      dashboardProperties,
      selectedProperty,
      selectedValues
    ),
  };
}

export function setGlobalSearchFilterText(filterText: string): NavigationAction {
  return {
    type: ActionType.SetGlobalSearchFilterText,
    filterText,
  };
}

export function setGlobalSearchSelectedTags(selectedTags: ReadonlyArray<string>): NavigationAction {
  return {
    type: ActionType.SetGlobalSearchSelectedTags,
    selectedTags,
  };
}

export function setDashboardManagementTableSortModel(sortModel: SortModel): NavigationAction {
  return {
    type: ActionType.SetDashboardManagementTableSortModel,
    sortModel,
  };
}

export function setEventManagementFilterText(filterText: string): NavigationAction {
  return {
    type: ActionType.SetEventManagementFilterText,
    filterText,
  };
}

export function setEventManagementActiveFilter(
  selectedProperty: 'status' | 'type',
  selectedValues: SelectableValues
): NavigationAction {
  return {
    type: ActionType.SetEventManagementActiveFilter,
    selectedValuesByProperty: makeSinglePropertyFilter(
      ['status', 'type'],
      selectedProperty,
      selectedValues
    ),
  };
}

export function setEventManagementTableSortModel(sortModel: SortModel): NavigationAction {
  return {
    type: ActionType.SetEventManagementTableSortModel,
    sortModel,
  };
}

export function setDestinationManagementFilterText(filterText: string): NavigationAction {
  return {
    type: ActionType.SetDestinationManagementFilterText,
    filterText,
  };
}

export function setDestinationManagementActiveFilter(
  selectedProperty: DestinationEntityProperties,
  selectedValues: SelectableValues,
  selectedValuesByProperty: SelectableValuesByProperty<DestinationEntityProperties>
): NavigationAction {
  return {
    type: ActionType.SetDestinationManagementActiveFilter,
    selectedValuesByProperty: {...selectedValuesByProperty, [selectedProperty]: selectedValues},
  };
}

export function setDestinationManagementTableSortModel(sortModel: SortModel): NavigationAction {
  return {
    type: ActionType.SetDestinationManagementTableSortModel,
    sortModel,
  };
}

export function setDashboardManagementDateSortType(dateSortType: DateSortType): NavigationAction {
  return {
    dateSortType,
    type: ActionType.SetDashboardManagementTableDateSortType,
  };
}

export function setPlanBreadcrumb(breadcrumb: BreadcrumbItem | null): NavigationAction {
  return {
    breadcrumb,
    type: ActionType.SetPlanBreadcrumb,
  };
}

export function setReturnTo(returnTo: BreadcrumbItem | null): NavigationAction {
  return {
    returnTo,
    type: ActionType.SetReturnTo,
  };
}

export function setIntegrationsPageFilterText(filterText: string): NavigationAction {
  return {
    type: ActionType.SetIntegrationsPageFilterText,
    filterText,
  };
}
export function setIntegrationsPageCountriesFilter(
  values: ReadonlyArray<string>
): NavigationAction {
  return {
    type: ActionType.SetIntegrationsPageCountriesFilter,
    selectedValues: values,
  };
}
export function setIntegrationsPageTypeFilter(
  values: ReadonlyArray<Types.DataIntegrationType>
): NavigationAction {
  return {
    type: ActionType.SetIntegrationsPageTypesFilter,
    selectedValues: values,
  };
}
export function setIntegrationsPageTagsFilter(values: ReadonlyArray<TagOption>): NavigationAction {
  return {
    type: ActionType.SetIntegrationsPageTagsFilter,
    selectedValues: values,
  };
}

export function setIntegrationsPageScrollPosition(value: number): NavigationAction {
  return {
    type: ActionType.SetIntegrationsPageScrollPosition,
    value,
  };
}

export function setNavigationInProgress(inProgress: boolean): NavigationAction {
  return {
    inProgress,
    type: ActionType.SetNavigationInProgress,
  };
}

import {Set} from 'immutable';
import moment from 'moment';
import {v4 as uuid} from 'uuid';

import {CurrentUser} from 'redux/reducers/user';
import {AttributesByTypeAndName} from 'toolkit/attributes/types';
import {createAttributeInstance, getAttributeNameForGranularity} from 'toolkit/attributes/utils';
import {Option} from 'toolkit/components/LabeledOptionsSelect';
import {ComputeResultExtended} from 'toolkit/compute/types';
import {getEffectiveMetricName} from 'toolkit/metrics/utils';
import {dateGroupingGranularityOrder, getVendorEvaluationDate} from 'toolkit/time/utils';
import {createEmptyView} from 'toolkit/views/utils';
import * as Types from 'types';
import {isTruthy} from 'utils/functions';
import {getDefaultWidgetProperties, Widgets} from 'widgets/utils';

import {PlanningReportType} from './types';

export const planningReportOptions: Option<PlanningReportType>[] = [
  {label: 'Inventory Plan', value: PlanningReportType.INVENTORY},
  {label: 'Purchase Plan', value: PlanningReportType.PURCHASE},
  {label: 'Planned Purchases', value: PlanningReportType.PLANNED_PURCHASES},
];

export function shouldShowPlanningMetricData(
  metricInstance: Types.MetricInstance,
  planDemandSource: Types.PlanDemandSource | null
) {
  const metricName = getEffectiveMetricName(metricInstance);
  switch (planDemandSource) {
    case Types.PlanDemandSource.POS:
    case null:
      return (
        metricName === 'sales_units_net' ||
        metricName === 'sales_units_gross' ||
        metricName === 'inbound_shipped_units' ||
        metricName === 'forecast_sales_units_net' ||
        metricName === 'shipment_plan'
      );
    case Types.PlanDemandSource.SHIPMENT:
      return (
        metricName === 'inbound_shipped_units' ||
        metricName === 'forecast_inbound_shipped_units' ||
        metricName === 'shipment_plan'
      );
    case Types.PlanDemandSource.DIRECT_TO_CONSUMER:
      return (
        metricName === 'sales_units_net' ||
        metricName === 'sales_units_gross' ||
        metricName === 'shipment_plan'
      );
  }
}

function planningWidgetTypeToAnalysisWidgetType(planningWidgetType: Types.WidgetType) {
  switch (planningWidgetType) {
    case Types.WidgetType.PLANNING_CHART:
      return Types.WidgetType.CHART;
    case Types.WidgetType.PLANNING_TABLE:
      return Types.WidgetType.TABLE;
    default:
      throw new Error('Unexpected plan widget type');
  }
}

export function planningViewToAnalysisView(
  planVersion: Types.PlanVersion,
  planningView: Types.View,
  planningFilters: ReadonlyArray<Types.AttributeFilter>,
  currentUser: CurrentUser
): Types.View {
  return {
    ...createEmptyView(currentUser.user, currentUser.settings.analysisSettings),
    calendar: planningView.calendar,
    evaluationPeriod: planningView.evaluationPeriod,
    filters: planningFilters,
    layoutType: Types.LayoutType.VERTICAL,
    widgets: planningView.widgets
      .filter(widget => !Widgets[widget.type].isSidebar)
      .map(widget => ({
        ...widget,
        filters: [], // planning filters are already in the view and having them on the widgets makes it harder to modify the produced dashboard
        columnGroupings: [],
        rowGroupings: [
          ...(Widgets[widget.type].isTable
            ? planVersion.basePlan.attributes.map(attribute => ({
                attribute,
                graphContext: null,
              }))
            : []),
          ...widget.rowGroupings,
          ...widget.columnGroupings,
        ],
        type: planningWidgetTypeToAnalysisWidgetType(widget.type),
        isGrouped: widget.type === Types.WidgetType.PLANNING_TABLE,
      })),
  };
}

export function hasDifferentAdjustments(v1: Types.PlanVersion, v2: Types.PlanVersion): boolean {
  const v1Adjustments = Set.of(...v1.manualAdjustments);
  const v2Adjustments = Set.of(...v2.manualAdjustments);

  return !v1Adjustments.equals(v2Adjustments);
}

export function hasDifferentOverrides(v1: Types.PlanVersion, v2: Types.PlanVersion): boolean {
  const v1Overrides = Set.of(...v1.forecastOverrides);
  const v2Overrides = Set.of(...v2.forecastOverrides);

  return !v1Overrides.equals(v2Overrides);
}

export function getPlanEvaluationDate(
  planVersion: Types.PlanVersion | null | undefined,
  vendorAnalysisSettings: Types.VendorAnalysisSettings
) {
  return planVersion && planVersion.status === Types.PlanStatusType.COMPLETED
    ? moment(planVersion.basePlan.date)
    : getVendorEvaluationDate(vendorAnalysisSettings.evaluationDate);
}

export function getDemandPlanningChartWidget(
  requestedMetrics: ReadonlyArray<Types.MetricInstance>,
  dateAttributeInstance: Types.AttributeInstance
): Types.Widget {
  const chartMetrics = requestedMetrics.filter(
    instance =>
      instance.metric.name !== 'expected_on_hand_units' &&
      instance.metric.name !== 'expected_remaining_supply' &&
      instance.metric.name !== 'scheduled_inbound_shipped_units' &&
      instance.metric.name !== 'recommended_shipments' &&
      instance.metric.name !== 'metric_comps_value' // used for the last year metrics
  );
  const getSeriesType = (instance: Types.MetricInstance) =>
    instance.metric.name === 'sales_units_net' ||
    instance.metric.name === 'sales_units_gross' ||
    instance.metric.name === 'inbound_shipped_units'
      ? Types.WidgetSeriesType.BAR
      : Types.WidgetSeriesType.LINE;
  return getPlanningChartWidget(chartMetrics, dateAttributeInstance, getSeriesType);
}

export function getInventoryPlanningChartWidget(
  requestedMetrics: ReadonlyArray<Types.MetricInstance>,
  dateAttributeInstance: Types.AttributeInstance
): Types.Widget {
  const getSeriesType = (instance: Types.MetricInstance) =>
    instance.metric.name === 'expected_on_hand_units'
      ? Types.WidgetSeriesType.LINE
      : Types.WidgetSeriesType.BAR;
  return getPlanningChartWidget(requestedMetrics, dateAttributeInstance, getSeriesType);
}

// wrapped by getDemandPlanningChartWidget and getInventoryPlanningWidget for plan type specific
// implementations, so use those instead
function getPlanningChartWidget(
  requestedMetrics: ReadonlyArray<Types.MetricInstance>,
  dateAttributeInstance: Types.AttributeInstance,
  getSeriesType: (
    instance: Types.MetricInstance
  ) => Types.WidgetSeriesType.BAR | Types.WidgetSeriesType.LINE
): Types.Widget {
  return {
    ...getDefaultWidgetProperties(),
    id: 200,
    metrics: requestedMetrics.map(metricInstance => ({
      metricInstance,
      seriesType: getSeriesType(metricInstance),
    })),
    metricsSplitIndex: requestedMetrics.length,
    rowGroupings: [dateAttributeInstance],
    type: Types.WidgetType.PLANNING_CHART,
  };
}

export function getPlanningTableWidget(
  requestedMetrics: ReadonlyArray<Types.MetricInstance>,
  dateAttributeInstance: Types.AttributeInstance
): Types.Widget {
  return {
    ...getDefaultWidgetProperties(),
    id: 300,
    columnGroupings: [dateAttributeInstance],
    customName: `Planning: ${requestedMetrics[0].metric.displayName}`,
    metrics: requestedMetrics.map(metricInstance => ({metricInstance})),
    type: Types.WidgetType.PLANNING_TABLE,
  };
}

export function isDemandPlanningChartMetric(metric: Types.MetricInstance): boolean {
  return metric.arguments.forecastComposition === Types.ForecastComposition.TOTAL;
}

export function isInventoryPlanningChartMetric(metric: Types.MetricInstance): boolean {
  const metricName = getEffectiveMetricName(metric);
  return ['shipment_plan', 'planned_inbound_received_units', 'expected_on_hand_units'].includes(
    metricName ?? ''
  );
}

export function findPlanningWidgetIndex(
  widgetType: Types.WidgetType,
  planningWidgets: ReadonlyArray<Types.Widget>
): number {
  return planningWidgets.findIndex(widget => widget.type === widgetType);
}

export function getDateAttributeInstance(
  dateGranularity: Types.CalendarUnit,
  availableAttributes: AttributesByTypeAndName
) {
  const attributeName = getAttributeNameForGranularity(dateGranularity);
  const attribute = availableAttributes.get(Types.AttributeType.DATE)!.get(attributeName);
  return createAttributeInstance(attribute);
}

function dateValuesToThinComputeResultColumn(
  dateValue: Types.DateValues
): Types.ThinComputeResultColumn {
  return {
    children: [],
    metricMetadata: [new Array(dateValue.metricValues.length).fill([])],
    metricValues: dateValue.metricValues,
    value: dateValue.interval,
  };
}

export function getReportRowsToComputeResultCallback(
  metrics: ReadonlyArray<Types.MetricInstance>,
  calendar: Types.RetailCalendarEnum,
  evaluationDate: string,
  dateGrouping: Types.AttributeInstance
) {
  return (reportRow: Types.PlanReportRow): ComputeResultExtended => {
    const request: Types.ComputeRequest = {
      requestId: uuid(),
      calendar,
      devOptions: null,
      evaluationDate,
      computeEvaluationDateTime: null,
      filters: [],
      postComputeFilters: [],
      metricFilterGroupings: [],
      metricFilters: [],
      metrics,
      options: null,
      removeDoubleCounting: false,
      rowGroupings: [],
      columnGroupings: [dateGrouping],
      type: Types.ComputeRequestType.DEFAULT,
      rowLimit: null,
    };
    const result: Types.ComputeResult = {
      data: {
        children: [],
        columnData: {
          children: reportRow.values.map(dateValuesToThinComputeResultColumn),
          metricMetadata: [[]],
          metricValues: [NaN],
          value: null,
        },
        value: null,
      },
      totalRowCount: 1,
    };
    return ComputeResultExtended.fromJS(request, result);
  };
}

export function isLeafNode<T>(path: ReadonlyArray<T>, hierarchy: ReadonlyArray<Types.Attribute>) {
  return path.length === hierarchy.length;
}

export function isPlanDiffEmpty(planDiff: Types.PlanDiff | null) {
  return (
    !planDiff ||
    (planDiff.addedManualAdjustments.length === 0 &&
      planDiff.addedForecastOverrides.length === 0 &&
      planDiff.removedForecastOverrides.length === 0 &&
      planDiff.removedManualAdjustments.length === 0)
  );
}

export function getVisibilityPeriodWithStartPeriod(
  visibilityPeriod: Types.DatePeriod,
  startPeriod: Types.SimpleDatePeriod
): Types.ComplexDatePeriod {
  if (visibilityPeriod.type !== 'complex') {
    throw new Error('Plan visibility period needs to be a complex period');
  }
  return {
    ...visibilityPeriod,
    startPeriod,
  };
}

export function getIsPlanTreeHiddenLocalStorageKey(planType: Types.PlanType) {
  return `planning.${planType}.hideTree`;
}

export function alignPath(
  attributeValues: ReadonlyArray<Types.AttributeValue>,
  planHierarchy: ReadonlyArray<Types.Attribute>
): ReadonlyArray<Types.AttributeValue> {
  return planHierarchy
    .map(attribute =>
      attributeValues.find(attributeValue => attributeValue.attribute.id === attribute.id)
    )
    .filter(isTruthy);
}

const GRANULARITY_OPTIONS = [
  Types.CalendarUnit.DAYS,
  Types.CalendarUnit.WEEKS,
  Types.CalendarUnit.MONTHS,
];

export const getSupportedPlanViewingGranularities = (planGranularity: Types.CalendarUnit) =>
  GRANULARITY_OPTIONS.filter(
    // do not allow granularity options that are finer than the plan granularity
    granularity =>
      dateGroupingGranularityOrder[granularity] >= dateGroupingGranularityOrder[planGranularity]
  );

export const shouldUseGrossSales = (planSettings: Types.PlanSettings | null | undefined) => {
  if (!planSettings) {
    return false;
  }
  return (
    planSettings.forecastConfig?.metrics.some(metric => metric.name === 'sales_units_gross') ??
    false
  );
};

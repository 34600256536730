import {List, Map, Seq, Set} from 'immutable';
import moment, {Moment} from 'moment-timezone';
import pluralize from 'pluralize';
import {useCallback} from 'react';

import * as Api from 'api';
import {errorString, showAlert, showErrorAlert} from 'app/alerts';
import {CalendarEventStatus} from 'events/types';
import {createOrUpdateEvent} from 'redux/actions/analysis';
import useSelector from 'redux/selectors/useSelector';
import {store} from 'redux/store/store';
import {UNKNOWN_VALUE_ID, MULTIVALUE_ID, toThinAttributeValue} from 'toolkit/attributes/utils';
import {ComputeResultExtended} from 'toolkit/compute/types';
import {createFilter, getFilterDisplayValues} from 'toolkit/filters/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {getDefaultMetricArguments} from 'toolkit/metrics/utils';
import {DEFAULT_HISTORICAL_PERIOD} from 'toolkit/time/constants';
import {DisplayableEvent} from 'toolkit/time/types';
import {containsDate, intersects, intervalToFixedPeriod} from 'toolkit/time/utils';
import * as Types from 'types';
import {invalidate, invalidateAllMatchingRequests} from 'utils/api';
import {replaceAt, insertAt, findLastIndex, descendingBy} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isNonNullish, isTruthy} from 'utils/functions';
import {useApi, useResult} from 'utils/useApi';

export enum EventDisplayType {
  EMPTY = 'Empty',
  EVENT = 'Event',
  OVERFLOW = 'Overflow',
}

export type EventDisplay =
  | {displayType: EventDisplayType.EMPTY}
  | {displayType: EventDisplayType.EVENT; event: DisplayableEvent}
  | {displayType: EventDisplayType.OVERFLOW; events: ReadonlyArray<DisplayableEvent>};
export type EventDisplaySize = 'large' | 'small' | 'tiny';

// Note: the true type here should be Types.CalendarUnit & moment.unitOfTime.DurationConstructor,
// but casing differences prevent that from working
export type DateGranularity = Exclude<Types.CalendarUnit, Types.CalendarUnit.SEASONS>;
export type CalendarType = (Types.CalendarUnit.YEARS | Types.CalendarUnit.MONTHS) & DateGranularity;
export interface CalendarConfig {
  readonly cellGranularity: DateGranularity;
  readonly eventSize: EventDisplaySize;
  readonly formatCalendarTitle: (date: moment.Moment) => string | null;
  readonly formatCell: (interval: Types.LocalInterval, isFirstCell: boolean) => string | null;
  readonly formatHeaderCell: (interval: Types.LocalInterval) => string | null;
  readonly rowGranularity?: DateGranularity | null;
}

export function getIntervalMidDate(interval: Types.LocalInterval) {
  const start = moment(interval.start);
  const end = moment(interval.end).subtract(1, 'days');
  const difference = Math.floor(end.diff(start, 'days') / 2);
  return start.clone().add(difference, 'days');
}

export function convertToMomentGranularity(
  unit: DateGranularity
): moment.unitOfTime.DurationConstructor {
  return unit.toLowerCase() as moment.unitOfTime.DurationConstructor;
}

export function getDefaultCalendarEvent(): Omit<Types.CalendarEvent, 'interval' | 'name'> {
  return {
    baselineMetricArguments: null,
    discountAmount: null,
    discountType: null,
    expectedSalesRelativeLift: null,
    eventLevelFilters: [],
    id: null,
    fixedCosts: null,
    note: '',
    type: Types.CalendarEventType.MARKETING_ADVERTISEMENT,
    lastUpdatedAt: null,
    createdAt: null,
    lastUpdatedBy: null,
    eventAnalysis: null,
    details: [],
    target: null,
    externalSource: null,
    externalId: null,
  };
}

export function getCalendarEventDetailAttribute(
  event: Types.CalendarEvent
): Types.Attribute | null {
  return event.details.length > 0 ? event.details[0].attributeValue.attribute : null;
}

export function getAllFiltersForCalendarEvent(
  event: Types.CalendarEvent
): ReadonlyArray<Types.AttributeFilter> {
  const detailAttribute = getCalendarEventDetailAttribute(event);
  return isNonNullish(detailAttribute)
    ? [
        ...event.eventLevelFilters.filter(
          filter => filter.attributeInstance.attribute.id !== detailAttribute.id
        ),
        createFilter(
          getCalendarEventDetailAttribute(event)!,
          event.details.map(detail => detail.attributeValue),
          true
        ),
      ]
    : [...event.eventLevelFilters];
}

export function getCalendarConfig(type: CalendarType): CalendarConfig {
  switch (type) {
    case Types.CalendarUnit.YEARS:
      return {
        cellGranularity: Types.CalendarUnit.MONTHS,
        eventSize: 'large',
        formatCalendarTitle: date => date.format('YYYY'),
        formatCell: (interval, isFirstCell) => {
          const date = getIntervalMidDate(interval);
          return date.format(isFirstCell || date.month() === 0 ? 'MMM YYYY' : 'MMM');
        },
        formatHeaderCell: () => '',
      };
    case Types.CalendarUnit.MONTHS:
      return {
        cellGranularity: Types.CalendarUnit.DAYS,
        eventSize: 'small',
        formatCalendarTitle: date => date.format('MMMM YYYY'),
        formatCell: (interval, isFirstCell) => {
          const date = getIntervalMidDate(interval);
          return date.format(isFirstCell || date.date() === 1 ? 'MMM D' : 'D');
        },
        formatHeaderCell: interval => getIntervalMidDate(interval).format('ddd'),
        rowGranularity: Types.CalendarUnit.WEEKS,
      };
  }
}

export interface CalendarLayout {
  rows: ReadonlyArray<ReadonlyArray<Types.LocalInterval>>;
  config: CalendarConfig;
}

export function getCalendarLayout(
  type: CalendarType,
  intervals: {
    [key: string]: ReadonlyArray<Types.LocalInterval>;
  },
  evaluationDate: string
): CalendarLayout | null {
  const config = getCalendarConfig(type);

  const calendarSpan: Types.LocalInterval = (intervals[type] || []).find(calendarInterval =>
    containsDate(calendarInterval, evaluationDate)
  )!;
  if (!calendarSpan) {
    return null;
  }

  const rows = config.rowGranularity
    ? intervals[config.rowGranularity].filter(rowInterval => intersects(rowInterval, calendarSpan))
    : [calendarSpan];

  return {
    config,
    rows: rows.map(rowInterval =>
      intervals[config.cellGranularity].filter(cellInterval =>
        intersects(cellInterval, rowInterval)
      )
    ),
  };
}

function intervalsOverlap(
  left: Types.LocalInterval,
  right: Types.LocalInterval,
  periods: ReadonlyArray<Types.LocalInterval>
) {
  return periods.some(period => intersects(left, period) && intersects(right, period));
}

export function arrangeEvents(
  events: ReadonlyArray<DisplayableEvent>,
  periods: ReadonlyArray<Types.LocalInterval>
): ReadonlyArray<ReadonlyArray<DisplayableEvent>> {
  const sortedEvents = [...events].sort(
    descendingBy((event: DisplayableEvent) =>
      moment(event.interval.end).diff(moment(event.interval.start), 'days')
    ).thenAscendingBy(event => event.interval.start)
  );

  return sortedEvents.reduce(
    (
      arrangedEvents: ReadonlyArray<ReadonlyArray<DisplayableEvent>>,
      newEvent: DisplayableEvent
    ) => {
      const indexOfInsertableRow = arrangedEvents.findIndex(row =>
        row.every(
          arrangedEvent => !intervalsOverlap(arrangedEvent.interval, newEvent.interval, periods)
        )
      );

      if (indexOfInsertableRow > -1) {
        const insertableRow = arrangedEvents[indexOfInsertableRow];
        const newEventPlaceInRow = insertableRow.findIndex(
          arrangedEvent => arrangedEvent.interval.start >= newEvent.interval.start
        );
        const newRow = insertAt(
          insertableRow,
          newEventPlaceInRow > -1 ? newEventPlaceInRow : insertableRow.length,
          newEvent
        );
        return replaceAt(arrangedEvents, indexOfInsertableRow, newRow);
      } else {
        return [...arrangedEvents, [newEvent]];
      }
    },
    []
  );
}

interface CalendarEventTypeMetadata {
  readonly category: string;
  readonly subcategory: string | null;
  readonly shortName?: string;
  readonly priority: number;
}

function makeEventTypeMetadata(
  category: string,
  subcategory: string | null,
  priority: number,
  shortName?: string
): CalendarEventTypeMetadata {
  return {category, subcategory, shortName, priority};
}

export const CalendarEventTypeMetadata: {
  readonly [key in Types.CalendarEventType]: CalendarEventTypeMetadata;
} = {
  MARKETING_SOCIAL: makeEventTypeMetadata('Marketing', 'Social', 1000),
  MARKETING_ADVERTISEMENT: makeEventTypeMetadata('Marketing', 'Advertisement', 999, 'Ad'),
  MARKETING_TV: makeEventTypeMetadata('Marketing', 'Television', 998, 'TV'),
  MARKETING_DIRECT_MAIL: makeEventTypeMetadata('Marketing', 'Direct Mail', 997),
  MARKETING_CIRCULAR: makeEventTypeMetadata('Marketing', 'Circular', 996),
  MARKETING_EMAIL_CAMPAIGN: makeEventTypeMetadata('Marketing', 'Email campaign', 995, 'Email'),

  // Merchandising
  MERCHANDISING_IN_STORE_VISIT: makeEventTypeMetadata('Merchandising', 'In-Store Visit', 850),
  MERCHANDISING_IN_STORE_DISPLAY: makeEventTypeMetadata('Merchandising', 'In-Store Display', 849),
  MERCHANDISING_PACKAGED_BUNDLE: makeEventTypeMetadata(
    'Merchandising',
    'Packaged Bundle',
    848,
    'Bundle'
  ),

  // Price-related events
  PRICE_DISCOUNT: makeEventTypeMetadata('Price', 'Discount', 800),
  PRICE_REBATE: makeEventTypeMetadata('Price', 'Rebate', 799),
  PRICE_BUY_X_GET_Y_FREE: makeEventTypeMetadata('Price', 'Buy X, Get Y Free', 798, 'BOGO'),

  // Product introductions
  PRODUCT_INTRO_NPI: makeEventTypeMetadata('Product Intro', 'New Product', 750),
  PRODUCT_INTRO_TRANSITION: makeEventTypeMetadata('Product Intro', 'Transition Product', 749),
  PRODUCT_INTRO_END_OF_LIFE: makeEventTypeMetadata('Product Intro', 'End of Life', 748),
  PRODUCT_INTRO_LOCATION_EXPANSION: makeEventTypeMetadata(
    'Product Intro',
    'Location Expansion',
    747
  ),
  PRODUCT_INTRO_PARTNER_EXPANSION: makeEventTypeMetadata('Product Intro', 'Partner Expansion', 746),

  // External factors
  EXTERNAL_FACTORS_COMPETITIVE_NEW_PRODUCT: makeEventTypeMetadata(
    'External Factors',
    'Competitive New Product',
    600
  ),
  EXTERNAL_FACTORS_COMPETITIVE_PROMOTION: makeEventTypeMetadata(
    'External Factors',
    'Competitive Promotion',
    599,
    'Competitive Promo'
  ),
  EXTERNAL_FACTORS_SUPPLY_CHAIN_DISRUPTION: makeEventTypeMetadata(
    'External Factors',
    'Supply Chain Disruption',
    598
  ),
  EXTERNAL_FACTORS_WEATHER: makeEventTypeMetadata('External Factors', 'Weather', 597),

  //
  OTHER: makeEventTypeMetadata('Other', null, -100),
};

export const CalendarEventTypesByCategory: {
  readonly [category: string]: ReadonlyArray<Types.CalendarEventType>;
} = Object.keys(CalendarEventTypeMetadata)
  .map(type => type as Types.CalendarEventType)
  .reduce<{[category: string]: ReadonlyArray<Types.CalendarEventType>}>((acc, type) => {
    const category = CalendarEventTypeMetadata[type].category;
    const typesInCategory = acc[category] || [];

    return {
      ...acc,
      [category]: [...typesInCategory, type].sort(
        descendingBy(t => CalendarEventTypeMetadata[t].priority)
      ),
    };
  }, {});

const MAX_SHOWN_VALUES_PER_FILTER = 5;

function getFilterDisplay(filter: Types.AttributeFilter) {
  const filterDisplayValues =
    filter.values.length > MAX_SHOWN_VALUES_PER_FILTER
      ? `${filter.values.length} ${pluralize(filter.attributeInstance.attribute.name)}`
      : getFilterDisplayValues(filter).join(', ');
  return `${
    !filter.inclusive ? `${pluralizeNone(filter.values.length)} ` : ''
  }${filterDisplayValues}`;
}

export function getEventPrimaryLabel(event: Types.CalendarEvent): string {
  return event.name;
}

function getEventProductScopeLabel(event: Types.CalendarEvent) {
  const productFilters = getAllFiltersForCalendarEvent(event).filter(
    filter => filter.attributeInstance.attribute.type === Types.AttributeType.PRODUCT
  );
  return productFilters.length > 0
    ? productFilters.map(getFilterDisplay).join(' - ')
    : 'All Products';
}

function getEventLocationScopeLabel(event: Types.CalendarEvent) {
  const locationFilters = event.eventLevelFilters.filter(
    filter => filter.attributeInstance.attribute.type === Types.AttributeType.LOCATION
  );
  return locationFilters.length > 0
    ? locationFilters.map(getFilterDisplay).join(' - ')
    : 'All Locations';
}

export function getEventSecondaryLabel(event: Types.CalendarEvent): string {
  const productScope = getEventProductScopeLabel(event);
  const locationScope = getEventLocationScopeLabel(event);

  const discount = formatDiscount(event, true);

  return List.of(locationScope, productScope, discount).filter(isTruthy).join(', ');
}

export function getEventLiftDisplayText(event: Types.CalendarEvent): string | undefined {
  return event.expectedSalesRelativeLift !== null
    ? `${Math.round(event.expectedSalesRelativeLift * 100)}% expected lift`
    : undefined;
}

function formatDiscountSingle(
  discountAmount: number | null,
  discountType: Types.DiscountType | null,
  includeOffText: boolean
) {
  if (!discountAmount) {
    return '';
  }
  const offText = includeOffText ? ' off' : '';
  switch (discountType) {
    case Types.DiscountType.RELATIVE:
      return `${Format.percent(discountAmount, {fractionalDigitCount: 0})}${offText}`;
    case Types.DiscountType.ABSOLUTE:
      return `$${discountAmount}${offText} / unit`;
  }
  return '';
}

function formatDiscountMultiple(
  details: ReadonlyArray<Types.CalendarEventDetail>,
  includeOffText: boolean
) {
  const groupedDetails = Seq(details).groupBy(detail => detail.discountType);
  if (groupedDetails.count() > 1) {
    return 'mixed';
  } else {
    return groupedDetails
      .entrySeq()
      .map(([discountType, details], _key, _iter) => {
        const min = details.minBy(detail => detail.discountAmount)!.discountAmount;
        const max = details.maxBy(detail => detail.discountAmount)!.discountAmount;

        if (min === max) {
          return formatDiscountSingle(min, discountType, includeOffText);
        } else {
          const offText = includeOffText ? ' off' : '';

          switch (discountType) {
            case Types.DiscountType.RELATIVE:
              return `${Format.percent(min, {fractionalDigitCount: 0})} to ${Format.percent(max, {
                fractionalDigitCount: 0,
              })}${offText}`;
            case Types.DiscountType.ABSOLUTE:
              return `$${min} to $${max}${offText} / unit`;
          }
        }
      })
      .join('');
  }
}

export function formatDiscount(event: Types.CalendarEvent, includeOffText = false): string {
  if (event.details.length === 0) {
    return formatDiscountSingle(event.discountAmount, event.discountType, includeOffText);
  } else {
    return formatDiscountMultiple(event.details, includeOffText);
  }
}

function pluralizeNone(count: number) {
  return count > 1 ? 'None of' : 'Not';
}

export function getCompactDisplayNameForEvent(event: Types.CalendarEvent) {
  const typeDisplay = Format.calendarEventType(event.type, {
    lengthFormat: TextLengthFormat.COMPACT,
  });

  const locationFiltersDisplay = getEventSecondaryLabel(event);
  const mainDisplay = getEventPrimaryLabel(event);

  return `${mainDisplay} - ${typeDisplay}, ${locationFiltersDisplay}`;
}

export function invalidateAfterEventUpdate(event: Types.CalendarEvent | null) {
  if (event !== null) {
    invalidate(Api.Workflows.getEventAnalysisPage.getResource(event.id!));
  } else {
    invalidateAllMatchingRequests(Api.Workflows.getEventAnalysisPage.getResource(0));
  }
}

export function createOrUpdateCalendarEvent(
  event: Types.CalendarEvent
): Promise<Types.CalendarEvent> {
  return Api.CalendarEvents.saveEvent(event)
    .then(savedEvent => {
      store.dispatch(createOrUpdateEvent(event, savedEvent));
      invalidateAfterEventUpdate(savedEvent);
      showAlert(`Calendar event successfully ${event.id === null ? 'created' : 'updated'}.`);
      return savedEvent;
    })
    .catch(error => {
      showErrorAlert(
        error,
        errorString`Failed to ${event.id === null ? `create` : `update`} calendar event: ${
          error.message
        }`
      );
      throw error;
    });
}

export async function deleteCalendarEvent(event: Types.CalendarEvent) {
  await Api.CalendarEvents.deleteEvent(assertTruthy(event.id));
  invalidateAfterEventUpdate(event);
}

function getColumnAttrValuesAtLevel(
  targetLevel: number,
  currentLevel: number,
  column: Types.ThinComputeResultColumn
): ReadonlyArray<Types.ThinAttributeValue | null> {
  if (targetLevel === currentLevel) {
    return column.children.map(child => child.value);
  }
  return column.children
    .map(child => getColumnAttrValuesAtLevel(targetLevel, currentLevel + 1, child))
    .reduce((acc, child) => acc.concat(child), []);
}

export function getIntervalBucketsFromComputeResult(result: ComputeResultExtended) {
  const dateGroupingIndex = findLastIndex(
    result.columnGroupings,
    grouping => grouping.attribute.valueType === Types.AttributeValueType.interval
  );
  if (dateGroupingIndex === -1 || !result.data) {
    return List.of<Types.LocalInterval>();
  }

  return Set(
    getColumnAttrValuesAtLevel(
      dateGroupingIndex,
      0,
      result.data.columnData
    ).map<Types.LocalInterval>(value => ({
      end: value!.value.end,
      start: value!.value.start,
    }))
  )
    .toList()
    .sortBy(interval => interval.start);
}

export const getEventsByInterval = (
  intervals: List<Types.LocalInterval>,
  events: ReadonlyArray<DisplayableEvent>
) => {
  return Map(
    intervals.map<[Types.LocalInterval, ReadonlyArray<DisplayableEvent>]>(interval => [
      interval,
      events.filter(event => isEventContainedInInterval(event, interval)),
    ])
  );
};

export function isEventContainedInInterval(event: DisplayableEvent, interval: Types.LocalInterval) {
  return event.displayContinuously
    ? intersects(interval, event.interval)
    : containsDate(interval, event.interval.start);
}

export function getCalendarPeriod(
  selectedDate: moment.Moment,
  granularity: DateGranularity
): Types.FixedDatePeriod {
  const momentGranularity = convertToMomentGranularity(granularity);
  // Pad edges as we don't necessarily know the interval that will be shown in the calendar (which
  // relies on the back-end's retail calendar logic
  return {
    end: selectedDate.clone().add(1.5, momentGranularity).format(DATE_FORMAT),
    start: selectedDate.clone().subtract(1.5, momentGranularity).format(DATE_FORMAT),
    type: 'fixed',
  };
}

export function getCalendarType(type: Types.WidgetType): CalendarType | null {
  if (type === Types.WidgetType.CALENDAR) {
    return Types.CalendarUnit.MONTHS;
  } else if (type === Types.WidgetType.YEARLY_CALENDAR) {
    return Types.CalendarUnit.YEARS;
  }
  return null;
}

export function getUniqueCalendarEventKey(event: Types.CalendarEvent): string {
  return event.id === null ? 'null' : event.id.toString();
}

export function getUniqueDisplayableEventKey(event: DisplayableEvent): string {
  return `${event.interval.start}-${event.mainText}`;
}

export function calendarEventToDisplayableEvent(event: Types.CalendarEvent): DisplayableEvent {
  return {
    compactName: getCompactDisplayNameForEvent(event),
    displayContinuously: true,
    interval: event.interval,
    key: getUniqueCalendarEventKey(event),
    liftText: getEventLiftDisplayText(event),
    mainText: getEventPrimaryLabel(event),
    note: event.note,
    secondaryText: getEventSecondaryLabel(event),
    sourceCalendarEvent: event,
    type: Format.calendarEventType(event.type),
  };
}

export function splitDisplayableEvents(
  events: ReadonlyArray<DisplayableEvent>
): [ReadonlyArray<Types.CalendarEvent>, ReadonlyArray<DisplayableEvent>] {
  const calendarEvents: Types.CalendarEvent[] = [];
  const displayableEvents: DisplayableEvent[] = [];
  events.forEach(event => {
    const calendarEvent = event.sourceCalendarEvent;
    if (calendarEvent) {
      calendarEvents.push(calendarEvent);
    } else {
      displayableEvents.push(event);
    }
  });
  return [calendarEvents, displayableEvents];
}

export function defaultBaselineMetricArguments(
  interval: Types.LocalInterval
): Types.MetricArguments {
  // Note: This should match the default specified in EventAnalysisServiceImpl::DEFAULT_BASELINE_METRIC_ARGUMENTS
  return {
    ...getDefaultMetricArguments(intervalToFixedPeriod(interval)),
    forecastComposition: Types.ForecastComposition.BASELINE,
    forecastType: Types.ForecastType.HISTORICAL_AVERAGE,
    historicalPeriod: DEFAULT_HISTORICAL_PERIOD,
  };
}

export function getEventStatus(
  event: Types.CalendarEvent,
  evaluationDate: Moment
): CalendarEventStatus;
export function getEventStatus(
  event?: Types.CalendarEvent,
  evaluationDate?: Moment
): CalendarEventStatus | undefined;
export function getEventStatus(event?: Types.CalendarEvent, evaluationDate?: Moment) {
  if (!event || !evaluationDate) {
    return undefined;
  }
  if (evaluationDate.isBefore(event.interval.start)) {
    return CalendarEventStatus.UPCOMING;
  } else if (evaluationDate.isBefore(event.interval.end)) {
    return CalendarEventStatus.IN_PROGRESS;
  } else {
    return CalendarEventStatus.COMPLETED;
  }
}

export const getDiscountDisplayType = (discountType: Types.DiscountType | null) =>
  discountType === Types.DiscountType.ABSOLUTE
    ? Types.MetricDisplayType.PRICE
    : Types.MetricDisplayType.PERCENT;

export const defaultDiscountType = Types.DiscountType.RELATIVE;

export const isValidDiscount = (
  discountType: Types.DiscountType | null,
  discountAmount: number | null,
  originalPrice: number | null
) => {
  if (discountAmount === null || Number.isNaN(discountAmount)) {
    // NaN can happen if you switch the discount type without a value.
    return true;
  }
  if ((discountType ?? defaultDiscountType) === Types.DiscountType.ABSOLUTE) {
    return discountAmount >= 0 && isValidOriginalPrice(originalPrice, discountType, discountAmount);
  } else {
    return discountAmount < 1 && discountAmount >= 0;
  }
};

export const isValidOriginalPrice = (
  originalPrice: number | null,
  discountType: Types.DiscountType | null,
  discountAmount: number | null
) => {
  if (!Number.isFinite(originalPrice)) {
    return true;
  } else if (discountType !== Types.DiscountType.ABSOLUTE) {
    return originalPrice! > 0;
  } else if (!Number.isFinite(discountAmount)) {
    return true;
  } else {
    return discountAmount! <= originalPrice!;
  }
};

export const isValidExpectedLift = (expectedLift: number | null) => {
  if (expectedLift === null || Number.isNaN(expectedLift)) {
    return true;
  }
  return expectedLift > -1;
};

export function isValidEvent(event: Types.CalendarEvent) {
  return (
    !!event.name &&
    isValidDiscount(event.discountType ?? defaultDiscountType, event.discountAmount, null) &&
    isValidExpectedLift(event.expectedSalesRelativeLift) &&
    event.details.every(
      eventDetail =>
        isValidDiscount(
          eventDetail.discountType,
          eventDetail.discountAmount,
          eventDetail.originalPrice
        ) &&
        isValidOriginalPrice(
          eventDetail.originalPrice,
          eventDetail.discountType,
          eventDetail.discountAmount
        )
    )
  );
}

export function useFilteredAttributesForPlanningVendors() {
  const hasPlans = useSelector(state => !state.plans.planVersions.isEmpty());
  const planHierarchy = useSelector(state =>
    state.analysis.data.defaultAttributeHierarchies.get(
      Types.AttributeHierarchyType.DEMAND_PLAN,
      []
    )
  ).map(attribute => attribute.name);

  return useCallback(
    (attribute: Types.Attribute) => !hasPlans || planHierarchy.includes(attribute.name),
    [hasPlans, planHierarchy]
  );
}

export function useEventDetailsAttributeValues(
  eventDetailAttribute: Types.Attribute,
  event: Types.CalendarEvent
) {
  const eventDetailAttributeResource = useApi(
    Api.Attributes.getAttributeValues.getResource({
      attribute: eventDetailAttribute,
      filters: event.eventLevelFilters.filter(
        filter => filter.attributeInstance.attribute.type === Types.AttributeType.PRODUCT
      ),
    })
  );

  const eventDetailsAttributeValuesFromApi = useResult(eventDetailAttributeResource);
  const eventDetailsAttributeValuesFromApiIds = eventDetailsAttributeValuesFromApi.map(
    attributeValue => assertTruthy(attributeValue.id)
  );

  const eventDetailsAttributeValuesFromDetails = event.details.map(
    eventDetail => eventDetail.attributeValue
  );
  return eventDetailsAttributeValuesFromApi
    .filter(
      attributeValue => ![UNKNOWN_VALUE_ID, MULTIVALUE_ID].includes(assertTruthy(attributeValue.id))
    )
    .concat(
      eventDetailsAttributeValuesFromDetails
        .filter(
          attributeValue =>
            !eventDetailsAttributeValuesFromApiIds.includes(assertTruthy(attributeValue.id))
        )
        .map(toThinAttributeValue)
    );
}

import 'toolkit/components/ItemChooser.scss';

import classNames from 'classnames';
import pluralize from 'pluralize';
import React, {ReactElement, useCallback} from 'react';

import {CheckboxType} from 'toolkit/components/Checkbox';
import EmptyListPlaceholder from 'toolkit/components/EmptyListPlaceholder';
import {Subtext} from 'toolkit/components/Subtext';
import TopBarSearchInput from 'toolkit/top-bar/TopBarSearchInput';
import {DisplayVariant} from 'toolkit/utils/displayVariants';
import {isNonNullish} from 'utils/functions';

import CheckableListItem from './CheckableListItem';
import SearchBar from './SearchBar';

function SelectableItem<T>({
  itemRenderer,
  item,
  selected,
  onSelectItem,
  type = CheckboxType.CHECK,
  isDisabled,
}: SelectableItemProps<T>) {
  const handleToggle = useCallback(
    (isSelected: boolean) => onSelectItem(item, isSelected),
    [item, onSelectItem]
  );
  return (
    <CheckableListItem
      className="chooser-item"
      isChecked={selected}
      isDisabled={isDisabled}
      isShadedWhenChecked={false}
      type={type}
      value={itemRenderer(item)}
      onToggle={handleToggle}
    />
  );
}
interface SelectableItemProps<T> {
  type: CheckboxType;
  item: T;
  onSelectItem: (item: T, selected: boolean) => void;
  selected: boolean;
  isDisabled?: boolean;
  itemRenderer: (item: T) => React.ReactNode;
}

export function defaultStringer<T>(x: T): string {
  return `${x}`;
}

export function defaultSorter<T>(_a: T, _b: T): number {
  return 0;
}

export default class ItemChooser<T> extends React.PureComponent<Props<T>, State> {
  static defaultProps = {
    isSelectAllVisible: true,
    searchable: true,
    showSelectionCount: false,
    stringer: defaultStringer,
    sorter: defaultSorter,
  };
  state = {
    searchText: '',
  };

  selectItem = (item: T, selected: boolean) => {
    if (selected) {
      if (this.props.multi) {
        this.props.onSelectionChanged([...this.props.selectedValues, item]);
      } else {
        this.props.onSelectionChanged(item);
      }
    } else {
      if (this.props.multi) {
        this.props.onSelectionChanged(this.props.selectedValues.filter(x => x !== item));
      }
    }
  };

  onSelectAll = (_: unknown, selected: boolean) => {
    if (!this.props.multi) {
      return;
    }
    if (selected) {
      this.props.onSelectionChanged(this.props.possibleValues);
    } else {
      this.props.onSelectionChanged([]);
    }
  };

  handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({searchText: event.target.value});

  render() {
    const {possibleValues} = this.props;
    const selectedValues = this.props.multi
      ? this.props.selectedValues
      : [this.props.selectedValue].filter(isNonNullish);
    const displayVariant = this.props.displayVariant ?? DisplayVariant.NORMAL;
    const items: ReadonlyArray<T> = possibleValues.filter(item =>
      this.props.stringer!(item).toLowerCase().includes(this.state.searchText.toLowerCase())
    );
    const checkboxType = this.props.multi ? CheckboxType.CHECK : CheckboxType.RADIO;

    return (
      <div
        className={classNames(
          'ItemChooser',
          `variant-${displayVariant.toLowerCase()}`,
          this.props.className
        )}
      >
        {this.props.searchable &&
          (displayVariant === DisplayVariant.LIGHTER ? (
            <SearchBar
              className="item-chooser-search-bar"
              placeholder={`Search ${pluralize(this.props.itemName)}...`}
              sizeVariant="medium"
              value={this.state.searchText}
              onChange={searchText => this.setState({searchText})}
            />
          ) : (
            <TopBarSearchInput
              className="item-chooser-search-bar"
              placeholder={`Search ${pluralize(this.props.itemName)}...`}
              value={this.state.searchText}
              onChange={this.handleSearchTextChange}
            />
          ))}
        {this.props.isSelectAllVisible && (
          <SelectableItem
            item="ALL"
            itemRenderer={() => 'ALL'}
            selected={this.props.possibleValues.length === selectedValues.length}
            type={checkboxType}
            onSelectItem={this.onSelectAll}
          />
        )}
        <div className="chooser-items">
          {!items.length ? (
            <EmptyListPlaceholder
              hideIcon={this.props.hideIcon}
              noResultsText={this.props.noResultsText}
            />
          ) : (
            [...items].sort(this.props.sorter).map(item => {
              const isSelected = selectedValues.includes(item);
              return (
                <SelectableItem
                  key={this.props.stringer!(item)}
                  isDisabled={
                    !isSelected &&
                    this.props.multi &&
                    isNonNullish(this.props.maxSelectedValues) &&
                    selectedValues.length >= this.props.maxSelectedValues
                  }
                  item={item}
                  itemRenderer={
                    this.props.itemRenderer
                      ? this.props.itemRenderer
                      : () => this.props.stringer!(item)
                  }
                  selected={isSelected}
                  type={checkboxType}
                  onSelectItem={this.selectItem}
                />
              );
            })
          )}
        </div>
        {this.props.multi && this.props.showSelectionCount && (
          <Subtext className="selected-item-count">
            {this.props.selectionCounter
              ? this.props.selectionCounter(selectedValues.length)
              : `${selectedValues.length} Selected`}
          </Subtext>
        )}
      </div>
    );
  }
}
export type Props<T> = {
  className?: string;
  displayVariant?: DisplayVariant;
  hideIcon?: boolean;
  itemName: string;
  itemRenderer?: (value: T) => React.ReactNode;
  noResultsText?: string | ReactElement;
  possibleValues: ReadonlyArray<T>;
  searchable?: boolean;
  showSelectionCount?: boolean;
  selectionCounter?: (count: number) => string;
  sorter?: (valueA: T, valueB: T) => number;
  stringer?: (value: T) => string;
} & (
  | {
      isSelectAllVisible?: boolean;
      multi: true;
      maxSelectedValues?: number;
      selectedValues: ReadonlyArray<T>;
      onSelectionChanged: (selection: ReadonlyArray<T>) => void;
    }
  | {
      isSelectAllVisible: false;
      multi?: false;
      selectedValue: T | null;
      onSelectionChanged: (selection: T) => void;
    }
);
interface State {
  searchText: string;
}

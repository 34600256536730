import React from 'react';

import useSelector from 'redux/selectors/useSelector';
import {getViewId, getViewPathMatch} from 'toolkit/views/utils';
import * as Types from 'types';

export interface IDashboardContext {
  selectionFilters: ReadonlyArray<Types.AttributeFilter>;
}

export const DashboardContext = React.createContext<IDashboardContext | null>(null);

export function useRootViewId() {
  const router = useSelector(state => state.router);
  const match = getViewPathMatch(router.location.pathname);
  if (match === null) {
    return null;
  }
  return getViewId(match);
}

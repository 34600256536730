import {isNonNullish} from 'utils/functions';

// Omits `keysToRemove` from `object`
export const without = <T extends object, K extends keyof T>(
  object: T,
  keysToRemove: ReadonlyArray<K>
): Omit<T, K> => {
  return (Object.keys(object) as Array<keyof T>).reduce((acc, key) => {
    if (!keysToRemove.includes(key as any)) {
      acc[key] = object[key];
    }
    return acc;
  }, {} as T);
};

export const nonNullPropertiesOf = <T extends object>(object: T): Partial<T> => {
  const toReturn: Partial<T> = {};
  for (const k in object) {
    if (isNonNullish(object[k])) {
      toReturn[k] = object[k];
    }
  }
  return toReturn;
};

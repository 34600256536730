import * as Types from 'types';

export interface ExperimentUrlParams {
  readonly vendor: string;
  readonly experimentId?: string;
  readonly experimentSlug?: string;
}

export interface ExperimentState {
  readonly breakdownDisplayMode: ExperimentBreakdownDisplayMode;
  readonly chartDisplayMode: ExperimentChartDisplayMode;
  readonly timeDisplayMode: ExperimentTimeDisplayMode;
}

export enum ExperimentChartDisplayMode {
  RAW = 'RAW',
  NORMALIZED = 'NORMALIZED',
  LIFT = 'LIFT',
}

export enum ExperimentBreakdownDisplayMode {
  AGGREGATED = 'AGGREGATED',
  PER_BREAKDOWN = 'PER_BREAKDOWN',
}

export enum ExperimentTimeDisplayMode {
  EXPERIMENT_DURATION = 'EXPERIMENT_DURATION',
  PER_WEEK = 'PER_WEEK',
  PER_DAY = 'PER_DAY',
}

export interface ExperimentData {
  readonly id: number | null | undefined;
  readonly attribute: Types.VirtualAttribute;
  readonly groupings: ReadonlyArray<Types.AttributeInstance>;
  readonly metrics: ReadonlyArray<Types.MetricInstance>;
  readonly postPeriodStartDate: string;
  readonly postPeriodEndDate: string;
}

import {
  ColumnApi,
  ColumnState,
  Events,
  GridApi,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import {BaseColDefParams, CellClassParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {Collection, List, Map} from 'immutable';
import moment from 'moment';
import {useEffect, useState} from 'react';

import {
  MetricCellValue,
  TypedColDef,
  TypedNodeDetails,
  TypedRowNode,
  TypedTransposedNodeDetails,
  TypedValueFormatterParams,
} from 'toolkit/ag-grid/types';
import {ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {DATE_FORMAT} from 'toolkit/format/constants';
import {aggridDateComparator} from 'toolkit/time/utils';
import * as Types from 'types';
import {isNullish, sum} from 'utils/functions';
import {MIN_COLUMN_WIDTH} from 'widgets/tables/impl/columns';

export const enum AgGridColResizeMethod {
  /**
   * FIT_WIDTH will try to size all columns so that it fits within the available width of the table.
   */
  FIT_WIDTH = 'FIT_WIDTH',
  /**
   * FIT_CONTENT will try to size all columns to the width of their content. If the width of all the
   * columns after sizing to the content is less than the width of the table, the remaining width is
   * distributed to all columns.
   */
  FIT_CONTENT = 'FIT_CONTENT',
}

interface ColumnWidth {
  minWidth: number;
  width: number;
  maxWidth?: number;
  proposedWidth: number;
}

export const treeDataFromTransposedNode = (
  node: BaseColDefParams | CellClassParams
): TypedTransposedNodeDetails<ThinComputeResultRowExtended> => node.data;

export const getAttributeValueAtLevel = (row: ThinComputeResultRowExtended, level: number) =>
  row && row.values ? row.values[level] : null;

export const getMetricValueAtLevel = (
  column: Types.ThinComputeResultColumn | null | undefined,
  level: number
): MetricCellValue => {
  const metricValue = column && column.metricValues ? column.metricValues[level] : null;
  const metadata = column && column.metricMetadata ? column.metricMetadata[level] : null;
  return {value: metricValue, metadata};
};

export function getNodesToRoot<T>(node: TypedRowNode<T>) {
  let currentNode: TypedRowNode<T> | null = node; // eslint-disable-line fp/no-let
  const nodes: TypedRowNode<T>[] = [];
  while (currentNode && currentNode.level >= 0) {
    nodes.push(currentNode);
    currentNode = currentNode.parent;
  }
  return List(nodes);
}

/**
 * Call on onRowClicked if you have a grid with groups.
 */
export function toggleRowExpandedOnClick(node: TypedRowNode<unknown>, gridApi: GridApi) {
  // This expands the row only if there was a click without a text selection
  if (node.group && !window.getSelection()?.toString()) {
    gridApi.setRowNodeExpanded(node, !node.expanded);
  }
}

export function useIsExpanded(node: TypedRowNode<unknown>, gridApi: GridApi) {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    return listenForRowExpansionChange(gridApi, () => setIsExpanded(node.expanded));
  }, [node, gridApi]);
  return isExpanded;
}

export function listenForRowExpansionChange(
  gridApi: GridApi,
  onExpansionChange: () => void
): () => void {
  gridApi.addEventListener(Events.EVENT_ROW_GROUP_OPENED, onExpansionChange);
  return () => gridApi.removeEventListener(Events.EVENT_ROW_GROUP_OPENED, onExpansionChange);
}

export function processCellForExport(cell: ProcessCellForExportParams) {
  return processCellValueForExport(cell.value, cell.column.getColDef().headerName);
}

export function processCellValueForExport(cellValue: any, cellHeader: string | undefined) {
  if (cellValue === 'Unknown' || cellValue === `Unknown ${cellHeader}`) {
    return null;
  } else if (Number.isNaN(cellValue) || cellValue === 'NaN') {
    return null;
  } else if (
    typeof cellValue === 'string' &&
    (cellValue.includes(',') || cellValue.includes('"'))
  ) {
    const value = cellValue.includes('"') ? cellValue.replace(/"/g, '""') : cellValue;
    return `"${value}"`;
  }

  return cellValue;
}

function getColumnsAboveMinWidth(columns: Map<string, ColumnWidth>) {
  return columns.filter(column => column.proposedWidth > column.minWidth);
}

function getColumnsBelowMaxWidth(columns: Map<string, ColumnWidth>) {
  return columns.filter(
    column => isNullish(column.maxWidth) || column.proposedWidth < column.maxWidth
  );
}

/**
 * Returns a Map of ColumnWidths by key that tries to reduce the width of columns proportionally until
 * we hit the table width or we hit the MIN_COLUMN_WIDTH for every column. If a column happens to have
 * started with a width smaller than MIN_COLUMN_WIDTH from AgGrid's autoSizeAllColumns, then we keep the
 * smaller size.
 */
function reduceColumnsToFitWidth(
  columns: Map<string, ColumnWidth>,
  proposedWidthForAllColumns: number,
  tableWidth: number
): Map<string, ColumnWidth> {
  /* eslint-disable fp/no-let */
  let proposedColumns = columns;
  let columnsAboveMinWidth = getColumnsAboveMinWidth(proposedColumns);
  let amountToReduce = proposedWidthForAllColumns - tableWidth;
  /* eslint-enable fp/no-let */

  while (!columnsAboveMinWidth.isEmpty() && amountToReduce > 0) {
    const amountToReduceEachColumn = Math.ceil(amountToReduce / columnsAboveMinWidth.size);

    for (const [colId, column] of columnsAboveMinWidth) {
      const widthToMinDifference = column.proposedWidth - column.minWidth;
      if (widthToMinDifference > 0) {
        const adjustment = Math.min(widthToMinDifference, amountToReduceEachColumn);
        proposedColumns = proposedColumns.set(colId, {
          ...column,
          proposedWidth: column.proposedWidth - adjustment,
        });
        amountToReduce -= adjustment;
      }
    }

    columnsAboveMinWidth = getColumnsAboveMinWidth(proposedColumns);
  }

  return proposedColumns;
}

/**
 * Returns a Map of ColumnWidths by key that expands the width of each column proportionally until
 * we hit the table width.
 */
function expandColumnsToFillWidth(
  columns: Map<string, ColumnWidth>,
  proposedWidthForAllColumns: number,
  tableWidth: number
): Map<string, ColumnWidth> {
  /* eslint-disable fp/no-let */
  let proposedColumns = columns;
  let columnsBelowMaxWidth = getColumnsBelowMaxWidth(proposedColumns);
  let amountToExpand = tableWidth - proposedWidthForAllColumns;
  /* eslint-enable fp/no-let */

  // Since we take the floor of amountToExpand / # of adjustable columns, stop adjust the columns
  // once amountToExpand / # of adjustable columns < 1
  while (!columnsBelowMaxWidth.isEmpty() && amountToExpand > columnsBelowMaxWidth.size) {
    const amountToExpandEachColumn = Math.floor(amountToExpand / columnsBelowMaxWidth.size);

    for (const [colId, column] of columnsBelowMaxWidth) {
      const maxToWidthDifference = (column.maxWidth ?? 0) - column.proposedWidth;
      if (isNullish(column.maxWidth)) {
        proposedColumns = proposedColumns.set(colId, {
          ...column,
          proposedWidth: column.proposedWidth + amountToExpandEachColumn,
        });
        amountToExpand -= amountToExpandEachColumn;
      } else if (maxToWidthDifference > 0) {
        const adjustment = Math.min(maxToWidthDifference, amountToExpandEachColumn);
        proposedColumns = proposedColumns.set(colId, {
          ...column,
          proposedWidth: column.proposedWidth + adjustment,
        });
        amountToExpand -= adjustment;
      }
    }

    columnsBelowMaxWidth = getColumnsBelowMaxWidth(proposedColumns);
  }

  return proposedColumns;
}

function getNewColumnWidths(
  columns: ReadonlyArray<ColumnState>,
  tableWidth: number,
  gridApi: GridApi
): Map<string, ColumnWidth> {
  const proposedColumns = Map(
    columns.map(column => {
      const columnDef = gridApi.getColumnDef(column.colId);
      return [
        column.colId,
        {
          minWidth: columnDef.minWidth ?? MIN_COLUMN_WIDTH,
          width: column.width ?? columnDef.width ?? 0,
          maxWidth: columnDef.maxWidth,
          proposedWidth: column.width ?? 0,
        },
      ];
    })
  );
  const proposedWidthForAllColumns = proposedColumns
    .map(({proposedWidth}) => proposedWidth)
    .reduce(sum, 0);
  if (proposedWidthForAllColumns > tableWidth) {
    return reduceColumnsToFitWidth(proposedColumns, proposedWidthForAllColumns, tableWidth);
  } else if (proposedWidthForAllColumns < tableWidth) {
    return expandColumnsToFillWidth(proposedColumns, proposedWidthForAllColumns, tableWidth);
  } else {
    return Map();
  }
}

export function resizeAgGridColumns(
  gridApi: GridApi,
  columnApi: ColumnApi,
  wrapperElement: Element | null | undefined,
  resizeMethod: AgGridColResizeMethod
) {
  if (resizeMethod === AgGridColResizeMethod.FIT_WIDTH || !wrapperElement) {
    gridApi.sizeColumnsToFit();
  } else {
    columnApi.autoSizeAllColumns(true);
    const fullWidth = wrapperElement.scrollWidth;

    const gridViewPort = wrapperElement.querySelector('.ag-body-viewport');
    const scrollBarWidth =
      gridViewPort && gridViewPort.scrollHeight > gridViewPort.clientHeight ? 15 : 0;

    const allColumns = columnApi.getColumnState();
    const adjustableColumns = allColumns.filter(column => {
      const colDef = gridApi.getColumnDef(column.colId);
      return !colDef.hide;
    });
    const adjustableColumnIds = adjustableColumns.map(column => column.colId);
    const nonAdjustableColumnsWidth = allColumns
      .map(column => {
        const colDef = gridApi.getColumnDef(column.colId);
        return !adjustableColumnIds.includes(column.colId) && !colDef.hide
          ? (column.width ?? 0)
          : 0;
      })
      .reduce(sum, 0);

    const tableWidth = fullWidth - scrollBarWidth - nonAdjustableColumnsWidth;

    const newColumnWidths = getNewColumnWidths(adjustableColumns, tableWidth, gridApi);
    if (!newColumnWidths.isEmpty()) {
      columnApi.setColumnWidths(
        newColumnWidths
          .map((widths, key) => ({key, newWidth: widths.proposedWidth}))
          .valueSeq()
          .toArray()
      );
    }
  }
}

/**
 * Create this object to automatically resize the grid as the containing element's size changes.
 */
export class AgGridResizer {
  private readonly gridApi: GridApi;
  private readonly columnApi: ColumnApi;
  private readonly wrapperElement: Element;
  private readonly resizeMethod: AgGridColResizeMethod;

  private resizeObserver: ResizeObserver | null = null;

  constructor(
    gridApi: GridApi,
    columnApi: ColumnApi,
    wrapperElement: Element,
    resizeMethod: AgGridColResizeMethod
  ) {
    this.gridApi = gridApi;
    this.columnApi = columnApi;
    this.wrapperElement = wrapperElement;
    this.resizeMethod = resizeMethod;
  }

  start() {
    // ag-Grid doesn't resize automatically when used inside a flexbox layout, so we manually
    // respond to resize events. The resizeTimer variable is used to ensure we only resize
    // the table once, when the user is finished resizing the window. We also resize the table when
    // the grid first renders, after a small delay (https://github.com/ceolter/ag-grid/issues/269)
    const resizeTable = () =>
      resizeAgGridColumns(this.gridApi, this.columnApi, this.wrapperElement, this.resizeMethod);

    let resizeTimer: number | null = null; // eslint-disable-line fp/no-let
    let shouldIgnoreInitialEvent = !!window.ResizeObserver; // eslint-disable-line fp/no-let
    const resizeListener = () => {
      if (shouldIgnoreInitialEvent) {
        shouldIgnoreInitialEvent = false;
        return;
      }
      if (resizeTimer !== null) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = window.setTimeout(resizeTable, 250);
    };

    this.resizeObserver = new ResizeObserver(resizeListener);
    this.resizeObserver.observe(this.wrapperElement);

    return this;
  }

  stop() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }
}

/**
 * Groups rows for usage in ag-grid tables
 *
 * @param items list of raw data instances
 * @param groupingFunction function such that items with the same return value are grouped together
 * @param topRowFunction function such that given a list of rows, computes the row that represents their parent
 */
export function getRowGroups<T>(
  items: List<T>,
  groupingFunction: (item: T) => unknown,
  topRowFunction: (value: Collection<number, T>) => T
): ReadonlyArray<TypedNodeDetails<T>> {
  return items
    .groupBy(groupingFunction)
    .valueSeq()
    .map(group => {
      const isGrouped = group.count() !== 1;
      const data = topRowFunction(group);
      return {
        children: !isGrouped
          ? []
          : group
              .valueSeq()
              .map(item => {
                return {
                  children: [],
                  data: item,
                  group: false,
                  level: 1,
                };
              })
              .toArray(),
        data,
        group: isGrouped,
        level: 0,
      };
    })
    .toArray();
}

export function comparator(
  nameA: string,
  nameB: string,
  _nodeA: any,
  _nodeB: any,
  _isInverted: boolean
): number {
  if (nameA === nameB) return 0;
  // if null we show last
  if (!nameA) return 1;
  if (!nameB) return -1;
  return nameA.toLocaleLowerCase() > nameB.toLocaleLowerCase() ? 1 : -1;
}

export function exportAsCsv(
  gridApi: GridApi,
  colDefs: ReadonlyArray<TypedColDef<any>>,
  exportName: string | {readonly modelName: string}
) {
  const params = {
    columnKeys: colDefs.map(colDef => colDef.colId!),
    fileName:
      typeof exportName === 'string'
        ? exportName
        : `${exportName.modelName.toLocaleLowerCase()}-${moment().format(DATE_FORMAT)}.csv`,
  };
  gridApi.exportDataAsCsv(params);
}

export const defaultTextColumnFilterParams = {
  suppressAndOrCondition: true,
};

export const defaultDateColumnFilterParams = {
  filterOptions: ['equals', 'inRange', 'lessThanOrEqual', 'greaterThanOrEqual'],
  suppressAndOrCondition: true,
  comparator: aggridDateComparator,
  // ag-grid inRange filter is exclusive by default
  inRangeInclusive: true,
};

/** Returns the value as a string. Makes the assumption that the value getter returns a number, boolean or string. */
export const defaultValueFormatter = ({value}: TypedValueFormatterParams<unknown>) => {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return value.toString();
  } else {
    return value as string;
  }
};

import {push} from 'connected-react-router';

import * as Api from 'api';
import {showErrorAlert} from 'app/alerts';
import ActionType from 'redux/actions/types';
import {Dispatch, GetState} from 'redux/reducers';
import {
  ExperimentBreakdownDisplayMode,
  ExperimentTimeDisplayMode,
  ExperimentChartDisplayMode,
} from 'toolkit/groups/types';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

import {DeleteTagAction, SaveTagAction} from './navigation';

export type GroupsAction =
  | {
      type: ActionType.SetGroups;
      data?: ReadonlyArray<Types.Group>;
      promise: Promise<any>;
    }
  | {
      type: ActionType.SetExperiments;
      data?: ReadonlyArray<Types.Experiment>;
      promise: Promise<any>;
    }
  | {
      type: ActionType.AddGroup;
      group: Types.Group;
    }
  | {
      type: ActionType.SetExperiment;
      experiment: Types.Experiment;
    }
  | {
      type: ActionType.DeleteExperiment;
      experimentId: number;
    }
  | {
      type: ActionType.SetExperimentVisitedTimestampsForUser;
      timestamps: ReadonlyArray<Types.VisitedTimestamp>;
    }
  | {
      type: ActionType.SetExperimentVisitedTimestampsForVendor;
      timestamps: ReadonlyArray<Types.VisitedTimestamp>;
    }
  | {type: ActionType.SetExperimentVisitedTime; time: string; experimentId: number}
  | {
      type: ActionType.SetFavoriteExperimentIds;
      data?: ReadonlyArray<number>;
      promise: Promise<ReadonlyArray<number>>;
    }
  | {
      type: ActionType.SetExperimentFavoriteStatus;
      experimentId: number;
      isFavorite: boolean;
      promise: Promise<void>;
    }
  | SaveTagAction
  | DeleteTagAction
  | {
      type: ActionType.SetExperimentBreakdownDisplayMode;
      experimentId: number | null;
      displayMode: ExperimentBreakdownDisplayMode;
    }
  | {
      type: ActionType.SetExperimentChartDisplayMode;
      experimentId: number | null;
      displayMode: ExperimentChartDisplayMode;
    }
  | {
      type: ActionType.SetExperimentTimeDisplayMode;
      experimentId: number | null;
      displayMode: ExperimentTimeDisplayMode;
    }
  | {experiment: Types.Experiment | null; type: ActionType.SetSelectedExperiment};

export function fetchGroups(): GroupsAction {
  return {
    promise: Api.Groups.getGroups(),
    type: ActionType.SetGroups,
  };
}

export function fetchExperiments(): GroupsAction {
  return {
    promise: Api.Experiments.getExperiments(),
    type: ActionType.SetExperiments,
  };
}

export function addGroup(group: Types.Group): GroupsAction {
  return {
    group,
    type: ActionType.AddGroup,
  };
}

export function setExperiment(experiment: Types.Experiment): GroupsAction {
  return {
    experiment,
    type: ActionType.SetExperiment,
  };
}

export function deleteExperiment(experimentId: number) {
  return (dispatch: Dispatch) => {
    Api.Experiments.deleteExperiment(experimentId)
      .then(() =>
        dispatch({
          type: ActionType.DeleteExperiment,
          experimentId,
        })
      )
      .catch(showErrorAlert);
  };
}

export function setVisitedExperimentTimestampsForUser(
  timestamps: ReadonlyArray<Types.VisitedTimestamp>
): GroupsAction {
  return {
    timestamps,
    type: ActionType.SetExperimentVisitedTimestampsForUser,
  };
}

export function setVisitedExperimentTimestampsForVendor(
  timestamps: ReadonlyArray<Types.VisitedTimestamp>
): GroupsAction {
  return {
    timestamps,
    type: ActionType.SetExperimentVisitedTimestampsForVendor,
  };
}

export function updateExperimentVisitedTime(experimentId: number) {
  return (dispatch: Dispatch) => {
    Api.Experiments.setVisitedTime(experimentId).then(time => {
      dispatch({
        experimentId,
        time,
        type: ActionType.SetExperimentVisitedTime,
      });
    });
  };
}

export function setExperiments(experiments: ReadonlyArray<Types.Experiment>): GroupsAction {
  return {
    promise: Promise.resolve(experiments),
    type: ActionType.SetExperiments,
  };
}

export function getFavoriteExperiments(): GroupsAction {
  return {
    promise: Api.Experiments.getFavoriteExperimentIds(),
    type: ActionType.SetFavoriteExperimentIds,
  };
}

export function setExperimentFavoriteStatus(
  experiment: Types.Experiment,
  isFavorite: boolean
): GroupsAction {
  const experimentId = assertTruthy(experiment.id);
  return {
    isFavorite,
    promise: isFavorite
      ? Api.Experiments.setExperimentAsFavorite(experimentId)
      : Api.Experiments.removeExperimentFromFavorites(experimentId),
    type: ActionType.SetExperimentFavoriteStatus,
    experimentId,
  };
}

export function setExperimentChartDisplayMode(
  experimentId: number | null,
  displayMode: ExperimentChartDisplayMode
): GroupsAction {
  return {
    type: ActionType.SetExperimentChartDisplayMode,
    experimentId,
    displayMode,
  };
}

export function setExperimentTimeDisplayMode(
  experimentId: number | null,
  displayMode: ExperimentTimeDisplayMode
): GroupsAction {
  return {
    type: ActionType.SetExperimentTimeDisplayMode,
    experimentId,
    displayMode,
  };
}

export function setExperimentBreakdownDisplayMode(
  experimentId: number | null,
  displayMode: ExperimentBreakdownDisplayMode
): GroupsAction {
  return {
    type: ActionType.SetExperimentBreakdownDisplayMode,
    experimentId,
    displayMode,
  };
}

export function saveCurrentExperiment(experiment: Types.Experiment) {
  return (dispatch: Dispatch, getState: GetState) => {
    return Api.Experiments.saveExperiment(experiment).then(savedExperiment => {
      dispatch(setExperiment(savedExperiment));
      dispatch(setSelectedExperiment(savedExperiment));

      const {id, slug} = savedExperiment;
      dispatch(push(`/${getState().user.vendor!.name}/experiments/${id}/${slug}`));
    });
  };
}

export function setSelectedExperiment(experiment: Types.Experiment | null): GroupsAction {
  return {
    experiment,
    type: ActionType.SetSelectedExperiment,
  };
}

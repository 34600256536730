import './SearchableDropdownList.scss';

import classNames from 'classnames';
import {is as isEqual} from 'immutable';
import pluralize from 'pluralize';
import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import EmptyListPlaceholder from 'toolkit/components/EmptyListPlaceholder';
import SearchBar from 'toolkit/components/SearchBar';
import {handleSearchInputKeyNavigation, swallowEvents} from 'toolkit/utils/events';

import {AutoFixedHeightList} from './react-window';

function SearchableDropdownList<T>({
  className,
  initialFilterText,
  listClassName,
  searchable,
  onSelect,
  optionKey,
  render,
  resourceName,
  search,
  value,
  virtualized,
  width,
  noResultsText,
  createOption,
}: SearchableDropdownListProps<T>) {
  const [filterText, setFilterText] = useState(initialFilterText ?? '');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const optionListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, [inputRef]);

  const filteredOptions = useMemo(() => {
    return search(filterText.trim());
  }, [filterText, search]);

  const updateSearch = useCallback(
    (search: string) => {
      setFilterText(search);
      if (optionListRef.current) {
        optionListRef.current.scrollTo({top: 0});
      }
    },
    [setFilterText, optionListRef]
  );
  const onClick = useCallback(
    (option: T, event: React.MouseEvent<HTMLElement>) => {
      swallowEvents(event);
      onSelect(option, event);
    },
    [onSelect]
  );
  const focusSearch = useCallback(() => inputRef.current?.focus(), [inputRef]);

  const renderOption = (option: T, key: string) => {
    return render({
      key,
      className: 'searchable-dropdown-list-item select-value',
      selected: isEqual(value, option),
      option,
      onClick,
      onFocusSearch: focusSearch,
    });
  };

  // requires: nonempty filteredOptions
  const renderVirtualizedItems = () => (
    <AutoFixedHeightList<T>
      getItem={index => filteredOptions[index ?? 0]}
      getKey={(option, index) => `${index}-${optionKey(option)}`}
      itemCount={filteredOptions.length}
      renderItem={({item, key}) => renderOption(item, key)}
    />
  );

  const renderUnvirtualizedItems = () =>
    filteredOptions.map((option, index) => renderOption(option, `${index}-${optionKey(option)}`));

  return (
    <div
      className={classNames('SearchableDropdownList', className)}
      style={width !== undefined ? {width} : undefined}
    >
      {searchable && (
        <SearchBar
          ref={inputRef}
          debounceDelay={100}
          placeholder={`Search ${resourceName ? pluralize(resourceName) : ''}…`}
          value={filterText}
          onChange={updateSearch}
          onClear={focusSearch}
          onKeyDown={event =>
            handleSearchInputKeyNavigation(
              event,
              optionListRef.current,
              'searchable-dropdown-list-item'
            )
          }
        />
      )}
      {createOption}
      {filteredOptions.length === 0 ? (
        <EmptyListPlaceholder className="no-items-placeholder" noResultsText={noResultsText} />
      ) : (
        <div ref={optionListRef} className={classNames('list-options', listClassName)}>
          {virtualized ? renderVirtualizedItems() : renderUnvirtualizedItems()}
        </div>
      )}
    </div>
  );
}

SearchableDropdownList.displayName = 'SearchableDropdownList';

export interface OptionProps<T> {
  className: string;
  key: React.Key;
  selected: boolean;
  option: T;
  onClick: (option: T, event: React.MouseEvent<HTMLElement>) => void;
  onFocusSearch: () => void;
}

interface SearchableDropdownListProps<T> {
  className?: string;
  initialFilterText?: string;
  listClassName: string;
  searchable?: boolean;
  onSelect: (option: T, event: React.UIEvent<HTMLElement>) => void;
  optionKey: (option: T) => string;
  render: (props: OptionProps<T>) => React.ReactNode;
  search: (filter: string) => ReadonlyArray<T>;
  resourceName?: string;
  value?: T | ReadonlyArray<T>;
  virtualized?: boolean;
  width?: number;
  noResultsText?: string | ReactElement;
  createOption?: React.ReactNode;
}

export default SearchableDropdownList;

import {push, replace} from 'connected-react-router';
import {Location} from 'history';
import {List, Map, Set} from 'immutable';
import moment from 'moment-timezone';

import * as Api from 'api';
import {errorString, showAlert, showErrorAlert} from 'app/alerts';
import {PresentationFile} from 'app/presentation/types';
import {VendorLocationChangeAction} from 'app/router-types';
import {CurrentUserActions} from 'redux/actions/user';
import {UserDataActions} from 'redux/actions/user-data';
import {getCurrentUserFromState} from 'redux/context/user';
import {Dispatch, GetState} from 'redux/reducers';
import {CurrentUser} from 'redux/reducers/user';
import {mainView, getAvailableViews} from 'redux/selectors/analysis';
import {Settings} from 'settings/utils';
import {MetricsByName} from 'toolkit/metrics/types';
import {deleteCalendarEvent} from 'toolkit/time/calendar-utils';
import {PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {createDefaultWidgetData, ViewState, ViewUrlParams} from 'toolkit/views/types';
import {getIntelligenceManagementPageUrl, isViewPrivate} from 'toolkit/views/utils';
import {getViewLink} from 'toolkit/views/view-urls';
import * as Types from 'types';
import {allEnumValues} from 'types/utils';
import {invalidate} from 'utils/api';
import {assertTruthy} from 'utils/assert';
import {ContainerWidgetType, WidgetLayoutData} from 'widgets/types';
import {
  createDefaultContainerWidget,
  getDefaultWidgetProperties,
  MAX_WIDGETS_PER_DASHBOARD,
  Widgets,
  widgetViolatesWidgetLimit,
} from 'widgets/utils';
import {fetchWidgetData} from 'widgets/widget-data';
import {getParentWidgetIndex, getWidgetTreeWithoutData} from 'widgets/widget-layout';

import {DeleteTagAction, SaveTagAction} from './navigation';
import ActionType from './types';

export type DeleteViewAction = {type: ActionType.DeleteView; viewId: number};
export type DeleteEventAction = {type: ActionType.DeleteEvent; eventId: number};
export type CreateEventAction = {
  type: ActionType.CreateEvent;
  event: Types.CalendarEvent;
};
export type UpdateEventAction = {
  type: ActionType.UpdateEvent;
  event: Types.CalendarEvent;
};

export type CalendarEventAction =
  | {
      type: ActionType.RefreshCalendarEventWidgets;
    }
  | {
      type: ActionType.SetAvailableCalendarEvents;
      data?: ReadonlyArray<Types.CalendarEvent>;
      promise: Promise<ReadonlyArray<Types.CalendarEvent>>;
    };

export type SetViewsAction = {
  type: ActionType.SetViews;
  location: Location;
  newViews: List<ViewState> | null;
};

export type AnalysisAction =
  | {type: ActionType.ClearUnsavedView}
  | {
      type: ActionType.ClearViewChanges;
      analysisSettings: Types.VendorAnalysisSettings;
    }
  | {
      type: ActionType.SetWidgetData;
      dataKey: Types.Widget;
      timestamp: moment.Moment;
      viewIndex: number;
      requestId: string;
      [key: string]: any;
    }
  | DeleteViewAction
  | DeleteEventAction
  | CreateEventAction
  | UpdateEventAction
  | {type: ActionType.ClearSelectionFilters; forWidgetIndex?: number}
  | {
      type: ActionType.ReplaceCurrentViews;
      analysisSettings: Types.VendorAnalysisSettings;
      views: List<Types.View>;
      viewParams?: List<ViewUrlParams | null> | null;
    }
  | CalendarEventAction
  | {
      type: ActionType.SetAvailableForecastsByMetricName;
      data?: {[forecastedMetricName: string]: ReadonlyArray<Types.ForecastType>};
      promise: Promise<any>;
    }
  | {type: ActionType.SetDefaultEvaluationPeriod; period: Types.DatePeriod}
  | {
      settings: Settings;
      availableMetrics: MetricsByName;
      type: ActionType.SetUnitConversionAttribute;
      unitConversionAttribute: Types.Attribute | null;
      currentUser: CurrentUser;
    }
  | {type: ActionType.SetEvaluationDate; date: moment.Moment}
  | {type: ActionType.SetComputeEvaluationDateTime; dateTime: moment.Moment | null}
  | {
      type: ActionType.SetGlobalFilters;
      filters: ReadonlyArray<Types.AttributeFilter>;
      partners: Map<number, Types.Partner>;
      vendorCalendar: Types.RetailCalendarEnum;
      isCalendarAutoSwitchingEnabled: boolean;
    }
  | {
      type: ActionType.SetSelectionFiltersForWidget;
      widgetIndex: number;
      filters: ReadonlyArray<Types.AttributeFilter>;
    }
  | {type: ActionType.SetSelectedCalendarDate; widgetIndex: number; date: moment.Moment}
  | {
      type: ActionType.SetAvailableThinViews;
      data?: List<Types.ThinView>;
      promise: Promise<List<Types.ThinView>> | null;
    }
  | {
      type: ActionType.AddVendorViews;
      vendorViews: List<Types.View>;
    }
  | {
      type: ActionType.ClearVendorViews;
    }
  | {type: ActionType.SetPeriodChooserOpen; isChooserOpen: boolean}
  | {type: ActionType.SetViewMenuOpen; isMenuOpen: boolean}
  | {type: ActionType.SelectWidget; widgetIndex: number | null}
  | {type: ActionType.SetExpandedWidget; widgetIndex: number | null}
  | {type: ActionType.SetFavoriteViews; views: List<Types.ThinView>}
  | {type: ActionType.SetViewFavoriteStatus; viewId: number; isFavorite: boolean}
  | {type: ActionType.SetEditingLayout; isEditingLayout: boolean}
  | {type: ActionType.SetEditingWidget; widgetIndex: number | null}
  | {type: ActionType.SetEditingWidgetSubscription; widgetIndex: number | null}
  | {type: ActionType.SetOriginalWidgets; widgets: ReadonlyArray<Types.Widget>}
  | {type: ActionType.SetProposedWidgets; widgets: ReadonlyArray<Types.Widget>}
  | {type: ActionType.SetViewWidgetFilesIndex; widgetIndex: number | null}
  | {type: ActionType.AddWidgetToEnd; widgetConfig: Types.Widget}
  | {type: ActionType.CopyWidget; fromTreeIndex: number; toTreeIndex: number}
  | {type: ActionType.DeleteWidget; widgetIndex: number}
  | {type: ActionType.MoveWidget; fromTreeIndex: number; toTreeIndex: number}
  | {
      type: ActionType.MoveWidgetChild;
      widgetTreeIndex: number;
      fromChildIndex: number;
      toChildIndex: number;
    }
  | {type: ActionType.SetProposedWidgetTreeItem; treeIndex: number; data: WidgetLayoutData}
  | {
      type: ActionType.SetWidgets;
      widgets: ReadonlyArray<Types.Widget>;
      calendar?: Types.RetailCalendarEnum;
      forceRefreshAllWidgets?: boolean;
    }
  | {
      type: ActionType.SetWidgetConfig;
      index: number;
      config: Types.Widget;
    }
  | {
      type: ActionType.SetWidgetConfigWithoutUpdate;
      index: number;
      partialConfig: Partial<Types.Widget>;
    }
  | {
      type: ActionType.SetAllGroupings;
      data?: Map<number, Types.Attribute>;
      promise: Promise<Map<number, Types.Attribute>>;
    }
  | {
      attributes: ReadonlyArray<Types.Attribute>;
      hierarchyType: Types.AttributeHierarchyType;
      type: ActionType.SetDefaultHierarchy;
    }
  | SaveTagAction
  | DeleteTagAction
  | {experiment: Types.Experiment; type: ActionType.SetSelectedExperiment}
  | {
      type: ActionType.SetAvailableMetrics;
      data?: Types.MetricResponse;
      promise: Promise<Types.MetricResponse>;
    }
  | {
      type: ActionType.SetAvailableMetricDescriptions;
      data: ReadonlyArray<Types.MetricDescription>;
    }
  | {type: ActionType.SetPartners; data?: ReadonlyArray<Types.Partner>; promise: Promise<any>}
  | {type: ActionType.CreateOrUpdateViews; views: List<Types.View>}
  | {type: ActionType.CreateOrUpdateView; view: Types.View; isNewView: boolean}
  | {type: ActionType.SetViewLinks; viewLinks: ReadonlyArray<Types.ViewLink>}
  | {type: ActionType.SetUserRole; role: Types.Role}
  | {type: ActionType.SetPresentationFile; file: PresentationFile}
  | {
      type: ActionType.SetDashboardCurrency;
      currency: Types.CurrencyCode;
    }
  | {
      type: ActionType.SetDoubleCountingPartners;
      doubleCountingPartners: ReadonlyArray<Types.DoubleCountingPartner>;
    }
  | VendorLocationChangeAction
  | SetViewsAction
  | {
      type: ActionType.SetEdgeDataRanges;
      promise: Promise<ReadonlyArray<Types.EdgeDataRange>>;
      data?: ReadonlyArray<Types.EdgeDataRange>;
    };

export interface IAnalysisDataActions {
  fetchEdgeDataRanges: () => any;
  fetchAllGroupings: () => any;
  fetchAvailableForecasts: () => any;
  fetchAvailableMetricDescriptions: () => any;
  updateAvailableMetricDescriptions: (descriptions: List<Types.MetricDescription>) => any;
  fetchAvailableMetrics: () => AnalysisAction;
  clearVendorViews: () => any;
  fetchAllThinViews: () => any;
  fetchAllCalendarEvents: () => any;
  fetchPartners: () => AnalysisAction;
  addVendorViews: (views: List<Types.View>) => AnalysisAction;
  setAvailableThinViews: (views: List<Types.ThinView>) => AnalysisAction;
  setViewFavoriteStatus: (view: Types.ThinView, isFavorite: boolean) => any;
  setViewLinks: (viewLinks: ReadonlyArray<Types.ViewLink>) => any;
  setDoubleCountingPartners: (
    doubleCountingPartners: ReadonlyArray<Types.DoubleCountingPartner>
  ) => any;

  setView: (view: Types.View, isNewView?: boolean) => AnalysisAction;
  storeViews: (views: List<Types.View>) => AnalysisAction;
}

export const AnalysisDataActions: IAnalysisDataActions = {
  fetchAllGroupings: () => {
    return (dispatch: Dispatch) => {
      dispatch({
        promise: Api.Attributes.getAllPopulatedAttributes().then(allAttributes => {
          return Map<number, Types.Attribute>(
            allAttributes.map<[number, Types.Attribute]>((attribute: Types.Attribute) => [
              assertTruthy(attribute.id),
              attribute,
            ])
          );
        }),
        type: ActionType.SetAllGroupings,
      });
      const promises = allEnumValues(Types.AttributeHierarchyType).map(type =>
        Api.Attributes.getDefaultAttributes(type).then(attributes => ({
          attributes,
          type,
        }))
      );
      Promise.all(promises)
        .then(defaults => {
          defaults.forEach(attributesAndType => {
            dispatch({
              attributes: attributesAndType.attributes,
              hierarchyType: attributesAndType.type,
              type: ActionType.SetDefaultHierarchy,
            });
          });
        })
        .catch(showErrorAlert);
    };
  },

  fetchAllThinViews: () => {
    return (dispatch: Dispatch, getState: GetState) => {
      if (getState().user.vendor!.id === PUBLIC_VENDOR_ID) {
        Api.Views.getThinViews().then(data =>
          dispatch(AnalysisDataActions.setAvailableThinViews(List(data)))
        );
      } else {
        dispatch({
          type: ActionType.SetAvailableThinViews,
          promise: Api.Views.getThinViews().then(vendorViews => List(vendorViews)),
        });
      }
    };
  },
  clearVendorViews: () => {
    return {
      type: ActionType.ClearVendorViews,
    };
  },
  fetchAllCalendarEvents: () => {
    return {
      promise: Api.CalendarEvents.getAllEvents(),
      type: ActionType.SetAvailableCalendarEvents,
    };
  },

  fetchEdgeDataRanges: () => {
    return {
      promise: Api.DataStatus.getOriginalEdgeDataRanges(),
      type: ActionType.SetEdgeDataRanges,
    };
  },

  fetchAvailableForecasts: () => {
    return {
      promise: Api.Metrics.getAvailableForecastsByForecastedMetricName(),
      type: ActionType.SetAvailableForecastsByMetricName,
    };
  },

  fetchAvailableMetrics: () => {
    return {
      promise: Api.Metrics.getMetrics(),
      type: ActionType.SetAvailableMetrics,
    };
  },

  fetchAvailableMetricDescriptions: () => {
    return {
      promise: Api.Metrics.getDescriptions(),
      type: ActionType.SetAvailableMetricDescriptions,
    };
  },

  updateAvailableMetricDescriptions: (descriptions: List<Types.MetricDescription>) => {
    return {
      data: descriptions,
      type: ActionType.SetAvailableMetricDescriptions,
    };
  },

  fetchPartners: () => {
    return {
      promise: Api.Partners.getAllPartnersWithData(),
      type: ActionType.SetPartners,
    };
  },

  addVendorViews: (vendorViews: List<Types.View>) => {
    return {
      type: ActionType.AddVendorViews,
      vendorViews,
    };
  },

  setAvailableThinViews: (thinViews: List<Types.ThinView>) => {
    return {
      type: ActionType.SetAvailableThinViews,
      data: thinViews,
      promise: null,
    };
  },

  setViewFavoriteStatus: (view: Types.ThinView, isFavorite: boolean) => {
    return (dispatch: Dispatch) => {
      const viewId = assertTruthy(view.id);
      (isFavorite ? Api.Views.setAsFavorite(viewId) : Api.Views.removeFromFavorites(viewId))
        .then(() =>
          dispatch({
            isFavorite,
            type: ActionType.SetViewFavoriteStatus,
            viewId,
          })
        )
        .catch(showErrorAlert);
    };
  },

  setViewLinks: (viewLinks: ReadonlyArray<Types.ViewLink>) => {
    return {
      type: ActionType.SetViewLinks,
      viewLinks,
    };
  },

  setView: (view: Types.View, isNewView = false) => {
    return {
      type: ActionType.CreateOrUpdateView,
      isNewView,
      view,
    };
  },

  storeViews: (views: List<Types.View>) => {
    return {
      type: ActionType.CreateOrUpdateViews,
      views,
    };
  },

  setDoubleCountingPartners: (
    doubleCountingPartners: ReadonlyArray<Types.DoubleCountingPartner>
  ) => {
    return {
      doubleCountingPartners,
      type: ActionType.SetDoubleCountingPartners,
    };
  },
};

export function reloadWidget(viewIndex: number, widgetIndex: number, force?: boolean) {
  return (dispatch: Dispatch, getState: GetState) => {
    const activeView = getState().analysis.views.get(viewIndex)!;
    const widget: Types.Widget = activeView.view.widgets[widgetIndex];
    const dataProvider = Widgets[widget.type].dataProvider;
    const viewUpdateMode = getState().user.isManualViewUpdateMode
      ? Types.ViewUpdateMode.MANUAL
      : activeView.view.updateMode;

    if (dataProvider && (force || viewUpdateMode === Types.ViewUpdateMode.AUTOMATIC)) {
      const currentUser = getCurrentUserFromState(getState().user);
      dispatch(
        fetchWidgetData(activeView, viewIndex, widgetIndex, currentUser, dataProvider, !!force)
      );
    }
  };
}

export function forceReloadWidgets(viewIndex: number, forceReloadAll?: boolean) {
  return (dispatch: Dispatch, getState: GetState) => {
    const activeView = getState().analysis.views.get(viewIndex)!;
    activeView.widgetData
      .filter(widgetData => forceReloadAll || widgetData.needsRefresh)
      .forEach((_, widgetIndex) => dispatch(reloadWidget(viewIndex, widgetIndex, true)));
  };
}

export function setSelectedCalendarDate(date: moment.Moment, widgetIndex: number): AnalysisAction {
  return {
    date,
    type: ActionType.SetSelectedCalendarDate,
    widgetIndex,
  };
}

export function refreshCalendarEventWidgets(): AnalysisAction {
  return {
    type: ActionType.RefreshCalendarEventWidgets,
  };
}

export function clearUnsavedView(): AnalysisAction {
  return {
    type: ActionType.ClearUnsavedView,
  };
}

export function clearViewChanges(analysisSettings: Types.VendorAnalysisSettings): AnalysisAction {
  return {
    analysisSettings,
    type: ActionType.ClearViewChanges,
  };
}

// subscriptions may be deleted when the view privacy setting is made more strict (i.e. PRIVATE)
function shouldInvalidateSubscriptions(viewToSave: Types.ThinView, getState: GetState) {
  const previousView = getAvailableViews(getState()).get(viewToSave.id, null);
  return (
    viewToSave.shareLevel === Types.ShareLevel.PRIVATE &&
    previousView?.shareLevel !== Types.ShareLevel.PRIVATE
  );
}

export function saveView(viewToSave: Types.View) {
  return (dispatch: Dispatch, getState: GetState) => {
    const currentUser = getCurrentUserFromState(getState().user);
    if (
      viewToSave.id === null &&
      widgetViolatesWidgetLimit(getWidgetTreeWithoutData(viewToSave.widgets), currentUser)
    ) {
      showErrorAlert(
        null,
        `This dashboard has more widgets than allowed (${MAX_WIDGETS_PER_DASHBOARD}). Please remove some to continue.`
      );
      return Promise.reject();
    }

    const invalidateSubscriptions = shouldInvalidateSubscriptions(viewToSave, getState);
    return Api.Views.saveView(viewToSave)
      .then(savedView => {
        dispatch(clearViewChanges(getState().user.settings.analysisSettings!));
        if (invalidateSubscriptions) {
          invalidate(Api.Subscriptions.getWidgetSubscriptions.getResource(), oldValue => oldValue);
        }
        return dispatch(storeAndGoToView(savedView, savedView.id !== viewToSave.id));
      })
      .catch(e => {
        showErrorAlert(e);
        return Promise.reject(e);
      });
  };
}

export function archiveView(viewId: number) {
  return (dispatch: Dispatch, getState: GetState) => {
    const invalidateSubscriptions = shouldInvalidateSubscriptions(
      assertTruthy(getAvailableViews(getState()).get(viewId)),
      getState
    );
    return Api.Views.archiveView(viewId)
      .then(view => {
        dispatch(clearViewChanges(getState().user.settings.analysisSettings!));
        if (invalidateSubscriptions) {
          invalidate(Api.Subscriptions.getWidgetSubscriptions.getResource());
        }
        return view;
      })
      .catch(showErrorAlert);
  };
}

export function deleteView(viewId: number) {
  return (dispatch: Dispatch, getState: GetState) => {
    Api.Views.deleteView(viewId)
      .then(() => {
        dispatch({
          type: ActionType.DeleteView,
          viewId,
        });
        dispatch(replace(getIntelligenceManagementPageUrl(getState().user.vendor!.name)));
        invalidate(Api.Views.getVisitedViewTimestampsForUser.getResource());
        invalidate(Api.Views.getVisitedViewTimestampsForVendor.getResource());
        invalidate(Api.Subscriptions.getWidgetSubscriptions.getResource());
      })
      .catch(showErrorAlert);
  };
}

export function deleteEvent(event: Types.CalendarEvent) {
  return (dispatch: Dispatch) =>
    deleteCalendarEvent(event)
      .then(() => {
        dispatch({
          type: ActionType.DeleteEvent,
          eventId: assertTruthy(event.id),
        });
        dispatch(refreshCalendarEventWidgets());
        showAlert(`Calendar event "${event.name}" deleted!`);
      })
      .catch(e => showErrorAlert(e, errorString`Failed to delete calendar event: ${e.message}`));
}

export function createOrUpdateEvent(
  event: Types.CalendarEvent | null,
  savedEvent: Types.CalendarEvent
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: event?.id ? ActionType.UpdateEvent : ActionType.CreateEvent,
      event: savedEvent,
    });
    dispatch(refreshCalendarEventWidgets());
  };
}

export function saveViewLinks(
  linksToSave: Set<Types.ViewLink>,
  linksToDelete: Set<Types.ViewLink>
) {
  return (dispatch: Dispatch) =>
    // Delete view links and then set them to ensure that the view links
    // in the response is the most updated list.
    Api.Views.deleteViewLinks(linksToDelete.toArray())
      .then(() => Api.Views.saveViewLinks(linksToSave.toArray()))
      .then(viewLinks => {
        return dispatch({
          type: ActionType.SetViewLinks,
          viewLinks,
        });
      })
      .catch(showErrorAlert);
}

export function activatePresentationMode() {
  return (dispatch: Dispatch, getState: GetState) => {
    const currentVendorId = getState().user.vendor!.id;
    const allowedVendors = getState().userData.allowedVendors.filter(
      vendor => vendor.id === currentVendorId
    );
    const availableThinViews = getAvailableViews(getState())
      .filter(view => !isViewPrivate(view))
      .toList();
    dispatch(UserDataActions.setAllowedVendors(allowedVendors));
    dispatch(AnalysisDataActions.setAvailableThinViews(availableThinViews));
    dispatch(CurrentUserActions.setUserRole(Types.Role.ADMIN));
    dispatch(CurrentUserActions.setPresentationMode());

    showAlert(
      'Presentation mode started: all vendors except current one are removed' +
        ' and all private views are hidden. Impersonating an admin.' +
        ' Refresh the page to exit this mode.'
    );
  };
}

export function setDefaultEvaluationPeriod(period: Types.DatePeriod): AnalysisAction {
  return {
    period,
    type: ActionType.SetDefaultEvaluationPeriod,
  };
}

export function setUnitConversionAttribute(
  settings: Settings,
  availableMetrics: MetricsByName,
  unitConversionAttribute: Types.Attribute | null,
  currentUser: CurrentUser
): AnalysisAction {
  return {
    settings,
    availableMetrics,
    currentUser,
    unitConversionAttribute,
    type: ActionType.SetUnitConversionAttribute,
  };
}

export function setDashboardCurrency(currency: Types.CurrencyCode): AnalysisAction {
  return {
    currency,
    type: ActionType.SetDashboardCurrency,
  };
}

export function setEvaluationDate(date: moment.Moment): AnalysisAction {
  return {
    date,
    type: ActionType.SetEvaluationDate,
  };
}

export function setComputeEvaluationDateTime(dateTime: moment.Moment | null): AnalysisAction {
  return {
    dateTime,
    type: ActionType.SetComputeEvaluationDateTime,
  };
}

export function setGlobalFilters(filters: ReadonlyArray<Types.AttributeFilter>) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({
      filters,
      partners: getState().analysis.data.partners,
      type: ActionType.SetGlobalFilters,
      vendorCalendar: getState().user.settings!.analysisSettings!.calendar,
      isCalendarAutoSwitchingEnabled:
        getState().user.settings!.analysisSettings!.isCalendarAutoSwitchingEnabled,
    });
  };
}

export function clearSelectionFilters(forWidgetIndex?: number): AnalysisAction {
  return {type: ActionType.ClearSelectionFilters, forWidgetIndex};
}

export function setSelectionFiltersForWidget(
  widgetIndex: number,
  filters: ReadonlyArray<Types.AttributeFilter>
): AnalysisAction {
  return {
    filters,
    type: ActionType.SetSelectionFiltersForWidget,
    widgetIndex,
  };
}

export function setPeriodChooserOpen(isChooserOpen: boolean): AnalysisAction {
  return {
    isChooserOpen,
    type: ActionType.SetPeriodChooserOpen,
  };
}

export function setViewMenuOpen(isMenuOpen: boolean): AnalysisAction {
  return {
    isMenuOpen,
    type: ActionType.SetViewMenuOpen,
  };
}

export function setExpandedWidget(widgetIndex: number | null): AnalysisAction {
  return {
    type: ActionType.SetExpandedWidget,
    widgetIndex,
  };
}

export function setFavoriteViews(views: List<Types.ThinView>): AnalysisAction {
  return {
    type: ActionType.SetFavoriteViews,
    views,
  };
}

export function replaceCurrentViews(
  views: List<Types.View>,
  viewParams?: List<ViewUrlParams | null> | null
) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({
      analysisSettings: getState().user.settings!.analysisSettings!,
      type: ActionType.ReplaceCurrentViews,
      viewParams,
      views,
    });
  };
}

export function setEditingLayout(isEditingLayout: boolean): AnalysisAction {
  return {
    isEditingLayout,
    type: ActionType.SetEditingLayout,
  };
}

export function setOriginalWidgets(widgets: ReadonlyArray<Types.Widget>): AnalysisAction {
  return {
    type: ActionType.SetOriginalWidgets,
    widgets,
  };
}

export function setProposedWidgets(widgets: ReadonlyArray<Types.Widget>): AnalysisAction {
  return {
    type: ActionType.SetProposedWidgets,
    widgets,
  };
}

export function selectWidget(widgetIndex: number | null): AnalysisAction {
  return {type: ActionType.SelectWidget, widgetIndex};
}

export function editWidget(widgetIndex: number | null) {
  return (dispatch: Dispatch) => {
    dispatch({type: ActionType.SetEditingWidget, widgetIndex});
    dispatch({type: ActionType.SetExpandedWidget, widgetIndex});
  };
}

export function editWidgetSubscription(widgetIndex: number | null): AnalysisAction {
  return {
    type: ActionType.SetEditingWidgetSubscription,
    widgetIndex,
  };
}

export function convertToContainerWidget(containerType: ContainerWidgetType, widgetIndex: number) {
  return (dispatch: Dispatch, getState: GetState) => {
    const activeView = mainView(getState())!;
    const widget = activeView.view.widgets[widgetIndex];
    const firstChildWidgetData: WidgetLayoutData = {
      data: activeView.widgetData[widgetIndex],
      widget,
    };
    const copyChildWidgetData: WidgetLayoutData = {
      data: activeView.widgetData[widgetIndex],
      widget: {
        ...widget,
        id: getDefaultWidgetProperties().id,
      },
    };
    const defaultContainerWidget = createDefaultContainerWidget(containerType);
    const data: WidgetLayoutData = {
      data: createDefaultWidgetData(),
      children: List.of(firstChildWidgetData, copyChildWidgetData),
      widget: {
        ...defaultContainerWidget,
        childCount: 2,
        layoutParams: firstChildWidgetData.widget.layoutParams,
      },
    };
    const tree = getWidgetTreeWithoutData(activeView.view.widgets);
    const treeIndex = getParentWidgetIndex(tree, widgetIndex);
    dispatch({type: ActionType.SetProposedWidgetTreeItem, treeIndex, data});

    if (activeView.editingWidgetIndex === widgetIndex) {
      dispatch({
        type: ActionType.SetEditingWidget,
        widgetIndex: activeView.editingWidgetIndex + data.children!.size,
      });
    }
    if (activeView.expandedWidgetIndex === widgetIndex) {
      dispatch({
        type: ActionType.SetExpandedWidget,
        widgetIndex: activeView.expandedWidgetIndex + data.children!.size,
      });
    }
  };
}

export function viewWidgetFiles(widgetIndex: number | null): AnalysisAction {
  return {
    type: ActionType.SetViewWidgetFilesIndex,
    widgetIndex,
  };
}

export function addWidget(widgetConfig: Types.Widget): AnalysisAction {
  return {
    type: ActionType.AddWidgetToEnd,
    widgetConfig,
  };
}

export function copyWidget(fromTreeIndex: number, toTreeIndex: number): AnalysisAction {
  return {
    fromTreeIndex,
    toTreeIndex,
    type: ActionType.CopyWidget,
  };
}

export function deleteWidget(widgetIndex: number): AnalysisAction {
  return {
    type: ActionType.DeleteWidget,
    widgetIndex,
  };
}

export function moveWidget(fromTreeIndex: number, toTreeIndex: number): AnalysisAction {
  return {
    fromTreeIndex,
    toTreeIndex,
    type: ActionType.MoveWidget,
  };
}

export function setProposedWidgetTreeItem(
  treeIndex: number,
  data: WidgetLayoutData
): AnalysisAction {
  return {
    data,
    treeIndex,
    type: ActionType.SetProposedWidgetTreeItem,
  };
}

export function moveWidgetChild(
  widgetTreeIndex: number,
  fromChildIndex: number,
  toChildIndex: number
): AnalysisAction {
  return {
    type: ActionType.MoveWidgetChild,
    widgetTreeIndex,
    fromChildIndex,
    toChildIndex,
  };
}

export function setWidgets(
  widgets: ReadonlyArray<Types.Widget>,
  options: {calendar?: Types.RetailCalendarEnum; forceRefreshAllWidgets?: boolean} = {}
): AnalysisAction {
  return {
    type: ActionType.SetWidgets,
    widgets,
    calendar: options.calendar,
    forceRefreshAllWidgets: options.forceRefreshAllWidgets,
  };
}

export function setWidgetConfig(index: number, config: Types.Widget): AnalysisAction {
  return {
    config,
    index,
    type: ActionType.SetWidgetConfig,
  };
}

/**
 * This will not reset the widgetData for a new request. Changing metrics/groupings/filters should
 * use setWidgetConfig instead.
 */
export function setWidgetConfigWithoutUpdate(
  index: number,
  partialConfig: Partial<Types.Widget>
): AnalysisAction {
  return {
    partialConfig,
    index,
    type: ActionType.SetWidgetConfigWithoutUpdate,
  };
}

export function storeAndGoToView(view: Types.View, isNewView = false) {
  return (dispatch: Dispatch) => {
    dispatch({
      isNewView,
      type: ActionType.CreateOrUpdateView,
      view,
    });
    dispatch(goToView(view));
    return view;
  };
}

export function storeAndGoToNewView(view: Types.View) {
  return storeAndGoToView(view, true);
}

export function goToView(view: Types.ThinView) {
  return (dispatch: Dispatch, getState: GetState) =>
    new Promise<void>(resolve => {
      dispatch(
        push(getViewLink(getState().user.vendor!.name, view.id, view.slug ? [view.slug] : []))
      );

      function resolveAfterNavigation() {
        setTimeout(() => {
          if (!getState().navigation.isNavigationInProgress) {
            resolve();
          } else {
            resolveAfterNavigation();
          }
        }, 10);
      }

      resolveAfterNavigation();
    });
}

import {Map} from 'immutable';
import moment from 'moment-timezone';

import {AlertAction, Alert} from 'redux/actions/alerts';
import ActionType from 'redux/actions/types';
import {findLastIndex, removeAt} from 'utils/arrays';

export interface AlertsState {
  readonly alerts: ReadonlyArray<Alert>;
  readonly lastShownTimestampsById: Map<string, moment.Moment>;
}

const initialAlertsState: AlertsState = {
  alerts: [],
  lastShownTimestampsById: Map(),
};

export default function alerts(state = initialAlertsState, action: AlertAction): AlertsState {
  if (action.type === ActionType.AddAlert) {
    const pastShownDateTime = state.lastShownTimestampsById.get(action.alert.id);
    const showAgainDelayMsec = action.alert.showAgainDelayMsec;
    if (
      showAgainDelayMsec !== undefined &&
      pastShownDateTime !== undefined &&
      moment().diff(pastShownDateTime, 'milliseconds') < showAgainDelayMsec
    ) {
      return state;
    }

    return {
      ...state,
      alerts: [
        ...state.alerts
          .filter(alert => !alert.autoCloseIntervalMsec)
          .filter(alert => !action.alert.isSingleton || alert.id !== action.alert.id),
        action.alert,
      ],
      lastShownTimestampsById: state.lastShownTimestampsById.set(action.alert.id, moment()),
    };
  } else if (action.type === ActionType.ClearAlert) {
    const lastIndex = findLastIndex(state.alerts, alert => alert.id === action.alertId);
    return {...state, alerts: removeAt(state.alerts, lastIndex)};
  }
  return state;
}

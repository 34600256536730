/**
 * Feature flags are added here as an enum to avoid typos and repetition of strings.
 *
 * If a feature flag is not needed in the frontend, it doesn't need to be added here.
 *
 * Before adding a feature flag here, remember to add the flag in the feature flags CRUD.
 */
export enum FeatureFlag {
  ALLOY_ASSISTANT = 'ALLOY_ASSISTANT',
  ATTRIBUTE_DESCRIPTIONS = 'ATTRIBUTE_DESCRIPTIONS',
  SALES_DIAGNOSTICS = 'SALES_DIAGNOSTICS',
  FORECAST_MODULE = 'FORECAST_MODULE',
  INVENTORY_PLANNING = 'INVENTORY_PLANNING',
  LAUNCHPAD_DATA_READINESS = 'LAUNCHPAD_DATA_READINESS',
  PLAN_APPLICATION = 'PLAN_APPLICATION',
  PLAN_METRICS = 'PLAN_METRICS',
  PROMOTION_ANALYSIS = 'PROMOTION_ANALYSIS',
  RBAC_SYSTEM = 'RBAC_SYSTEM',
  SHIPPING_LANE_RULES = 'SHIPPING_LANE_RULES',
  STORE_SPECIFIC_ORDERS = 'STORE_SPECIFIC_ORDERS',
  SUPPLY_CHAIN = 'SUPPLY_CHAIN',
  FAST_DASHBOARDS = 'FAST_DASHBOARDS',
  EPOCH_SYSTEM = 'EPOCH_SYSTEM',
  REDSHIFT_DATA_SHARE = 'REDSHIFT_DATA_SHARE',
  PERCENTAGE_BASE_FILTERS = 'PERCENTAGE_BASE_FILTERS',
  SIDEBAR_ON_DATA_PAGES = 'SIDEBAR_ON_DATA_PAGES',
  DATA_CENTER = 'DATA_CENTER',
  BIGQUERY_EXPORTS = 'BIGQUERY_EXPORTS',
  DEPROMO = 'DEPROMO',
  SNOWFLAKE_DATA_SHARE = 'SNOWFLAKE_DATA_SHARE',
  AZURE_DATA_SHARE = 'AZURE_DATA_SHARE',
  BUCKET_DESTINATIONS = 'BUCKET_DESTINATIONS',
  DATA_STATUS_SELL_IN = 'DATA_STATUS_SELL_IN',
}

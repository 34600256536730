import equal from 'fast-deep-equal';
import {List, Set, Map} from 'immutable';
import moment from 'moment-timezone';
import {ReactNode} from 'react';

export function flattenShallow<T>(nestedArray: T[][] | ReadonlyArray<T>[]): T[] {
  const emptyArray: T[] = [];
  return emptyArray.concat(...nestedArray);
}

export function keyBy<T, K extends keyof T>(
  objects: ReadonlyArray<T>,
  key: K,
  value?: undefined
): Map<T[K], T>;
export function keyBy<T, K extends keyof T, V extends keyof T>(
  objects: ReadonlyArray<T>,
  key: K,
  value: V
): Map<T[K], T[V]>;
export function keyBy<T, K extends keyof T, V extends keyof T>(
  objects: ReadonlyArray<T>,
  key: K,
  value: V | undefined = undefined
) {
  if (value !== undefined) {
    return Map<T[K], T[V]>(objects.map(obj => [obj[key], obj[value]]));
  }
  return Map<T[K], T>(objects.map(obj => [obj[key], obj]));
}

export function insertAt<T>(list: ReadonlyArray<T>, index: number, value: T): ReadonlyArray<T> {
  const newList = [...list];
  newList.splice(index, 0, value);
  return newList;
}

export function maxBy<T, U>(list: ReadonlyArray<T>, mapper: (value: T) => U) {
  return List(list).maxBy(mapper);
}

export function replaceAt<T>(list: ReadonlyArray<T>, index: number, value: T): ReadonlyArray<T> {
  const newList = [...list];
  newList.splice(index, 1, value);
  return newList;
}

export function removeAt<T>(list: ReadonlyArray<T>, index: number): ReadonlyArray<T> {
  const newList = [...list];
  newList.splice(index, 1);
  return newList;
}

export function splice<T>(
  list: ReadonlyArray<T>,
  startIndex: number,
  itemsToDelete: number,
  itemsToAdd: ReadonlyArray<T>
) {
  const newList = [...list];
  newList.splice(startIndex, itemsToDelete, ...itemsToAdd);
  return newList;
}

export function removeValue<T>(array: ReadonlyArray<T>, value: T): ReadonlyArray<T> {
  return array.filter(item => !equal(item, value));
}

export function getMostCommonItem<T>(list: ReadonlyArray<T>) {
  const mostCommonList = List(list)
    .groupBy(entry => entry)
    .map(subList => subList.toList())
    .sortBy(subList => subList.size)
    .last();
  return mostCommonList ? mostCommonList.first() : null;
}

export function subtract<T>(array1: ReadonlyArray<T>, array2: ReadonlyArray<T>) {
  return Set(array1).subtract(Set(array2)).toArray();
}

export function toggleInArray<T>(array: ReadonlyArray<T>, itemToToggle: T) {
  return array.includes(itemToToggle)
    ? array.filter(item => item !== itemToToggle)
    : [...array, itemToToggle];
}

export function last<T>(array: ReadonlyArray<T>) {
  return array[array.length - 1];
}

export function first<T>(array: ReadonlyArray<T>) {
  return array[0];
}

export function findLastIndex<T>(array: ReadonlyArray<T>, predicate: (value: T) => boolean) {
  // eslint-disable-next-line fp/no-let
  for (let i = array.length - 1; i >= 0; --i) {
    if (predicate(array[i])) {
      return i;
    }
  }
  return -1;
}

export function findLast<T>(array: ReadonlyArray<T>, predicate: (value: T) => boolean) {
  // eslint-disable-next-line fp/no-let
  for (let i = array.length - 1; i >= 0; --i) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return null;
}

export function anyMatch<T>(
  list: ReadonlyArray<T>,
  predicate: (value: T, index: number) => boolean
) {
  return list.map(predicate).reduce((a, b) => a || b, false);
}

export function allMatch<T>(
  list: ReadonlyArray<T>,
  predicate: (value: T, index: number) => boolean
) {
  return list.map(predicate).reduce((a, b) => a && b, true);
}

export function noneMatch<T>(
  list: ReadonlyArray<T>,
  predicate: (value: T, index: number) => boolean
) {
  return !anyMatch(list, predicate);
}

export function moveItem<T>(array: ReadonlyArray<T>, oldIndex: number, newIndex: number) {
  const movedItem = array[oldIndex];
  return insertAt(removeAt(array, oldIndex), newIndex, movedItem);
}

export function uniquify<T>(array: ReadonlyArray<T>): ReadonlyArray<T> {
  return Set(array).toArray();
}

export function reverse<T>(array: ReadonlyArray<T>): ReadonlyArray<T> {
  return [...array].reverse();
}

export function compareByIndex<T>(order: ReadonlyArray<T>) {
  return (a: T, b: T) => order.indexOf(a) - order.indexOf(b);
}

/**
 * If length is larger than a.length, truncate to length.
 * If length is smaller than a.length, pad to length (like String.padEnd for arrays).
 */
export function setLength<T, P>(a: ReadonlyArray<T>, length: number, pad: P): ReadonlyArray<T | P> {
  if (a.length === length) {
    return a;
  }
  if (a.length > length) {
    return a.slice(0, length);
  }
  return [...a, ...new Array(length - a.length).fill(pad)];
}

export type ChainableCompareFunction<T> = CompareFunction<T> & {
  thenAscendingBy: (next: SortableValueFunction<T>) => ChainableCompareFunction<T>;
  thenDescendingBy: (next: SortableValueFunction<T>) => ChainableCompareFunction<T>;
  thenByComparator: (comparator: (a: T, b: T) => number) => ChainableCompareFunction<T>;
};

/**
 * Returns a function that can be passed to [...].sort() in order to sort the array in
 * ascending order by whatever the supplied mapping function returns for the individual
 * items. The following return values are supported:
 *
 * - number
 * - string (alphabetical, case-insensitive)
 * - boolean (true is higher than false)
 * - moment (chronologically)
 *
 * You can chain .thenAscendingBy(...) and .thenDescendingBy(...) to provide secondary
 * sort orders.
 *
 *     const animals = ["Horse", "Cat", "Dog", "Hippopotamus", "Camel", "Cow"];
 *     animals.sort(ascendingBy<string>(name => name.length).thenDescendingBy(name => name))
 *     // -> ["Dog", "Cow", "Cat", "Horse", "Camel", "Hippopotamus"]
 *
 * If you don't provide secondary sorts, TypeScript can figure out the type from the array
 * type, so you can leave it off.
 */
export function ascendingBy<T>(mapping: SortableValueFunction<T>): ChainableCompareFunction<T> {
  return makeChainable(ascendingByImpl(mapping));
}

/**
 * Returns a function that can be passed to [...].sort() in order to sort the array in
 * descending order by whatever the supplied mapping function returns for the individual
 * items.
 *
 * See ascendingBy() for more details.
 */
export function descendingBy<T>(mapping: SortableValueFunction<T>): ChainableCompareFunction<T> {
  return makeChainable(descendingByImpl(mapping));
}

export type SortableValueFunction<T> =
  | ((value: T) => number | null)
  | ((value: T) => string | null)
  | ((value: T) => boolean)
  | ((value: T) => moment.Moment)
  | ((value: T) => null);

export type CompareFunction<T> = (a: T, b: T) => number;

function combineCompareFunctions<T>(
  cf1: CompareFunction<T>,
  cf2: CompareFunction<T>
): CompareFunction<T> {
  return (a: T, b: T) => {
    const compared = cf1(a, b);
    if (compared !== 0) {
      return compared;
    }
    return cf2(a, b);
  };
}

function makeChainable<T>(cf: CompareFunction<T>): ChainableCompareFunction<T> {
  const result = ((a: T, b: T) => cf(a, b)) as ChainableCompareFunction<T>;
  result.thenAscendingBy = (next: SortableValueFunction<T>) => {
    const combined = combineCompareFunctions(result, ascendingByImpl(next));
    return makeChainable(combined);
  };
  result.thenDescendingBy = (next: SortableValueFunction<T>) => {
    const combined = combineCompareFunctions(result, descendingByImpl(next));
    return makeChainable(combined);
  };
  result.thenByComparator = (comparator: (a: T, b: T) => number) => {
    const combined = combineCompareFunctions(result, comparator);
    return makeChainable(combined);
  };
  return result;
}

function ascendingByImpl<T>(mapping: SortableValueFunction<T>) {
  return (a: T, b: T) => {
    const ma = mapping(a);
    if (typeof ma === 'string') {
      return ma.localeCompare(mapping(b) as string, undefined, {
        numeric: true,
      });
    }
    if (moment.isMoment(ma)) {
      return ma.unix() - (mapping(b) as moment.Moment).unix();
    } else {
      // This is only to make sure at compile time that all possible types other than
      // booleans and numbers are handled
      const numA: number | boolean | null = ma;

      // note that this works fine with booleans and nulls
      return (numA as number) - (mapping(b) as number);
    }
  };
}

function descendingByImpl<T>(mapping: SortableValueFunction<T>) {
  const ascending = ascendingByImpl(mapping);
  return (a: T, b: T) => -ascending(a, b);
}

export function humanReadableList(
  array: ReadonlyArray<string>,
  maxItems: number = Number.POSITIVE_INFINITY,
  itemMapper: (item: string) => ReactNode,
  hiddenItemsMapper: (hiddenItems: ReadonlyArray<string>) => ReactNode
): ReadonlyArray<ReactNode> {
  if (array.length <= maxItems) {
    return array.map(itemMapper);
  }
  const numDisplayedItems = maxItems - 1;
  return [
    ...array.slice(0, numDisplayedItems).map(itemMapper),
    hiddenItemsMapper(array.slice(numDisplayedItems)),
  ];
}

export function humanReadableString(
  array: ReadonlyArray<string>,
  maxItems: number = Number.POSITIVE_INFINITY
): string {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length > maxItems) {
    // To ensure we show a consistent number of items, hide the last item (as we append the "others" item)
    const shownItems = maxItems - 1;
    return `${array.slice(0, shownItems).join(', ')} and ${array.length - shownItems} others`;
  }
  return `${array.slice(0, -1).join(', ')} and ${array[array.length - 1]}`;
}

export function hasIntersection<T>(array1: ReadonlyArray<T>, array2: ReadonlyArray<T>) {
  return array1.some(item => array2.includes(item));
}

export function isPrefix<T>(array1: ReadonlyArray<T>, array2: ReadonlyArray<T>) {
  if (array1.length > array2.length) {
    return false;
  }
  // eslint-disable-next-line fp/no-let
  for (let i = 0; i < array1.length; i++) {
    if (!equal(array1[i], array2[i])) {
      return false;
    }
  }

  return true;
}

export function isSuffix<T>(array1: ReadonlyArray<T>, array2: ReadonlyArray<T>): boolean {
  if (array1.length < array2.length) {
    return false;
  }
  const length1 = array1.length;
  const length2 = array2.length;
  // eslint-disable-next-line fp/no-let
  for (let i = 0; i <= array2.length; i++) {
    if (!equal(array1[length1 - i], array2[length2 - i])) {
      return false;
    }
  }
  return true;
}

/**
 * Maps array `array` to an object whose keys are obtained from array elements using `keyMapper`,
 * and whose values are obtained using `valueMapper`.
 */
export function toObjectMap<T, V>(
  array: ReadonlyArray<T>,
  keyMapper: (value: T) => string,
  valueMapper: (value: T) => V
): {readonly [key: string]: V} {
  const map: {[key: string]: V} = {};
  array.forEach(item => {
    map[keyMapper(item)] = valueMapper(item);
  });

  return map;
}

export function toArray<T>(item: T): ReadonlyArray<T> {
  return [item];
}

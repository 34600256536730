import {LOCATION_CHANGE} from 'connected-react-router';
import {Map} from 'immutable';

import {isPlanDiffEmpty} from 'planning/utils';
import {isFail, isLoading} from 'redux/actions/helpers';
import {PlansAction} from 'redux/actions/plans';
import ActionType from 'redux/actions/types';
import {SortModel} from 'toolkit/ag-grid/types';
import {EntityTableColumn} from 'toolkit/entities/types';
import {PendingNpiStatus, PendingNpiStatusById} from 'toolkit/plans/new-product-introduction/utils';
import {getDiscontinuedSkuPathAsKey, PlanVersionsByType} from 'toolkit/plans/utils';
import * as Types from 'types';
import {ThinUserManualAdjustment} from 'types';
import {assertTruthy} from 'utils/assert';
import {Status} from 'utils/status';
import {getDeletedAddedAdjustments} from 'widgets/tables/impl/planning/utils';

export enum PlanViewFilterType {
  ALL = 'All',
  ACTIVE = 'Active',
  DIRECT_IMPORT = 'Direct Import',
  DISCONTINUED_SKU = 'Discontinued SKUs',
  MANUAL_ADJUSTMENT = 'Manual Adjustments',
  NEW_PRODUCT = 'New Products',
}

interface PlanVersionsState {
  // The plan version that is persisted on the backend.
  readonly lastSavedPlanVersion: Types.PlanVersion | null;
  // The plan version whose data is currently being shown, assuming no pending changes that require a refresh.
  readonly activePlanVersionDiff: Types.PlanDiff | null;
  // The plan version whose changes are pending, i.e. there is an Update action required from user.
  readonly pendingPlanDiff: Types.PlanDiff | null;
  // The plan version that the user has not saved yet. Used to allow restoring it if navigating away.
  readonly unsavedPlanVersion: Types.PlanVersion | null;
}

export interface PlansState {
  readonly demand: PlanVersionsState;
  readonly inventory: PlanVersionsState;
  // the diff of the active plan to last pending plan used when sending a request to keep the visual state consistent
  readonly deletedManualAdjustmentsSinceLastUpdate: ReadonlyArray<ThinUserManualAdjustment>;
  readonly planningSelectedPath: ReadonlyArray<Types.AttributeFilter> | null;
  readonly planVersions: PlanVersionsByType;
  readonly plansFetchStatus: Status;
  readonly plansFetchRequest: Promise<unknown> | null;
  readonly sortModel: SortModel;
  readonly planSettings: Types.PlanSettings | null;
  readonly planSettingsFetchStatus: Status;
  readonly filterType: PlanViewFilterType;
  readonly newProductIntroductionsFetchStatus: Status;
  readonly newProductIntroductions: ReadonlyArray<Types.NewProductIntroductionConfig>;
  readonly pendingNpiStatuses: PendingNpiStatusById;
  readonly disabledForecastTypesFetchStatus: Status;
  readonly disabledForecastTypes: ReadonlyArray<Types.ForecastType>;
  readonly forecastPageSelectionPath: ReadonlyArray<number>;
  readonly forecastsPageHasUnsavedChanges: boolean;
  readonly planDemandSourceMapFetchStatus: Status;
  readonly planDemandSourceMap: Map<number, Types.PlanDemandSource>;
  readonly discontinuedSkuByPathFetchStatus: Status;
  readonly discontinuedSkuByPath: Map<ReadonlyArray<number>, Types.DiscontinuedSku>;
  readonly isViewingDirectImportSecondaryProduct: boolean;
  readonly refreshNeeded: boolean;
}

const initialState: PlansState = {
  demand: {
    lastSavedPlanVersion: null,
    activePlanVersionDiff: null,
    pendingPlanDiff: null,
    unsavedPlanVersion: null,
  },
  inventory: {
    lastSavedPlanVersion: null,
    activePlanVersionDiff: null,
    pendingPlanDiff: null,
    unsavedPlanVersion: null,
  },
  planningSelectedPath: null,
  deletedManualAdjustmentsSinceLastUpdate: [],
  planVersions: Map(),
  plansFetchStatus: Status.unstarted,
  plansFetchRequest: null,
  sortModel: [
    {
      colId: EntityTableColumn.START_DATE,
      sort: 'desc',
    },
  ],
  planSettings: null,
  planSettingsFetchStatus: Status.unstarted,
  filterType: PlanViewFilterType.ACTIVE,
  newProductIntroductions: [],
  newProductIntroductionsFetchStatus: Status.unstarted,
  pendingNpiStatuses: Map(),
  disabledForecastTypesFetchStatus: Status.unstarted,
  disabledForecastTypes: [],
  forecastPageSelectionPath: [],
  forecastsPageHasUnsavedChanges: false,
  planDemandSourceMapFetchStatus: Status.unstarted,
  planDemandSourceMap: Map(),
  discontinuedSkuByPathFetchStatus: Status.unstarted,
  discontinuedSkuByPath: Map(),
  isViewingDirectImportSecondaryProduct: false,
  refreshNeeded: true,
};

export default function plans(state = initialState, action: PlansAction): PlansState {
  if (action.type === ActionType.SetPlans) {
    if (isLoading(action)) {
      return {...state, plansFetchStatus: Status.inProgress, plansFetchRequest: action.promise};
    } else if (isFail(action)) {
      return {...state, plansFetchStatus: Status.failed, plansFetchRequest: action.promise};
    }
    return {
      ...state,
      planVersions: Map(action.data!.map(planVersion => [planVersion.basePlan.type, planVersion])),
      plansFetchStatus: Status.succeeded,
      plansFetchRequest: null,
    };
  } else if (
    action.type === ActionType.RestorePlan ||
    action.type === ActionType.RestoreUnsavedPlanVersion ||
    action.type === ActionType.SetLastSavedPlanVersion ||
    action.type === ActionType.SetActivePlanDiff ||
    action.type === ActionType.SetPendingPlanDiff
  ) {
    return action.planType === Types.PlanType.DEMAND
      ? {
          ...state,
          demand: getPlanVersionState(action, state.demand),
        }
      : {
          ...state,
          inventory: getPlanVersionState(action, state.inventory),
        };
  } else if (action.type === ActionType.ClearPlanVersion) {
    return action.planType === Types.PlanType.DEMAND
      ? {
          ...state,
          demand: getPlanVersionState(action, state.demand),
          forecastsPageHasUnsavedChanges: false,
          pendingNpiStatuses: Map(),
          forecastPageSelectionPath: [],
        }
      : {
          ...state,
          inventory: getPlanVersionState(action, state.inventory),
          forecastsPageHasUnsavedChanges: false,
          pendingNpiStatuses: Map(),
          forecastPageSelectionPath: [],
        };
  } else if (action.type === ActionType.UpdatePlanVersion) {
    return {
      ...state,
      planVersions: state.planVersions.set(action.newPlan.basePlan.type, action.newPlan),
    };
  } else if (action.type === ActionType.SetDeletedManualAdjustmentsSinceLastUpdate) {
    return {
      ...state,
      deletedManualAdjustmentsSinceLastUpdate: [
        ...getDeletedAddedAdjustments(
          state.demand.activePlanVersionDiff?.addedManualAdjustments ?? [],
          state.demand.pendingPlanDiff?.addedManualAdjustments ?? []
        ),
        ...getDeletedAddedAdjustments(
          state.inventory.activePlanVersionDiff?.addedManualAdjustments ?? [],
          state.inventory.pendingPlanDiff?.addedManualAdjustments ?? []
        ),
      ],
    };
  } else if (action.type === ActionType.ClearDeletedManualAdjustmentsSinceLastUpdate) {
    return {
      ...state,
      deletedManualAdjustmentsSinceLastUpdate: [],
    };
  } else if (action.type === ActionType.SetPlanSettings) {
    if (isLoading(action)) {
      return {...state, planSettingsFetchStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, planSettingsFetchStatus: Status.failed};
    } else {
      return {
        ...state,
        planSettingsFetchStatus: Status.succeeded,
        planSettings: action.data!,
      };
    }
  } else if (action.type === ActionType.SetPlanViewFilterType) {
    return {...state, filterType: action.value};
  } else if (action.type === LOCATION_CHANGE) {
    if (!action.payload.location.pathname.startsWith(`/${action.vendor?.name}/planning`)) {
      return {
        ...state,
        demand: getPlanVersionState(action, state.demand),
        inventory: getPlanVersionState(action, state.inventory),
        forecastsPageHasUnsavedChanges:
          state.forecastPageSelectionPath.length > 0 &&
          (!!state.demand.pendingPlanDiff ||
            !!state.inventory.pendingPlanDiff ||
            state.pendingNpiStatuses.size > 0),
      };
    }
  } else if (action.type === ActionType.FetchNewProductIntroductions) {
    if (isLoading(action)) {
      return {
        ...state,
        newProductIntroductionsFetchStatus: Status.inProgress,
      };
    } else if (isFail(action)) {
      return {...state, newProductIntroductionsFetchStatus: Status.failed};
    }
    return {
      ...state,
      newProductIntroductions: action.data!,
      newProductIntroductionsFetchStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.UpdateNewProductIntroduction) {
    return {
      ...state,
      newProductIntroductions: [
        ...state.newProductIntroductions.filter(
          npiConfig => npiConfig.newProductIntroduction.id !== action.id
        ),
        action.npiConfig,
      ],
    };
  } else if (action.type === ActionType.UpdateNewProductIntroductions) {
    const npiConfigIds = action.npiConfigs.map(npiConfig => npiConfig.newProductIntroduction.id);
    return {
      ...state,
      newProductIntroductions: [
        ...state.newProductIntroductions.filter(
          npiConfig => !npiConfigIds.includes(npiConfig.newProductIntroduction.id)
        ),
        ...action.npiConfigs,
      ],
    };
  } else if (action.type === ActionType.SetPendingNpiStatus) {
    return {
      ...state,
      pendingNpiStatuses:
        action.pendingNpiStatus === PendingNpiStatus.UNCHANGED
          ? state.pendingNpiStatuses.remove(action.id)
          : state.pendingNpiStatuses.set(action.id, action.pendingNpiStatus),
    };
  } else if (action.type === ActionType.ClearPendingNpiStatuses) {
    return {
      ...state,
      pendingNpiStatuses: Map(),
    };
  } else if (action.type === ActionType.FetchDisabledForecastTypes) {
    if (isLoading(action)) {
      return {...state, disabledForecastTypesFetchStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, disabledForecastTypesFetchStatus: Status.failed};
    } else {
      return {
        ...state,
        disabledForecastTypes: assertTruthy(action.data),
        disabledForecastTypesFetchStatus: Status.succeeded,
      };
    }
  } else if (action.type === ActionType.SetForecastPageSelectionPath) {
    return {
      ...state,
      forecastPageSelectionPath: action.path,
    };
  } else if (action.type === ActionType.ClearForecastsPageUnsavedChanges) {
    return {
      ...state,
      forecastsPageHasUnsavedChanges: false,
    };
  } else if (action.type === ActionType.SetSelectedPlanningPath) {
    return {
      ...state,
      planningSelectedPath: action.selectedPath,
    };
  } else if (action.type === ActionType.FetchPlanDemandSources) {
    if (isLoading(action)) {
      return {
        ...state,
        planDemandSourceMapFetchStatus: Status.inProgress,
      };
    } else if (isFail(action)) {
      return {
        ...state,
        planDemandSourceMapFetchStatus: Status.failed,
      };
    } else {
      return {
        ...state,
        planDemandSourceMapFetchStatus: Status.succeeded,
        planDemandSourceMap: action.data!.reduce((acc, item) => {
          return acc.set(item.attributeValueId, item.planDemandSource);
        }, Map<number, Types.PlanDemandSource>()),
      };
    }
  } else if (action.type === ActionType.FetchDiscontinuedSkus) {
    if (isLoading(action)) {
      return {
        ...state,
        discontinuedSkuByPathFetchStatus: Status.inProgress,
      };
    } else if (isFail(action)) {
      return {
        ...state,
        discontinuedSkuByPathFetchStatus: Status.failed,
      };
    } else {
      return {
        ...state,
        discontinuedSkuByPathFetchStatus: Status.succeeded,
        discontinuedSkuByPath: Map(
          action.data!.map(item => [getDiscontinuedSkuPathAsKey(item.attributeValues), item])
        ),
      };
    }
  } else if (action.type === ActionType.RefreshCalendarEventWidgets) {
    return {...state, refreshNeeded: true};
  } else if (action.type === ActionType.SetIsViewingDirectImportSecondaryProduct) {
    return {
      ...state,
      isViewingDirectImportSecondaryProduct: action.isViewingDirectImportSecondaryProduct,
    };
  } else if (action.type === ActionType.SetRefreshNeeded) {
    return {
      ...state,
      refreshNeeded: action.refreshNeeded,
    };
  }
  return state;
}

function getPlanVersionState(action: PlansAction, planVersionState: PlanVersionsState) {
  switch (action.type) {
    case ActionType.RestorePlan:
      return {
        ...planVersionState,
        activePlanVersionDiff: planVersionState.pendingPlanDiff,
      };
    case ActionType.ClearPlanVersion:
      return {
        lastSavedPlanVersion: null,
        activePlanVersionDiff: null,
        pendingPlanDiff: null,
        unsavedPlanVersion: null,
      };
    case ActionType.RestoreUnsavedPlanVersion:
      return {
        ...planVersionState,
        lastSavedPlanVersion: planVersionState.unsavedPlanVersion,
        unsavedPlanVersion: null,
      };
    case ActionType.SetLastSavedPlanVersion:
      return {
        ...planVersionState,
        lastSavedPlanVersion: action.planVersion,
      };
    case ActionType.SetActivePlanDiff:
      const planDiff = isPlanDiffEmpty(action.planDiff) ? null : action.planDiff;
      return {
        ...planVersionState,
        activePlanVersionDiff: planDiff,
        pendingPlanDiff: planDiff,
      };
    case ActionType.SetPendingPlanDiff:
      return {
        ...planVersionState,
        pendingPlanDiff: action.planDiff,
      };
    case LOCATION_CHANGE:
      return {
        ...planVersionState,
        lastSavedPlanVersion: null,
        unsavedPlanVersion: !!planVersionState.pendingPlanDiff
          ? planVersionState.lastSavedPlanVersion
          : null,
      };
    default:
      throw new Error(`${action.type} is not a valid action to change the plan version state.`);
  }
}

import equal from 'fast-deep-equal';
import {List, Map, Set} from 'immutable';
import memoizeOne from 'memoize-one';

import {TypedTransposedNodeDetails} from 'toolkit/ag-grid/types';
import {ThinComputeResultRowExtended} from 'toolkit/compute/types';
import Format from 'toolkit/format/format';
import {getEffectiveMetricName} from 'toolkit/metrics/utils';
import {
  findActiveForecastOverride,
  removeInapplicableManualAdjustment,
} from 'toolkit/plans/overrides';
import {dateInterval, intersects} from 'toolkit/time/utils';
import * as Types from 'types';
import {last} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isNonNullish, isNullish} from 'utils/functions';

import {
  ADJUSTMENT_ROOT,
  AdjustmentsByFirstPathId,
  AdjustmentsByMetricNameAndFirstPathId,
  PlanRowType,
} from './planning-types';

export const ADJUSTMENT_DEPENDENT_METRIC_NAMES = [
  'expected_on_hand_units',
  'expected_on_hand_units_visible',
  'shipment_plan',
  'expected_remaining_supply',
  'planned_inbound_received_units',
  'projected_receipts_materialized',
  'total_projected_receipts',
  'planned_purchases',
];

export function formatOverriddenMetricName(metricInstance: Types.MetricInstance): string {
  return Format.metric(
    metricInstance.metric.name !== 'forecast_sales_units_net'
      ? metricInstance
      : {
          ...metricInstance,
          metric: {...metricInstance.metric, displayName: 'Planned Unit Sales', name: ''},
        }
  );
}

export const getAdjustments = memoizeOne(
  (planVersion: Types.PlanVersion): AdjustmentsByMetricNameAndFirstPathId => {
    return clusterAdjustmentsByMetricAndFirstPathValue(planVersion.manualAdjustments);
  }
);

// Gets adjustments that were removed (i.e. reset to baseline) from the pending or active plans.
// Case 1 - There is a saved adjustment, it gets reset:
//   This will be in pendingPlanDiff.removedManualAdjustments.
// Case 2 - First Case 1, then the user clicks Update, then the adjustment is reset:
//   This will be in activePlanDiff.removedManualAdjustments
// Case 3 - First Case 2, then the adjustment is reset:
//   This will be in activePlanDiff.addedManualAdjustments, but not in pendingPlanDiff.addedManualAdjustments.
// Case 4 - An adjustment is created, user clicks update, and then removes the adjustment (actual diff to saved plan is none)

export function getRemovedAdjustments(
  pendingPlanDiff: Types.PlanDiff | null,
  activePlanDiff: Types.PlanDiff | null,
  deletedAdjustments: ReadonlyArray<Types.ThinUserManualAdjustment>
): AdjustmentsByMetricNameAndFirstPathId {
  const pendingRemovedAdjustments = pendingPlanDiff?.removedManualAdjustments || [];
  const pendingAddedAdjustments = pendingPlanDiff?.addedManualAdjustments || [];
  const activeAddedAdjustments = activePlanDiff?.addedManualAdjustments || [];
  const removedFromActiveDiffAdjustments = getDeletedAddedAdjustments(
    activeAddedAdjustments,
    pendingAddedAdjustments
  );
  return clusterAdjustmentsByMetricAndFirstPathValue(
    pendingRemovedAdjustments.concat(removedFromActiveDiffAdjustments).concat(deletedAdjustments)
  );
}

// Deep equality filter for adjustments in activeAddedAdjustments but not pendingAddedAdjustments
export function getDeletedAddedAdjustments(
  activeAddedAdjustments: readonly Types.ThinUserManualAdjustment[],
  pendingAddedAdjustments: readonly Types.ThinUserManualAdjustment[]
) {
  return activeAddedAdjustments.filter(
    adjustment =>
      !pendingAddedAdjustments.some(pendingAdjustment => equal(adjustment, pendingAdjustment))
  );
}

// Note: we cluster by the first path value as a performance optimization to cheaply reduce the search space when
// searching for adjustments
function clusterAdjustmentsByMetricAndFirstPathValue(
  adjustments: readonly Types.ThinUserManualAdjustment[]
): AdjustmentsByMetricNameAndFirstPathId {
  return Set(adjustments)
    .groupBy(adjustment => adjustment.metricValue.metricName)
    .map(adjustmentsForMetric =>
      adjustmentsForMetric
        .groupBy(adjustmentForMetric => {
          const values = adjustmentForMetric.attributeValues;
          return values.length > 0 ? values[0].id! : ADJUSTMENT_ROOT;
        })
        .map(adjustmentsForMetricAndFirstPathValue =>
          adjustmentsForMetricAndFirstPathValue.toList()
        )
        .toMap()
    )
    .toMap();
}

export function getAdjustmentsForDataPoint(
  adjustments: AdjustmentsByMetricNameAndFirstPathId | undefined,
  metricInstance: Types.MetricInstance | null,
  interval: Types.LocalInterval,
  pathValueIds: ReadonlyArray<number> | undefined
): ReadonlyArray<Types.ThinUserManualAdjustment> {
  if (!adjustments || !metricInstance) {
    return [];
  }

  const adjustmentsMatchingMetric: AdjustmentsByFirstPathId = adjustments.get(
    metricInstance.metric.name,
    Map()
  );

  const adjustmentsMatchingMetricAndInterval = (
    pathValueIds === undefined || pathValueIds.length === 0
      ? adjustmentsMatchingMetric.valueSeq().flatten(1)
      : adjustmentsMatchingMetric
          .get(pathValueIds[0], List.of<Types.ThinUserManualAdjustment>())
          .concat(
            adjustmentsMatchingMetric.get(
              ADJUSTMENT_ROOT,
              List.of<Types.ThinUserManualAdjustment>()
            )
          )
  ).filter(adjustment => isAdjustmentWithin(adjustment, interval));
  return removeInapplicableManualAdjustment(
    adjustmentsMatchingMetricAndInterval.toArray(),
    assertTruthy(pathValueIds)
  );
}

function isAdjustmentWithin(
  adjustment: Types.ThinUserManualAdjustment,
  interval: Types.LocalInterval
) {
  const adjustmentInterval = adjustment.metricValue.interval;

  return intersects(dateInterval(adjustmentInterval.start, adjustmentInterval.end), interval);
}

export function aggregateByAttributeValues(
  adjustments: ReadonlyArray<Types.ThinUserManualAdjustment>
): List<Types.ThinUserManualAdjustment> {
  return adjustments
    .reduce(
      (
        map: Map<ReadonlyArray<Types.ThinAttributeValue>, Types.ThinUserManualAdjustment>,
        adjustment
      ) => {
        const existing: Types.ThinUserManualAdjustment | null = map.get(
          adjustment.attributeValues,
          null
        );
        if (existing) {
          return map.set(adjustment.attributeValues, {
            ...existing,
            metricValue: {
              ...existing.metricValue,
              value: existing.metricValue.value + adjustment.metricValue.value,
            },
          });
        }

        return map.set(adjustment.attributeValues, adjustment);
      },
      Map<ReadonlyArray<Types.ThinAttributeValue>, Types.ThinUserManualAdjustment>()
    )
    .toList();
}

export function getCurrentForecastType(
  selectionFilters: ReadonlyArray<Types.AttributeFilter>,
  activePlanVersion: Types.PlanVersion | null
): Types.ForecastType {
  if (!activePlanVersion) {
    return Types.ForecastType.HISTORICAL_AVERAGE;
  }
  return (
    findActiveForecastOverride(selectionFilters, activePlanVersion)?.forecastType ||
    activePlanVersion.forecastType
  );
}

export function getRowName(
  metricInstance: Types.MetricInstance,
  planRowType = PlanRowType.PRIMARY,
  connectedDemandPlanPath: ReadonlyArray<Types.AttributeValue> = []
): string | null {
  const metricName = metricInstance?.metric?.name;
  if (planRowType === PlanRowType.PRIMARY) {
    if (metricName === 'shipment_plan') {
      return 'Shipment Plan';
    } else if (metricName === 'expected_on_hand_units') {
      return 'Projected Units on Hand';
    } else if (metricName === 'expected_remaining_supply') {
      return 'Projected Weeks of Supply';
    } else if (metricName === 'sales_units_net' || metricName === 'sales_units_gross') {
      return 'Point of Sale';
    } else if (metricName === 'inbound_shipped_units') {
      return 'Shipments';
    } else if (metricName === 'forecast_sales_units_net') {
      return 'Forecasted Point of Sale';
    } else if (metricName === 'expected_inbound_received_units') {
      return 'Expected Receipts';
    } else if (metricName === 'planned_inbound_received_units') {
      return 'Planned Receipts';
    } else if (metricName === 'projected_receipts_materialized') {
      return 'Projected Receipts (In Transit)';
    } else if (metricName === 'total_projected_receipts') {
      return 'Projected Receipts';
    } else if (metricName === 'forecast_inbound_shipped_units') {
      return 'Forecasted Shipments';
    } else if (
      metricName === 'downstream' &&
      getEffectiveMetricName(metricInstance) === 'shipment_plan'
    ) {
      return 'Planned Shipments';
    }
  } else if (planRowType === PlanRowType.BASELINE) {
    if (metricName === 'recommended_shipments') {
      return 'Recommended Shipments';
    } else if (metricName === 'raw_recommended_purchases') {
      return 'Recommended Purchases (Raw)';
    }
    return 'Baseline';
  } else if (planRowType === PlanRowType.ADJUSTMENT) {
    return 'Manual Adjustment';
  } else if (planRowType === PlanRowType.UNADJUSTED) {
    return 'Recommended Purchases';
  } else if (planRowType === PlanRowType.EVENT_LIFT) {
    return 'Event Lift';
  } else if (planRowType === PlanRowType.SCHEDULED_SHIPPED_UNITS) {
    return 'Scheduled Shipments';
  } else if (planRowType === PlanRowType.COMPS) {
    const effectiveMetric = getEffectiveMetricName(metricInstance);
    if (effectiveMetric === 'sales_units_net' || effectiveMetric === 'sales_units_gross') {
      return `Point of Sale (${Format.metricTimeAgo(metricInstance)})`;
    } else if (effectiveMetric === 'inbound_shipped_units') {
      return `Shipments (${Format.metricTimeAgo(metricInstance)})`;
    }
  } else if (planRowType === PlanRowType.SHIPMENT_PLAN_DETAILS) {
    if (connectedDemandPlanPath.length === 0) {
      throw new Error('Invalid connected demand plan path.');
    }
    const lowestLocationAttribute = getShipmentPlanDetailsAttributeValue(connectedDemandPlanPath);
    return Format.attributeValue(lowestLocationAttribute);
  }
  return null;
}

export function sumShipmentPlanDetails(
  rows: ReadonlyArray<TypedTransposedNodeDetails<ThinComputeResultRowExtended> | null | undefined>
): TypedTransposedNodeDetails<ThinComputeResultRowExtended> | null | undefined {
  if (rows.length === 0) {
    return null;
  }
  if (rows.length === 1) {
    return rows[0];
  }

  const currentRow = rows.find(row => isNonNullish(row));
  if (isNullish(currentRow)) {
    return null;
  }

  const children = currentRow.data.columnData.children.map((child, index) => {
    const allMetricValues = rows.map(row => row?.data.columnData.children[index].metricValues[0]);
    const sum = allMetricValues
      .filter(isNonNullish)
      .filter(value => !Number.isNaN(value))
      .reduce((sum, value) => sum + value, 0);
    return {...child, metricValues: [sum]};
  });
  return {
    ...currentRow,
    data: {
      ...currentRow.data,
      columnData: {
        ...currentRow.data.columnData,
        children,
      },
    },
  };
}

export function getShipmentPlanDetailsAttributeValue(
  demandPlanAttributes: ReadonlyArray<Types.AttributeValue> | undefined
): Types.AttributeValue | null {
  if (!demandPlanAttributes) {
    return null;
  }
  return last(
    demandPlanAttributes.filter(
      attributeValue => attributeValue.attribute.type === Types.AttributeType.LOCATION
    )
  );
}

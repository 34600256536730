import {parse} from '@tinyhttp/content-disposition';
import Cookies from 'js-cookie';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {showReloadAlert} from 'app/alerts';
import {decrementActiveRequestCount, incrementActiveRequestCount} from 'redux/actions/ajax';
import {CurrentUserActions} from 'redux/actions/user';
import {store} from 'redux/store/store';
import {getFeatureFlagOverrideHeaderEntry} from 'settings/features/utils';
import {getPackageOverrideHeaderEntry} from 'toolkit/permissisons/utils';
import * as Types from 'types';
import {ApiError, InterruptedByRedirect, FailedToFetchError} from 'types/error';
import {XSRF_TOKEN, LOGGED_IN, isRunningEndToEndTests} from 'utils/cookies';
import {Status} from 'utils/status';

import {isTruthy, noop} from './functions';

export type RequestMethod = 'get' | 'post' | 'put' | 'delete';

export const BASE_URL = '/api';

interface ApiMetadata {
  currentOpenApiChecksum: string | undefined;
  originalOpenApiChecksum: string | undefined;
}

const apiMetadata: ApiMetadata = {
  currentOpenApiChecksum: undefined,
  originalOpenApiChecksum: undefined,
};

export interface BlobResponse {
  readonly blob: Blob;
  readonly filename?: string;
}

export interface FetchExtras {
  readonly logErrors?: boolean;
  readonly redirectOn401?: boolean;
  readonly vendorId?: number;
  readonly fetchBlob?: boolean;
  readonly extraHeaders?: object;
  readonly signal?: AbortSignal;
  readonly returnResponseHeaders?: boolean;
}

export type ResponseWithHeaders<T> = T & {
  readonly __internalResponseHttpHeaders: Headers;
};

export const getCurrentApiVersion = () =>
  apiMetadata.currentOpenApiChecksum || apiMetadata.originalOpenApiChecksum;
export const getOriginalApiVersion = () => apiMetadata.originalOpenApiChecksum;

function checkApiStatus(response: Response): Response {
  const apiChecksum = response.headers.get('X-OpenApi-Checksum');
  if (apiChecksum) {
    if (!apiMetadata.originalOpenApiChecksum) {
      apiMetadata.originalOpenApiChecksum = apiChecksum;
    } else if (apiChecksum !== apiMetadata.originalOpenApiChecksum) {
      apiMetadata.currentOpenApiChecksum = apiChecksum;
      showReloadAlert().catch(noop);
    }
  }
  return response;
}

function resolveResponse(
  response: Response,
  requestUrl: string,
  extras: FetchExtras
): Promise<Error | any | null> | null | undefined {
  if (response.status >= 200 && response.status < 300) {
    return resolveContent(response, extras.fetchBlob || false, {
      returnHeaders: !!extras.returnResponseHeaders,
    });
  } else if (response.status === 401 && extras.redirectOn401) {
    Cookies.remove(LOGGED_IN);
    store.dispatch(CurrentUserActions.logoutWithExpiredCredentials());
    return Promise.reject(new InterruptedByRedirect());
  }

  return resolveServerError(response, requestUrl, extras);
}

export function getDefaultHeaders(vendorId?: number): {[key: string]: string} {
  const vendor = store.getState().user.vendor;
  return Object.fromEntries(
    [
      ['ALLOY-VENDOR', vendorId ? String(vendorId) : vendor ? String(vendor.id) : ''],
      ['X-Alloy-Referrer', window.location.href],
      ['X-XSRF-TOKEN', Cookies.get(XSRF_TOKEN)],
      ['X-E2E-BUILD-URL', store.getState().ajax.e2eBuildUrl],
      getFeatureFlagOverrideHeaderEntry(),
      getPackageOverrideHeaderEntry(vendor?.id ?? undefined),
    ].filter(headerEntry => isTruthy(headerEntry[1]))
  );
}

async function resolveBody<T = any>(
  response: Response | null,
  options: {returnHeaders: boolean}
): Promise<T | ResponseWithHeaders<T> | string | null> {
  if (!response) {
    return Promise.resolve(null);
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.startsWith('application/json')) {
    if (options.returnHeaders) {
      return response.json().then(jsonResponse => ({
        ...jsonResponse,
        __internalResponseHttpHeaders: response.headers,
      }));
    } else {
      return response.json();
    }
  }
  return response.text();
}

async function resolveServerError(response: Response, requestUrl: string, extras: FetchExtras) {
  return resolveBody(response, {returnHeaders: false}).then(
    (errorBody: Types.ErrorResponse | any) => {
      const traceId = response.headers.get('x-cloud-trace-context');
      throw new ApiError(
        response.status,
        errorBody.message || response.statusText || response.status.toString(),
        errorBody,
        extras.logErrors || false,
        requestUrl,
        traceId
      );
    }
  );
}

function resolveContent(
  response: Response,
  fetchBlob: boolean,
  options: {returnHeaders: boolean}
): Promise<any> | null | undefined {
  if (response === null) {
    return null;
  }
  if (response.status === 204) {
    return undefined;
  }
  if (fetchBlob) {
    const dispositionHeader = response.headers.get('content-disposition');
    const filename = dispositionHeader
      ? parse(dispositionHeader).parameters.filename
      : 'alloy-file';
    return response.blob().then(blob => ({blob, filename}));
  }
  return resolveBody(response, options);
}

const inProgressGetRequests = new Map<string, Promise<any>>();

function augmentError(error: any, requestUrl: string, extras: FetchExtras) {
  if (error instanceof TypeError) {
    const errorMessage = error.message.toLowerCase();
    if (
      // Chrome, Edge
      errorMessage.includes('failed to fetch') ||
      // Firefox
      errorMessage.includes('networkerror when attempting to fetch') ||
      // Safari
      errorMessage.includes('load failed')
    ) {
      throw new FailedToFetchError(error, requestUrl, extras.logErrors || false);
    }
  }
  throw error;
}

async function safeFetch<T = any>(
  rawUrl: string,
  options: RequestInit,
  extras: FetchExtras = {logErrors: true, redirectOn401: true}
): Promise<T> {
  const url = rawUrl.startsWith('http')
    ? rawUrl
    : rawUrl.startsWith(BASE_URL)
      ? rawUrl
      : `${BASE_URL}/${rawUrl}`;

  const isGet = options.method === 'get';
  if (isGet && inProgressGetRequests.has(url)) {
    return inProgressGetRequests.get(url)!;
  }

  if (isRunningEndToEndTests()) {
    const gcpProject = store.getState().environment.environment.gcpProject;
    window.dispatchEvent(
      new CustomEvent('cypressfetch', {detail: {url, options, extras, gcpProject}})
    );
  }

  // do this asynchronously so as not to synchronously trigger a re-render in the event that this is being called
  // synchronously during a render, which react warns against.
  setTimeout(() => store.dispatch(incrementActiveRequestCount()), 0);

  const request = fetch(url, options)
    .then(checkApiStatus)
    .then(response => resolveResponse(response, url, extras))
    .catch(error => augmentError(error, url, extras))
    .finally(() => store.dispatch(decrementActiveRequestCount()));

  if (isGet) {
    inProgressGetRequests.set(url, request);
    return request.finally(() => inProgressGetRequests.delete(url));
  }
  return request;
}

export function request<Method extends RequestMethod, T, Url extends string>(
  method: Method,
  url: Url,
  body?: object,
  extras?: FetchExtras,
  accept = 'application/json',
  contentType = 'application/json'
): Promise<T> {
  return safeFetch(
    url,
    {
      body: typeof body === 'string' ? body : JSON.stringify(body),
      credentials: 'include',
      headers: {
        ...getDefaultHeaders(extras?.vendorId),
        Accept: accept,
        ...(contentType ? {'Content-Type': contentType} : {}),
        ...extras?.extraHeaders,
      },
      method,
      // the mocking framework treats `signal: undefined` different from no property at all
      ...(extras?.signal ? {signal: extras.signal} : {}),
    },
    extras
  );
}

export function get<T = any>(
  url: string,
  extras: FetchExtras = {logErrors: true, redirectOn401: true}
): Promise<T> {
  const isAbsolute = url.startsWith('http');
  return safeFetch(
    url,
    {
      method: 'get',
      ...(isAbsolute
        ? {}
        : {
            credentials: 'include',
            headers: getDefaultHeaders(extras.vendorId),
          }),
    },
    extras
  );
}

export function post<T = any>(url: string, body?: object, extras: FetchExtras = {}): Promise<T> {
  return request('post', url, body, extras);
}

export function postFile<T = any>(
  url: string,
  file: File,
  additionalFormData?: {[key: string]: unknown}
): Promise<T> {
  const formData = new FormData();
  formData.append('file', file);

  if (additionalFormData) {
    Object.entries(additionalFormData).forEach(([key, value]) => {
      formData.append(key, `${value}`);
    });
  }

  return safeFetch(url, {
    body: formData,
    credentials: 'include',
    headers: {
      ...getDefaultHeaders(),
      Accept: 'application/json',
    },
    method: 'post',
  });
}

export function putFileToGcs<T = any>(signedUrl: string, file: File): Promise<T> {
  return safeFetch(signedUrl, {
    body: file,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    method: 'put',
  });
}

export async function downloadFile(
  method: RequestMethod,
  url: string,
  body?: object,
  accept = 'application/octet-stream'
): Promise<any> {
  return request<RequestMethod, any, string>(method, url, body, {
    extraHeaders: {
      Accept: accept,
    },
    fetchBlob: true,
  }).then(result => {
    saveDownloadedFile(URL.createObjectURL(result.blob), result.filename);
  });
}

function saveDownloadedFile(objectUrl: string, fileName?: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = objectUrl;
  if (fileName) {
    downloadLink.download = fileName;
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export async function resolveOrTimeout<T>(promise: Promise<T>, milliseconds: number): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    setTimeout(() => reject(new Error('Request timed out.')), milliseconds);
    promise.then(resolve, reject);
  });
}

export type NetworkRequestState<T> =
  | {result: null; status: Status.unstarted}
  | {result: null; status: Status.inProgress}
  | {result: T; status: Status.succeeded}
  | {result: Error; status: Status.failed};

/**
 * useRequest with debounce
 * Note: while useRequest is deprecated for non-mutating requests (which is what
 * this is likely to be used for, there isn't built-in support in the alternative
 * (useApi) for debouncing yet.
 */
export function useDebouncedRequest<T>(
  initialRequest: Promise<T> | null = null,
  delay = 250
): [NetworkRequestState<T>, (newRequest: () => Promise<T>) => void] {
  const [serverData, requestServerData] = useRequest<T>(initialRequest);
  const debouncedRequestServerData = useDebouncedCallback(
    callback => requestServerData(callback()),
    delay
  );
  return [serverData, debouncedRequestServerData];
}

/**
 * Tracks the state of network requests for convenient use by a component.
 * Prevents race conditions and only returns results for the most recently made request.
 * Note: this is deprecated in favour of useApi for non-mutating requests.
 */
export function useRequest<T>(
  initialRequest: Promise<T> | null = null
): [NetworkRequestState<T>, (newRequest: Promise<T> | null) => Promise<void>] {
  const [result, makeRequests] = useRequests<T>(initialRequest ? [initialRequest] : null);

  const makeRequest = useCallback(
    (request: Promise<T> | null) => makeRequests(request ? [request] : null),
    [makeRequests]
  );

  return [
    useMemo(() => {
      if (result.status === Status.succeeded) {
        return {result: result.result[0], status: Status.succeeded};
      } else {
        return result;
      }
    }, [result]),
    makeRequest,
  ];
}

/**
 * Note: this is deprecated in favour of useApi for non-mutating requests.
 */
export function useRequests<T>(
  initialRequests: Promise<T>[] | null = null
): [NetworkRequestState<T[]>, (newRequests: Promise<T>[] | null) => Promise<void>] {
  const [result, setResult] = useState<NetworkRequestState<T[]>>(
    initialRequests === null
      ? {status: Status.unstarted, result: null}
      : {status: Status.inProgress, result: null}
  );

  const latestRequests = useRef<Promise<T>[] | null>(initialRequests);
  const makeRequests = useCallback(async (requests: Promise<T>[] | null) => {
    if (!requests) {
      setResult({status: Status.unstarted, result: null});
      latestRequests.current = null;
      return;
    }
    setResult({status: Status.inProgress, result: null});
    latestRequests.current = requests;

    return Promise.all(requests)
      .then(data => {
        if (latestRequests.current === requests) {
          setResult({status: Status.succeeded, result: data});
        }
      })
      .catch(error => {
        if (latestRequests.current === requests) {
          setResult({status: Status.failed, result: error});
          throw error;
        }
      });
  }, []);

  return [result, makeRequests];
}

/**
 * Note: deprecated in favour of useApi.
 * Todo: delete this https://app.shortcut.com/alloytech/story/78015
 */
export function useResource_deprecated<T>(fetcher: () => Promise<T>): NetworkRequestState<T> {
  const [request, makeRequest] = useRequest<T>();
  useEffect(() => {
    makeRequest(fetcher());
  }, [fetcher, makeRequest]);
  return request;
}

import type * as GeoJSON from 'geojson';
import {List, Map, Set} from 'immutable';

import {isFail, isLoading} from 'redux/actions/helpers';
import {LocationData, MapsAction} from 'redux/actions/maps';
import ActionType from 'redux/actions/types';
import * as Types from 'types';
import * as MapTypes from 'widgets/maps/map-types';

function toMultiPolygon(
  features: ReadonlyArray<GeoJSON.Feature<GeoJSON.Polygon>>
): GeoJSON.Feature<GeoJSON.MultiPolygon> {
  return {
    type: 'Feature',
    geometry: {
      coordinates: features.map(feature => feature.geometry.coordinates),
      type: 'MultiPolygon',
    },
    id: features[0].id,
    properties: features[0].properties,
  };
}

function groupGeoJsonFeatures(
  features: GeoJSON.Feature[],
  groupByPropertyKey: string
): List<GeoJSON.Feature> {
  return List(features)
    .groupBy(feature => feature.properties![groupByPropertyKey])
    .map(featureGroup =>
      featureGroup.count() === 1
        ? featureGroup.first()!
        : toMultiPolygon(featureGroup.toArray() as readonly GeoJSON.Feature<GeoJSON.Polygon>[])
    )
    .toList();
}

export interface MapsState {
  readonly availableDatasets: Set<string>;
  readonly postalGeoData: Map<string, Map<string, GeoJSON.Feature>>;
  readonly locationData: Map<ReadonlyArray<Types.AttributeFilter>, ReadonlyArray<LocationData>>;
  readonly stateLookups: MapTypes.StateLookups;
  readonly stateShapes: MapTypes.StateShapes;
}

const initialMapsState: MapsState = {
  availableDatasets: Set(),
  postalGeoData: Map(),
  locationData: Map(),
  stateLookups: Map(),
  stateShapes: Map(),
};

export default function maps(state = initialMapsState, action: MapsAction): MapsState {
  if (action.type === ActionType.SetMapPostalData) {
    if (!isLoading(action) && !isFail(action)) {
      const groupedFeatures = groupGeoJsonFeatures(action.data!.features, action.keyProperty);
      const postalGeoData = Map<string, GeoJSON.Feature>(
        groupedFeatures.map(feature => [feature.properties![action.keyProperty], feature])
      );
      return {...state, postalGeoData: state.postalGeoData.set(action.countryName, postalGeoData)};
    }
  } else if (action.type === ActionType.SetMapStateData) {
    if (!isLoading(action) && !isFail(action)) {
      const groupedFeatures = groupGeoJsonFeatures(action.data!.features, action.keyProperty);
      const stateShapes = Map<string, MapTypes.StateShapeFeature>(
        groupedFeatures.map(feature => [
          feature.properties![action.keyProperty],
          feature as MapTypes.StateShapeFeature,
        ])
      );

      return {
        ...state,
        stateLookups: state.stateLookups.set(
          action.countryName,
          Map(
            stateShapes
              .map<[string, string]>((feature, name) => [feature.properties!.abbreviation, name])
              .toList()
          )
        ),
        stateShapes: state.stateShapes.set(action.countryName, stateShapes),
      };
    }
  } else if (action.type === ActionType.SetAvailableMapDatasets) {
    return {...state, availableDatasets: action.availableDatasets};
  } else if (action.type === ActionType.SetLocationData) {
    return {...state, locationData: state.locationData.set(action.filters, action.locationData)};
  }
  return state;
}
